export default function useFathomEvents() {
  const trackSignupClickOnHome = () => {
    if (typeof window !== 'undefined' && window.fathom) window.fathom.trackGoal('0TFCBK70', 0)
  }
  const trackSignupClickOnHeader = () => {
    if (typeof window !== 'undefined' && window.fathom) window.fathom.trackGoal('IBCGINO7', 0);
  }
  const trackSubmitSignupForm = () => {
    if (typeof window !== 'undefined' && window.fathom) window.fathom.trackGoal('EIZ8Z1YV', 0)
  }

  return {
    trackSignupClickOnHome,
    trackSignupClickOnHeader,
    trackSubmitSignupForm,
  }
}
