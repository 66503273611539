import { StopIcon } from '@heroicons/react/outline'
import { take } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Head, AdminRoundsList } from '../../..'
import { Locale } from '../../../../../types'
import { makeLocale } from '../../../../../utils/locale'
import { Header } from '../../../03-organisms/app-layout/header'
import { SearchHeader } from '../../../03-organisms/app-layout/search-header'
import { translations } from './translations'
import { AdminSeriesRoundTemplateProps as Props } from './types'

export const AdminSeriesRoundTemplate = ({ rounds, seriesContext }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]
  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector('html')
      if (htmlEl) {
        htmlEl.classList.add('h-full')
        htmlEl.classList.add('bg-white')
      }
      const bodyEl = document.querySelector('body')
      if (bodyEl) {
        bodyEl.classList.add('h-full')
        bodyEl.classList.add('overflow-hidden')
      }
      const nextWrapperEl = document.querySelector('#__next')
      if (nextWrapperEl) {
        nextWrapperEl.classList.add('h-full')
        nextWrapperEl.classList.add('overflow-hidden')
      }
    }
  })
  const [round] = take(rounds, 1)

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <div className="h-full flex">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          incomingSeriesId={seriesContext.id}
          seriesContext={seriesContext}
        />
        {/* Main column */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/* Search header */}
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="flex-1 min-w-0">
                <h1 className="text-base font-medium leading-6 text-gray-900 sm:truncate">
                  Rundor för {round && round.series_name ? round.series_name : 'denna serie'}
                </h1>
              </div>
            </div>
            {rounds.length === 0 ? (
              <div className="mt-12 text-center">
                <StopIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Inga rundor funna för{' '}
                  {round && round.series_name ? round.series_name : 'denna serie'}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Kom tillbaka till den här sidan vid ett senare tillfälle.
                </p>
              </div>
            ) : null}
            {rounds.length > 0 ? <AdminRoundsList rounds={rounds} /> : null}
          </main>
        </div>
      </div>
    </>
  )
}
