import Link from 'next/link'
import { CTA } from './cta'
import { FinalList } from './final-list'

export const FullContent = () => {
  return (
    <div className="relative overflow-hidden bg-white py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-base font-semibold uppercase tracking-wide text-indigo-600">
              Summering
            </span>
            <span className="mt-2 block text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Örnsköldsviks Nätverkspadel hösten 2021
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Fredag den 17e december genomförde vi den första säsongsavslutningen av Ö-viks
            Nätverkspadel. Det innefattade 90 minuter Mexicano-turnering med efterföljande
            finalmatch. Efter spel följde en after work/padel uppe på <em>hyllan</em> samt
            prisutdelning. Kvällen avslutades på House Be där vi åt julbord och alternerade mellan
            att se Modo åka på en tung förlust i Umeå samt WPTs Master-slutspel från Madrid, där
            Paquito/Di Nenno kunde skicka hem självaste Bela i en övertygande semifinal.
          </p>
        </div>
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <p>
            Jag (Joel) och Gabriel bestämde oss för att försöka få ihop ÖNP med väldigt kort varsel,
            inte långt före den första bollen studsade den första onsdagen i oktober. Vi har inget
            annat än tacksamhet och förundran till alla er padelentusiaster som valde att prova på
            denna idé! Tack!
          </p>
          <p>
            Nu har vi tagit denna säsong i mål och kunnat kröna <strong>Placerum</strong> (Victor &
            Andreas) som bästa företag samt <strong>Fredrik Bylin</strong> (Nybergs) som bästa
            spelare. Fredrik bjöd på ett dominerande slutspel där{' '}
            <a href="#standings">han samlade ihop hela 102 poäng</a>.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src="/assets/fredrik-striking-ball.jpg"
              alt="Fredrik slår bollen"
              width={1310}
              height={873}
            />
            <figcaption>Fredrik i en av många matcher.</figcaption>
          </figure>
          <h2>Vad händer nu?</h2>
          <p>
            Nu är det paus fram till den 19 januari när vi gladeligen välkomnar er alla tillbaka för
            starten av säsong 2. Vi kan redan meddela att flertalet platser är bokade och det har
            gått fort hittills. Vår långsiktiga målsättning är att 4-5 matcher spelas både morgon
            och lunch.
          </p>
          <p>Här är några få av alla de förbättringar vi planerar:</p>
          <ul role="list">
            <li>En uppdaterad spelmodell som ska leda till jämnare matcher.</li>
            <li>
              Det tillkommer 3 olika sallader till lunch-valen, utöver de wraps som vi haft denna
              säsong.
            </li>
            <li>
              Vi kommer också, i samarbete med Hälsobaren, ta frukosten till nästa nivå med goda
              smörgåsar och fräscha, nygjorda, smoothies.
            </li>
          </ul>
        </div>
        <CTA />
        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
          <h2>Några röster om Nätverkspadeln</h2>
          <p>
            Vi älskar att spela padel och har genom ÖNP lärt känna många fler som känner samma sak.
            För att vet hur spelarna känner skickade vi ut några frågor. Det som gjorde oss extra
            glad är att{' '}
            <strong>80% har rekommenderat Nätverkspadeln till någon i sin närhet</strong>.
          </p>
          <p>
            Här är ett svar på frågan{' '}
            <em>&quot;Vad är det huvudsakliga värdet du har fått från ÖNP?&quot;</em>
          </p>
          <blockquote>
            <p>
              Motion, starkare kontakt med nyckelpersoner i min bransch och en härlig känsla av
              gemenskap.
            </p>
          </blockquote>
          <p>Här är några fler</p>
          <blockquote>
            <p>Träffat nya trevliga personer som jag inte skulle kommit i kontakt med annars.</p>
          </blockquote>
          <blockquote>
            <p>
              Roligt att spela padel och göra nya bekantskaper. Eventuellt bli det några affärer
              framledes.
            </p>
          </blockquote>

          <h2>Nätverkspadelns framtid</h2>
          <p>
            Vår långsiktiga vision är att etablera en modell där företagande/entreprenörskap och
            padel möts på ett ömsesidigt gynnsamt sätt. Denna modell hoppas vi sedan kunna{' '}
            <em>exportera</em> till fler städer i Sverige och i förlängning ha en nationell
            uppgörelse kring vilket företag som är bäst. På en lättsamt sätt förstås.
          </p>
          <p>
            Låter det intressant?{' '}
            <a
              href="https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <a>Kontakta oss</a>
            </a>{' '}
            så berättar vi mer.
          </p>
        </div>
        <div className="mx-auto mt-12 max-w-7xl px-4 sm:px-6 lg:px-8" id="standings">
          <div className="mx-auto max-w-5xl">
            <div className="my-5 border-b border-gray-200 pb-5">
              <h3 className="text-lg font-medium leading-6 text-gray-900 sm:text-xl lg:text-3xl">
                Slutresultat Ö-viks Nätverkspadel hösten 2021
              </h3>
            </div>
            <FinalList />
          </div>
        </div>
      </div>
    </div>
  )
}
