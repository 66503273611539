import { Locale } from '../../../../../types'

type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Bjud in ett lag till rundan',
    pageDescription: 'Bjud in ett lag till en runda av Nätverkspadel.',
  },
  en: {
    pageTitle: 'Bjud in ett lag till rundan',
    pageDescription: 'Bjud in ett lag till en runda av Nätverkspadel.',
  },
}
