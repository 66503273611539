import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { SeriesContext } from '../../../../api-utils'

const RoundStatus = t.union([
  t.literal('current'),
  t.literal('next_week'),
  t.literal('upcoming'),
  t.literal('past'),
  t.literal('unknown'),
  t.literal('dynamic'),
])
const RoundGroup = t.union([
  t.literal('morning'),
  t.literal('lunch'),
  t.literal('unknown'),
  t.literal('dynamic'),
])

export const RoundDetails = t.type({
  series_id: UUID,
  series_name: t.string,
  round_id: t.number,
  round_public_id: UUID,
  round_name: t.string,
  round_start: t.string,
  round_end: t.string,
  start_hour: t.number,
  start_minute: t.number,
  start_week: t.number,
  current_week: t.number,
  status: RoundStatus,
  group: RoundGroup,
  invitations: t.number,
  accepted: t.number,
  declined: t.number,
  pending: t.number,
})

export type RoundDetails = t.TypeOf<typeof RoundDetails>

export type ComponentProps = {
  round: RoundDetails
  series: SeriesContext
}
