export type Testimonial = {
  logoUrl: string
  embedUrl: string
  personName: string
  companyName: string
}
type Props = {
  id: string
  firstTestimonial: Testimonial
  secondTestimonial: Testimonial
}

export const Testimonial = ({ id, firstTestimonial, secondTestimonial }: Props) => {
  return (
    <section id={id} className="bg-gradient-to-r from-purple-800 to-blue-700">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">
          <div className="md:flex-shrink-0 bg-white rounded-md flex justify-center items-center py-4">
            <img
              className="h-12"
              src={firstTestimonial.logoUrl}
              alt={firstTestimonial.companyName}
            />
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="video-responsive">
              <iframe
                title="Nätverkspadel testimonial"
                width="560"
                height="315"
                src={firstTestimonial.embedUrl}
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="rounded-md"
                allowFullScreen
              />
            </div>
          </blockquote>
        </div>
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">
          <div className="md:flex-shrink-0 bg-white rounded-md flex justify-center items-center py-4">
            <img
              className="h-12"
              src={secondTestimonial.logoUrl}
              alt={secondTestimonial.companyName}
            />
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="video-responsive">
              <iframe
                title="Nätverkspadel testimonial"
                width="560"
                height="315"
                src={secondTestimonial.embedUrl}
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="rounded-md"
                allowFullScreen
              />
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
