/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { translations } from './translations'

export const Footer = () => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {translation.solutionsLabel}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {translation.footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href}>
                        <a className="text-base text-gray-500 hover:text-gray-900">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {translation.supportLabel}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {translation.footerNavigation.support.map((item) =>
                    !item.type || item.type === 'internal' ? (
                      <li key={item.name}>
                        <Link href={item.href}>
                          <a className="text-base text-gray-500 hover:text-gray-900">{item.name}</a>
                        </Link>
                      </li>
                    ) : item.type === 'external' ? (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          target="_blank"
                          className="text-base text-gray-500 hover:text-gray-900"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {translation.organizationLabel}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {translation.footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href}>
                        <a className="text-base text-gray-500 hover:text-gray-900">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {translation.legalLabel}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {translation.footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link href={item.href}>
                        <a className="text-base text-gray-500 hover:text-gray-900">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          </div>
          {/* <div className="mt-12 xl:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Subscribe to our newsletter
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  The latest news, articles, and resources, sent to your inbox weekly.
                </p>
                <form className="mt-4 sm:flex sm:max-w-md">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:placeholder-gray-400"
                    placeholder="Enter your email"
                  />
                  <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-blue-700">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div> */}
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div className="flex space-x-6 md:order-2">
            {translation.footerNavigation.social.map((item) => (
              <Link key={item.name} href={item.href}>
                <a className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              </Link>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Impact Quest. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
