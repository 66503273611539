import useSWR from 'swr'
import { Dispatch, Fragment, SetStateAction, useRef, useState } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { UUID } from 'io-ts-types'
import { CheckIcon, PlusIcon, SelectorIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { includes, map } from 'lodash'
import { useRouter } from 'next/router'
import {
  FetchPlayersResponseData,
  Player,
  Players,
} from '../../../../pages/api/admin/series/[series_id]/round/[round_id]/fetch-players'
import { ErrorAlert, SuccessAlert } from '../..'
import { classNames } from '../../../../utils/classnames'
import {
  CreateMatchRequestBody,
  CreateMatchResponseData,
} from '../../../../pages/api/admin/match/create'

type Props = {
  seriesId: UUID
  roundId: number
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const fetcher = async (uri: string): Promise<FetchPlayersResponseData> => {
  const response = await fetch(uri)
  return response.json()
}

type PlayerSelectorProps = {
  label: string
  selected: Player | undefined
  setSelected: Dispatch<SetStateAction<Player | undefined>>
  players: Players
}
const PlayerSelector = ({ selected, setSelected, label, players }: PlayerSelectorProps) => {
  return (
    <div className="py-2">
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
            <span className="block truncate">
              {selected?.user_name} [{selected?.team_name}]
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {players.map((player) => (
                <Listbox.Option
                  key={player.user_id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-8 pr-4'
                    )
                  }
                  value={player}
                >
                  {({ selected: isSelected, active: isActive }) => {
                    return (
                      <>
                        <span
                          className={classNames(
                            isSelected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {player.user_name} [{player.team_name}]
                        </span>
                        {isSelected ? (
                          <span
                            className={classNames(
                              isActive ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                        {!player.is_in_series ? (
                          <span
                            className={classNames(
                              isActive ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

const getAvailablePlayers = (
  currentPlayer: Player | undefined,
  currentlySelected: Player[],
  players: Player[]
) => {
  if (!currentPlayer) return players
  return players.filter(
    (player) =>
      player.user_id !== currentPlayer.user_id &&
      !includes(
        currentlySelected.map((p) => p.user_id),
        player.user_id
      ) /*  && */
    /* !includes(currentPlayer.partners.map(partner => partner.id), player.user_id) */
  )
}

export const CreateMatchModal = ({ open, setOpen, roundId, seriesId }: Props) => {
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [homePlayer1, setHomePlayer1] = useState<Player>()
  const [homePlayer2, setHomePlayer2] = useState<Player>()
  const [awayPlayer1, setAwayPlayer1] = useState<Player>()
  const [awayPlayer2, setAwayPlayer2] = useState<Player>()
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const cancelButtonRef = useRef(null)
  const { data, error: fetchError } = useSWR(
    `/api/admin/series/${seriesId}/round/${roundId}/fetch-players`,
    fetcher
  )

  const selected: Player[] = []
  if (homePlayer1) selected.push(homePlayer1)
  if (homePlayer2) selected.push(homePlayer2)
  if (awayPlayer1) selected.push(awayPlayer1)
  if (awayPlayer2) selected.push(awayPlayer2)
  const selectedPlayers = selected.filter((player) => player)
  const players = map(data?.players, (player) => player)

  const resetStateAndClose = () => {
    setHomePlayer1(undefined)
    setHomePlayer2(undefined)
    setAwayPlayer1(undefined)
    setAwayPlayer2(undefined)
    setError(undefined)
    setStatus('pristine')
    setIsSubmitting(false)
    setOpen(false)
  }

  const sendRequest = async () => {
    setIsSubmitting(true)
    try {
      if (!homePlayer1 || !homePlayer2 || !awayPlayer1 || !awayPlayer2) {
        setError(new Error('Non 200 response from backend'))
        setIsSubmitting(false)
        return
      }
      const requestBody: CreateMatchRequestBody = {
        roundId,
        homeTeamMemberIds: [homePlayer1.team_member_id, homePlayer2.team_member_id],
        awayTeamMemberIds: [awayPlayer1.team_member_id, awayPlayer2.team_member_id],
        court: 'Temp',
      }
      const createMatchRes = await fetch('/api/admin/match/create', {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (createMatchRes.status !== 200) {
        console.error('createMatchRes', createMatchRes)
        setError(new Error('Non 200 response from backend'))
        setStatus('not_ok')
        setIsSubmitting(false)
        return
      }

      const createMatchResData: CreateMatchResponseData = await createMatchRes.json()
      console.debug('createMatchResData', createMatchResData)
      router.replace(router.asPath)
      resetStateAndClose()
    } catch (error) {
      console.error('Create match error', error)
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error was caught'))
      }
      setIsSubmitting(false)
      setStatus('not_ok')
      return
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block h-screen transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <PlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Skapa ny match
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Här kan du skapa en ny match genom att välja fyra stycken spelare.
                    </p>
                  </div>
                  {fetchError ? (
                    <ErrorAlert
                      translations={{ headline: 'Kunde inte hämta spelare' }}
                      error={fetchError}
                      reset={() => setStatus('pristine')}
                    />
                  ) : null}
                  {error ? (
                    <ErrorAlert
                      translations={{ headline: 'Kunde inte skapa match' }}
                      error={error}
                      reset={() => setStatus('pristine')}
                    />
                  ) : null}
                  {status === 'ok' ? (
                    <div className="mt-12">
                      <SuccessAlert
                        translations={{ headline: 'Match skapad!' }}
                        reset={() => setStatus('pristine')}
                      />
                    </div>
                  ) : null}
                  {data === undefined ? (
                    <svg
                      className="-mr-1 ml-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  {!fetchError && data ? (
                    <>
                      <div className="mt-6 flex items-center justify-between gap-x-4">
                        <div className="grid flex-grow grid-cols-1">
                          <PlayerSelector
                            selected={homePlayer1}
                            setSelected={setHomePlayer1}
                            label="Hemma - spelare 1"
                            players={getAvailablePlayers(homePlayer2, selectedPlayers, players)}
                          />
                          <PlayerSelector
                            selected={homePlayer2}
                            setSelected={setHomePlayer2}
                            label="Hemma - spelare 2"
                            players={getAvailablePlayers(homePlayer1, selectedPlayers, players)}
                          />
                        </div>
                        <p className="flex-shrink">vs.</p>
                        <div className="grid flex-grow grid-cols-1">
                          <PlayerSelector
                            selected={awayPlayer1}
                            setSelected={setAwayPlayer1}
                            label="Borta - spelare 1"
                            players={getAvailablePlayers(awayPlayer2, selectedPlayers, players)}
                          />
                          <PlayerSelector
                            selected={awayPlayer2}
                            setSelected={setAwayPlayer2}
                            label="Borta - spelare 2"
                            players={getAvailablePlayers(awayPlayer1, selectedPlayers, players)}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                  disabled={fetchError || error || isSubmitting || selectedPlayers.length !== 4}
                  onClick={() => sendRequest()}
                >
                  Skapa en match
                  {isSubmitting ? (
                    <svg
                      className="-mr-1 ml-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Stäng det här fönstret
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
