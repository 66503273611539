import { Locale } from "../../../../../types"

type FieldContent = {
  label: string
  placeholder: string
}
type Button = {
  text: string
  destination?: string
}
export type Translation = {
  success: {
    headline: string
  }
  error: {
    headline: string
  }
  fields: {
    first_name: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    last_name: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    email_adress: FieldContent & {
      validation: {
        short: string
        long: string
        format: string
        required: string
      }
    }
    phone_number: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
  }
  button: Button
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    fields: {
      first_name: {
        label: 'Förnamn',
        placeholder: 'Skriv spelarens förnamn',
        validation: {
          short: 'Förnamnet är för kort',
          long: 'Förnamnet är för långt',
          required: 'Förnamnet saknas',
        },
      },
      last_name: {
        label: 'Efternman',
        placeholder: 'Skriv spelarens efternman',
        validation: {
          short: 'Efternamnet är för kort',
          long: 'Efternamnet är för långt',
          required: 'Efternamnet saknas',
        },
      },
      email_adress: {
        label: 'E-postadress',
        placeholder: 'Skriv spelarens e-postadress',
        validation: {
          short: 'E-postadressen är för kort',
          long: 'E-postadressen är för lång',
          format: 'E-postadressen har ogiltigt format',
          required: 'E-postadressen saknas',
        },
      },
      phone_number: {
        label: 'Telefonnummer',
        placeholder: 'Skriv spelarens telefonummer',
        validation: {
          short: 'Telefonnumret är för kort',
          long: 'Telefonnumret är för långt',
          required: 'Telefonnumret saknas',
        },
      },
    },
    button: {
      text: 'Spara',
    },
    error: {
      headline: 'Det var ett problem med att skapa en ny spelare.',
    },
    success: {
      headline: 'Tack för du skapat en spelare. Nu kan du fortsätta med anmälan.',
    },
  },
  en: {
    fields: {
      first_name: {
        label: 'Förnamn',
        placeholder: 'Skriv spelarens förnamn',
        validation: {
          short: 'Förnamnet är för kort',
          long: 'Förnamnet är för långt',
          required: 'Förnamnet saknas',
        },
      },
      last_name: {
        label: 'Efternman',
        placeholder: 'Skriv spelarens efternman',
        validation: {
          short: 'Efternamnet är för kort',
          long: 'Efternamnet är för långt',
          required: 'Efternamnet saknas',
        },
      },
      email_adress: {
        label: 'E-postadress',
        placeholder: 'Skriv spelarens e-postadress',
        validation: {
          short: 'E-postadressen är för kort',
          long: 'E-postadressen är för lång',
          format: 'E-postadressen har ogiltigt format',
          required: 'E-postadressen saknas',
        },
      },
      phone_number: {
        label: 'Telefonnummer',
        placeholder: 'Skriv spelarens telefonummer',
        validation: {
          short: 'Telefonnumret är för kort',
          long: 'Telefonnumret är för långt',
          required: 'Telefonnumret saknas',
        },
      },
    },
    button: {
      text: 'Spara',
    },
    error: {
      headline: 'Det var ett problem med att skapa en ny spelare.',
    },
    success: {
      headline: 'Tack för du skapat en spelare. Nu kan du fortsätta med anmälan.',
    },
  },
}
