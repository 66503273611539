import { MatchResultRowItem } from '../../02-molecules'
import { ComponentProps, MatchResultRows } from './types'

export { MatchResultRows }

export const MatchResultList = ({
  matches,
  roundName,
  seriesContext,
  compact = false,
}: ComponentProps) => {
  const upcomingMatches = matches.filter((match) => match.is_upcoming_match)
  const completedMatches = matches.filter((match) => !match.is_upcoming_match)
  return (
    <div className="mt-8 bg-white overflow-hidden">
      {upcomingMatches && upcomingMatches.length > 0 ? (
        <>
          {!compact ? (
            <div className="pb-5 px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Kommande matcher{roundName ? ` - ${roundName}` : null}
              </h3>
            </div>
          ) : null}
          <ul role="list" className="divide-y divide-gray-200">
            {upcomingMatches.map((match) => (
              <MatchResultRowItem
                key={match.match_id}
                match={match}
                seriesContext={seriesContext}
              />
            ))}
          </ul>
        </>
      ) : null}
      {completedMatches && completedMatches.length > 0 ? (
        <>
          {!compact ? (
            <div className="pb-5 px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Spelade matcher{roundName ? ` - ${roundName}` : null}
              </h3>
            </div>
          ) : null}
          <ul role="list" className="divide-y divide-gray-200">
            {completedMatches.map((match) => (
              <MatchResultRowItem
                key={match.match_id}
                match={match}
                seriesContext={seriesContext}
              />
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}
