import * as t from 'io-ts'
import { UUID } from 'io-ts-types'

const RoundStatus = t.union([
  t.literal('current'),
  t.literal('next_week'),
  t.literal('upcoming'),
  t.literal('past'),
  t.literal('unknown'),
  t.literal('dynamic'),
])
const RoundGroup = t.union([
  t.literal('morning'),
  t.literal('lunch'),
  t.literal('unknown'),
  t.literal('dynamic'),
])

export const AdminRound = t.type({
  series_id: UUID,
  series_name: t.string,
  round_id: t.number,
  round_public_id: UUID,
  round_name: t.string,
  round_start: t.string,
  round_end: t.string,
  start_hour: t.number,
  start_minute: t.number,
  start_week: t.number,
  current_week: t.number,
  status: RoundStatus,
  group: RoundGroup,
  no_of_accepted_signups: t.number,
  no_of_rejected_signups: t.number,
  no_of_pending_invitations: t.number,
})

export type AdminRound = t.TypeOf<typeof AdminRound>

export type ComponentProps = {
  round: AdminRound
}
