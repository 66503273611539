const players = [
  { name: 'Hans Persson', final_points: 126.5, mexicano_points: 110, boost: 15 },
  { name: 'Josip Pavic', final_points: 125.08, mexicano_points: 118, boost: 6 },
  { name: 'Fredrik Bylin', final_points: 113.4, mexicano_points: 105, boost: 8 },
  { name: 'Johan Edlund', final_points: 113.22, mexicano_points: 111, boost: 2 },
  { name: 'Erik Nilsson', final_points: 112.2, mexicano_points: 102, boost: 10 },
  { name: 'Fredrik Furevik', final_points: 112, mexicano_points: 100, boost: 12 },
  { name: 'Marcus Sjöberg', final_points: 109.08, mexicano_points: 108, boost: 1 },
  { name: 'Pär Edblom', final_points: 104.03, mexicano_points: 101, boost: 3 },
  { name: 'Micke Selin', final_points: 103.95, mexicano_points: 99, boost: 5 },
  { name: 'Gabriel Baudin', final_points: 100, mexicano_points: 100, boost: 0 },
  { name: 'Joel Baudin', final_points: 96, mexicano_points: 96, boost: 0 },
  { name: 'Martin Carstedt', final_points: 96, mexicano_points: 96, boost: 0 },
  { name: 'Tobias Sjödin', final_points: 94.64, mexicano_points: 91, boost: 4 },
  { name: 'Johan Lindgren', final_points: 92, mexicano_points: 92, boost: 0 },
  { name: 'Niclas Grundström', final_points: 92, mexicano_points: 92, boost: 0 },
  { name: 'Michael Lindroth', final_points: 92, mexicano_points: 92, boost: 0 },
  { name: 'Per-Olof Björklund', final_points: 90, mexicano_points: 90, boost: 0 },
  { name: 'Åke Ödling', final_points: 89, mexicano_points: 89, boost: 0 },
  { name: 'Thomas Pettersson', final_points: 86, mexicano_points: 86, boost: 0 },
  { name: 'Tilde Nording', final_points: 82, mexicano_points: 82, boost: 0 },
  {
    name: 'Mattias Modig',
    final_points: 122,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Alexander Larsson',
    final_points: 116,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Andreas Fjällström',
    final_points: 113,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Peter Selin',
    final_points: 112,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mikael Selin',
    final_points: 111,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Björn Sundin',
    final_points: 111,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Jörgen Näslund',
    final_points: 110,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Tomislav Lovric',
    final_points: 108,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Ida Nyberg',
    final_points: 108,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mikael Nordstrand',
    final_points: 107,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Håkan Martinell',
    final_points: 106,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Stefan Lyng',
    final_points: 105,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Bjarte Trauthaug',
    final_points: 105,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Gustav Häggström',
    final_points: 105,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mikael Söderberg',
    final_points: 105,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mari Pettersson',
    final_points: 105,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mikael Hübinette',
    final_points: 104,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Hampus Sjöberg',
    final_points: 103,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Victor Hansson',
    final_points: 103,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Martin Dahlberg',
    final_points: 103,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Tove Sarri Carstedt',
    final_points: 102,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Annica Åslund Olsén',
    final_points: 101,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Johannes Brorsson',
    final_points: 99,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Peter Granholm',
    final_points: 98,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Fredrik Sjöberg',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Johan Alsén',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Hanna Öhman',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Carl Söderström',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Sam Gunnarsson',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mats Hilli',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Tina Bostedt',
    final_points: 97,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Tommy Nilsson',
    final_points: 96,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mattias Westman',
    final_points: 96,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Mats Myrsell',
    final_points: 96,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Cecilia Häggström',
    final_points: 96,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Hanna Sjöberg',
    final_points: 94,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Jon Moraeus',
    final_points: 92,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Peter Hörnell',
    final_points: 91,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Dennis Edlund',
    final_points: 87,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
  {
    name: 'Martin Söderström',
    final_points: 82,
    mexicano_points: 0,
    boost: 0,
    comment: 'Deltog inte i slutspelet.',
  },
]

export const FinalList = () => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rank
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Namn
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Slutpoäng i ÖNP
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Totala poäng i Mexicano
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Boost
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Kommentar
                  </th>
                </tr>
              </thead>
              <tbody>
                {players.map((person, personIdx) => (
                  <tr key={person.name} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {personIdx + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {person.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {person.final_points}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {person.mexicano_points}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {person.boost}%
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {person.comment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
