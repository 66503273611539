import { AdminMatchResultRowItem } from '../../02-molecules'
import { ComponentProps, AdminMatchResultRows } from './types'

export { AdminMatchResultRows }

export const AdminMatchResultList = ({ matches, handleMatchClick }: ComponentProps) => {
  return (
    <div className="bg-white overflow-hidden">
      <ul role="list" className="divide-y divide-gray-200">
        {matches.map((match) => <AdminMatchResultRowItem key={match.match_id} match={match} handleMatchClick={handleMatchClick} />)}
      </ul>
    </div>
  )
}