import * as t from 'io-ts'
import { SeriesContext } from '../../../../../../api-utils'

export const PlayerStandingRow = t.type({
  user_id: t.string,
  name: t.string,
  points_standing: t.number,
  average_match_point: t.number,
  no_of_matches: t.number,
  positive: t.number,
  negative: t.number,
  total_games_won: t.number,
  total_games_lost: t.number,
  plus_minus: t.number,
  five_points: t.number,
  four_points: t.number,
  three_points: t.number,
  two_points: t.number,
  minus_two_points: t.number,
  minus_three_points: t.number,
  image_url_logo: t.union([t.string, t.null]),
})

export type PlayerStandingRow = t.TypeOf<typeof PlayerStandingRow>

export const PlayerStandingRows = t.array(PlayerStandingRow)
export type PlayerStandingRows = t.TypeOf<typeof PlayerStandingRows>

export type PlayerProps = {
  standings: PlayerStandingRows
  seriesContext: SeriesContext
}
