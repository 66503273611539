import * as t from 'io-ts'
import { SeriesContext } from '../../../../../../api-utils'

export const TeamStandingRow = t.type({
  team_id: t.string,
  name: t.string,
  image_url_logo: t.union([t.string, t.null]),
  website: t.union([t.string, t.null]),
  points_standing: t.number,
  average_match_point: t.number,
  no_of_matches: t.number,
  positive: t.number,
  negative: t.number,
  total_games_won: t.number,
  total_games_lost: t.number,
  plus_minus: t.number,
  five_points: t.number,
  four_points: t.number,
  three_points: t.number,
  two_points: t.number,
  minus_two_points: t.number,
  minus_three_points: t.number,
  no_of_spots: t.number,
})

export type TeamStandingRow = t.TypeOf<typeof TeamStandingRow>

export const TeamStandingRows = t.array(TeamStandingRow)
export type TeamStandingRows = t.TypeOf<typeof TeamStandingRows>

export type TeamProps = {
  standings: TeamStandingRows
  seriesContext: SeriesContext
}
