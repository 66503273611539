import Link from 'next/link'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { CreateTeamProps } from './types'
import { Group } from '../../../../../pages/api/admin/series/[series_id]/team/create/types'
import {
  CreateTeamRequestBody,
  CreateTeamResponseBody,
} from '../../../../../pages/api/admin/series/[series_id]/team/create'
import { useState } from 'react'
import { ErrorAlert, SuccessAlert } from '../../..'

type FormValues = {
  team_name: string
  team_group: Group
  player_name: string
  player_email: string
  player_phone: string
}

const ValidationSchema = Yup.object().shape({
  team_name: Yup.string()
    .min(2, 'Värdet är för kort')
    .max(40, 'Värdet är för långt')
    .trim()
    .required('Detta fält måste ha ett värde'),
  team_group: Yup.string()
    .min(0, 'Måste vara minst 0')
    .max(7, 'Du kan bara vinna som mest 7 gem')
    .lowercase()
    .required('Detta fält måste ha ett värde'),
  player_name: Yup.string()
    .min(2, 'Värdet är för kort')
    .max(40, 'Värdet är för långt')
    .trim()
    .required('Detta fält måste ha ett värde'),
  player_email: Yup.string()
    .min(2, 'Värdet är för kort')
    .max(40, 'Värdet är för långt')
    .email('Fel format på e-postadressen')
    .trim()
    .lowercase()
    .required('Detta fält måste ha ett värde'),
  player_phone: Yup.string()
    .min(2, 'Värdet är för kort')
    .max(40, 'Värdet är för långt')
    .trim()
    .required('Detta fält måste ha ett värde'),
})

const initialValues: FormValues = {
  team_name: '',
  team_group: 'morning',
  player_name: '',
  player_email: '',
  player_phone: '',
}

export const CreateTeamForm = ({ seriesContext }: CreateTeamProps) => {
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  return (
    <>
      {status === 'ok' ? (
        <SuccessAlert translations={{ headline: 'Gick bra!' }} reset={reset} />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert translations={{ headline: 'Gick inte bra!' }} error={error} reset={reset} />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            const requestBody: CreateTeamRequestBody = {
              teamName: values.team_name,
              group: values.team_group,
              player: {
                name: values.player_name,
                email: values.player_email,
                phone: values.player_phone,
              },
            }
            const CreateTeamRes = await fetch(`/api/admin/series/${seriesContext.id}/team/create`, {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            if (CreateTeamRes.status !== 200) {
              setStatus('not_ok')
              setError(new Error('Non 200 response from backend'))
              actions.resetForm()
              actions.setSubmitting(false)
              return
            }

            const CreateTeamResData: CreateTeamResponseBody = await CreateTeamRes.json()
            if (!CreateTeamResData.success) {
              setStatus('not_ok')
              setError(new Error(CreateTeamResData.code))
              actions.resetForm()
              actions.setSubmitting(false)
            } else {
              actions.resetForm()
              actions.setSubmitting(false)
              setStatus('pristine')
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Report game result failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}
      >
        {(formikBag) => {
          return (
            <Form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Laget</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      This information will be displayed publicly so be careful what you share.
                    </p>
                  </div>

                  <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <Field name="team_name">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="team_name"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Lagnamn
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...field}
                              required
                              type="text"
                              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            {meta.touched && meta.error && (
                              <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="team_group">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="team_group"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Grupp
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              {...field}
                              required
                              type="text"
                              className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option value="morning">Morgon</option>
                              <option value="lunch">Lunch</option>
                            </select>
                            {meta.touched && meta.error && (
                              <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Spelarinformation
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Use a permanent address where you can receive mail.
                    </p>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <Field name="player_name">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="player_name"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Namn
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...field}
                              required
                              type="text"
                              className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            {meta.touched && meta.error && (
                              <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="player_email">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="player_email"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            E-post
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...field}
                              required
                              type="email"
                              className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            {meta.touched && meta.error && (
                              <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="player_phone">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="player_phone"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Telefon
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...field}
                              required
                              type="tel"
                              className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            {meta.touched && meta.error && (
                              <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <Link href={`/admin/series/${seriesContext.id}/manage`}>
                    <a className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Avbryt
                    </a>
                  </Link>
                  <button
                    type="submit"
                    disabled={formikBag.isSubmitting || !formikBag.isValid}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Skapa
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
