import * as t from 'io-ts'
import { UUID } from 'io-ts-types'

const Partner = t.type({
  name: t.string,
  id: UUID,
})
export const Breakdown = t.type({
  series_id: UUID,
  player_id: UUID,
  player_name: t.string,
  partners: t.array(Partner),
  no_of_partners: t.number,
})
export type Breakdown = t.TypeOf<typeof Breakdown>

export type ComponentProps = {
  row: Breakdown
  rowIdx: number
  allRows: Breakdown[]
}