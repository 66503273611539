import Link from 'next/link'
import { DateTime } from 'luxon'
import { get } from 'lodash'
import { useState } from 'react'
import { StopIcon, ChevronRightIcon, LinkIcon } from '@heroicons/react/outline'
import { ComponentProps } from '../admin-signup-row-item/types'

const ClipboardCopy = ({ copyText, type }: { copyText: string; type: 'input' | 'textarea' }) => {
  const [isCopied, setIsCopied] = useState(false)

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="flex gap-x-2 py-4 w-full items-end">
      {type === 'input' ? (
        <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
          >
            Länk till inbjudan
          </label>
          <input
            type="text"
            value={copyText}
            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            readOnly
          />
        </div>
      ) : null}
      {type === 'textarea' ? (
        <div>
          <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
            Kopiera och skicka
          </label>
          <div className="mt-1">
            <textarea
              rows={4}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={copyText}
              readOnly
            />
          </div>
        </div>
      ) : null}
      {/* Bind our handler function to the onClick button property */}
      <button
        onClick={handleCopyClick}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <span>
          {isCopied ? (
            'Kopierad!'
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
              />
            </svg>
          )}
        </span>
      </button>
    </div>
  )
}

export const SignupDetails = ({
  signup,
  round,
  series,
  expanded = false,
}: ComponentProps & { expanded?: boolean }) => {
  const signupState =
    signup && signup.signup_created_at ? (
      <>
        Anmälde sig{' '}
        <time dateTime={DateTime.fromISO(signup.signup_created_at).toISODate()}>
          {DateTime.fromISO(signup.signup_created_at)
            .setLocale('sv')
            .toLocaleString(DateTime.DATETIME_SHORT)}
        </time>
      </>
    ) : signup && signup.signup_state === 'rejected' ? (
      <>
        Kommentar:{' '}
        <i>{signup.signup_comment ? signup.signup_comment : 'Ingen lämnad vid avanmälan.'}</i>
      </>
    ) : (
      <>Har inte anmält sig ännu.</>
    )

  const groupBadge = () => {
    switch (signup.team_series_group) {
      case 'morning':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
            Frukost
          </span>
        )
      case 'lunch':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
            Lunch
          </span>
        )
      case 'any':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Gäst
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Gäst
          </span>
        )
    }
  }
  const stateBadge = () => {
    switch (signup.signup_state) {
      case 'created':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
            Skapad
          </span>
        )
      case 'sent':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Skickat
          </span>
        )
      case 'accepted':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
            Accepterat
          </span>
        )
      case 'rejected':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
            Tackat nej (ingen ersättare)
          </span>
        )
      case 'reminder_sent':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Påminnelse skickad
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
            Oväntad status
          </span>
        )
    }
  }

  const answers = get(signup, 'signup_additional_answers.answers', [])

  return (
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <p className="text-sm font-medium text-indigo-600 truncate">{signup.user_name}</p>
            <p className="mt-2 flex items-center text-sm text-gray-500">
              <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="truncate">{signup.team_name}</span>
            </p>
          </div>
          <div className={`${expanded ? 'block' : 'hidden md:block'}`}>
            <div className="flex flex-col gap-y-2 pt-2">
              <p className="text-sm text-gray-900">{signupState}</p>
              <div className="flex gap-x-1">
                <p className="mt-2 flex items-center text-sm text-gray-500">{stateBadge()}</p>
                <p className="mt-2 flex items-center text-sm text-gray-500">{groupBadge()}</p>
              </div>
              {answers
                .filter((a: any) => a.answer)
                .map((answer: any) => (
                  <p
                    key={answer.question}
                    className="mt-2 flex items-center gap-x-2 text-sm text-gray-500 bg-gray-50 p-2"
                  >
                    <strong>{answer.question}</strong>
                    <span>{answer.answer}</span>
                  </p>
                ))}
              {signup &&
              (signup.signup_state === 'sent' ||
                signup.signup_state === 'created' ||
                signup.signup_state === 'reminder_sent' ||
                signup.signup_state === 'accepted') ? (
                <ClipboardCopy copyText={signup.invite_link} type="input" />
              ) : null}
              {signup &&
              (signup.signup_state === 'sent' || signup.signup_state === 'reminder_sent') ? (
                <p className="mt-2 flex items-center gap-x-2 text-sm text-gray-500">
                  <ClipboardCopy copyText={signup.reminder_text} type="textarea" />
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {signup && signup.user_id && !expanded ? (
        <Link
          href={`/admin/series/${series.id}/round/${round.round_public_id}/signup/${signup.signup_public_id}/view`}
        >
          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Link>
      ) : null}
    </div>
  )
}
