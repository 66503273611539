import Link from 'next/link'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { InviteTeamToRoundProps } from './types'
import { useState } from 'react'
import { UUID } from 'io-ts-types'
import { ErrorAlert, SuccessAlert } from '../../..'
import { useRouter } from 'next/router'
import {
  InviteTeamToRoundRequestBody,
  InviteTeamToRoundResponseData,
} from '../../../../../pages/api/admin/series/[series_id]/round/[round_id]/create-and-send-single-invitation'

type FormValues = {
  team_id: UUID
  send_email: boolean
}

const ValidationSchema = Yup.object().shape({
  team_id: Yup.string().required('Detta fält måste ha ett värde'),
  send_email: Yup.boolean().required('Detta fält måste ha ett värde'),
})

const initialValues: FormValues = {
  team_id: '' as UUID,
  send_email: false,
}

export const CreateTeamForm = ({ seriesContext, teams, round }: InviteTeamToRoundProps) => {
  const router = useRouter()
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  return (
    <>
      {status === 'ok' ? (
        <SuccessAlert translations={{ headline: 'Gick bra!' }} reset={reset} />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert translations={{ headline: 'Gick inte bra!' }} error={error} reset={reset} />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            const requestBody: InviteTeamToRoundRequestBody = {
              teamId: values.team_id,
              sendEmail: values.send_email,
            }
            const InviteTeamToRound = await fetch(
              `/api/admin/series/${seriesContext.id}/round/${round.round_public_id}/create-and-send-single-invitation`,
              {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )

            if (InviteTeamToRound.status !== 200) {
              setStatus('not_ok')
              setError(new Error('Non 200 response from backend'))
              actions.resetForm()
              actions.setSubmitting(false)
              return
            }

            const InviteTeamToRoundData: InviteTeamToRoundResponseData =
              await InviteTeamToRound.json()
            if (!InviteTeamToRoundData.success) {
              setStatus('not_ok')
              setError(new Error(InviteTeamToRoundData.code))
              actions.resetForm()
              actions.setSubmitting(false)
            } else {
              actions.resetForm()
              actions.setSubmitting(false)
              setStatus('pristine')
              router.replace(`/admin/series/${seriesContext.id}/round/${round.round_public_id}`)
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Inviting team to round failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}
      >
        {(formikBag) => {
          return (
            <Form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Bjud in ett lag till denna runda
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      This information will be displayed publicly so be careful what you share.
                    </p>
                  </div>

                  <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                    <Field name="team_id">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="team_id"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Lag
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <select
                              {...field}
                              required
                              type="text"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            >
                              <option value="none" disabled>
                                Välj ett lag
                              </option>
                              {teams.map((team) => (
                                <option key={team.id} value={team.id}>
                                  {team.name}
                                </option>
                              ))}
                            </select>
                            {meta.touched && meta.error && (
                              <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="send_email">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <div className="relative flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                aria-describedby="send_email_description"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              {meta.touched && meta.error && (
                                <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                              )}
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="send_email" className="font-medium text-gray-700">
                                Skicka mejl
                              </label>
                              <p id="send_email_description" className="text-gray-500">
                                Ska den inbjudne få ett mejl när inbjudan skapas.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <Link href={`/admin/series/${seriesContext.id}/round/${round.round_public_id}`}>
                    <a className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Avbryt
                    </a>
                  </Link>
                  <button
                    type="submit"
                    disabled={formikBag.isSubmitting || !formikBag.isValid}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Skicka
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
