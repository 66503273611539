import { ComponentProps, Signup } from './types'
import { SignupDetails } from '../admin-signup-details'
import { isEmpty } from 'lodash'

export { Signup }

export const SignupRowItem = ({ signup, round, series }: ComponentProps) => {
  return (
    <li
      className={`${
        signup && signup.signup_state === 'rejected' ? 'bg-red-50' : 'bg-white'
      } hover:bg-gray-50`}
    >
      <SignupDetails
        signup={signup}
        round={round}
        series={series}
        expanded={isEmpty(signup.signup_public_id)}
      />
    </li>
  )
}
