import { MatchResultList, MatchResultRows } from '../../../..'
import { SeriesContext } from '../../../../../../api-utils'

type Props = {
  matches: MatchResultRows
  seriesContext: SeriesContext
}

export const Matches = ({ matches, seriesContext }: Props) => {
  return (
    <div className="bg-gray-50 overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Matcher</h3>
          <p className="mt-2 text-sm text-gray-500">
            Detta är omgångens matcher som vi genererar utifrån rankingen ovan. Observera att det
            kanske inte stämmer 100% om anmälda spelare har ändrats efter att matcherna skapades.
          </p>
        </div>
        {matches.length > 0 ? (
          <MatchResultList matches={matches} seriesContext={seriesContext} compact />
        ) : null}
      </div>
    </div>
  )
}
