/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useUser } from '@auth0/nextjs-auth0'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { Footer, Head, Header } from '../../index'
import { translations } from './translations'
import { useSalesTax } from '../../../../hooks'
import { SignupForm } from './signup-form'
import { SeriesGroup, SeriesLength } from '../../../../pages/api/signup'

export const SignupPage = () => {
  const [seriesLength, setSeriesLength] = useState<SeriesLength>('10weeks')
  const [seriesGroup, setSeriesGroup] = useState<SeriesGroup>('morning')
  const { type } = useSalesTax()
  const userData = useUser()
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const { user, error, isLoading } = userData

  const { query } = router

  const length = get(query, 'length', '10weeks')
  useEffect(() => {
    if (length) {
      setSeriesLength(length === '10weeks' ? '10weeks' : length === '5weeks' ? '5weeks' : '10weeks')
    }
  }, [length])
  const alternative = get(query, 'alternative', 'morning')
  useEffect(() => {
    if (alternative) {
      setSeriesGroup(alternative === 'morning' ? 'morning' : alternative === 'lunch' ? 'lunch' : 'morning')
    }
  }, [alternative])

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <div className="bg-white">
        <SignupForm seriesLength={seriesLength} seriesGroup={seriesGroup} user={user}/>
      </div>
    </>
  )
}
