import * as t from 'io-ts'
import { SeriesContext } from '../../../../api-utils'
import { Round } from '../../02-molecules/round-row-item/types'

export const RoundRows = t.array(Round)

export type RoundRows = t.TypeOf<typeof RoundRows>

export type ComponentProps = {
  rounds: RoundRows
  seriesContext: SeriesContext
}
