import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline'

import { Locale } from '../../../../types'

type Solution = {
  name: string
  description: string
  href: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
}

type CTAButton = {
  text: string
  href: string
  type: 'anchor' | 'link'
}

type Translation = {
  solutionsLabel: string
  solutions: Solution[]
  pricingLabel: string
  pricingHref: string
  partnersLabel: string
  partnersHref: string
  organizationLabel: string
  organizationHref: string
  signInCta: CTAButton
  signOutCta: CTAButton
  signUpCta: CTAButton
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    solutionsLabel: 'Serier',
    organizationLabel: 'Organisation',
    organizationHref: '/organization',
    partnersLabel: 'Partners',
    partnersHref: '/partners',
    pricingLabel: 'Pris',
    pricingHref: '/pricing',
    signInCta: {
      text: 'Logga in',
      href: '/api/auth/login',
      type: 'link',
    },
    signOutCta: {
      text: 'Logga ut',
      href: '/api/auth/logout',
      type: 'link',
    },
    signUpCta: {
      text: 'Anmäl dig',
      href: '/signup',
      type: 'link',
    },
    solutions: [
      {
        name: 'Inbox',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: '#',
        icon: InboxIcon,
      },
      {
        name: 'Messaging',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '#',
        icon: AnnotationIcon,
      },
      {
        name: 'Live Chat',
        description: "Your customers' data will be safe and secure.",
        href: '#',
        icon: ChatAlt2Icon,
      },
      {
        name: 'Knowledge Base',
        description: "Connect with third-party tools that you're already using.",
        href: '#',
        icon: QuestionMarkCircleIcon,
      },
    ],
  },
  en: {
    solutionsLabel: 'Serier',
    organizationLabel: 'Organisation',
    organizationHref: '/organization',
    partnersLabel: 'Partners',
    partnersHref: '/partners',
    pricingLabel: 'Pris',
    pricingHref: '/pricing',
    signInCta: {
      text: 'Sign in',
      href: '/api/auth/login',
      type: 'link',
    },
    signOutCta: {
      text: 'Sign out',
      href: '/api/auth/logout',
      type: 'link',
    },
    signUpCta: {
      text: 'Sign up',
      href: '/signup',
      type: 'link',
    },
    solutions: [
      {
        name: 'Inbox',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: '#',
        icon: InboxIcon,
      },
      {
        name: 'Messaging',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '#',
        icon: AnnotationIcon,
      },
      {
        name: 'Live Chat',
        description: "Your customers' data will be safe and secure.",
        href: '#',
        icon: ChatAlt2Icon,
      },
      {
        name: 'Knowledge Base',
        description: "Connect with third-party tools that you're already using.",
        href: '#',
        icon: QuestionMarkCircleIcon,
      },
    ],
  },
}
