import { Locale } from '../types'

const removeRegion = (locale: string): string => {
  const [noRegion] = locale.split('-')
  return noRegion
}
const validateLocale = (locale: string, defaultLocale: string): Locale => {
  if (locale === Locale.sv || locale === Locale.en) {
    return locale
  } else {
    return defaultLocale as Locale
  }
}
export const makeLocale = (locale = 'sv', defaultLocale = 'sv'): Locale => {
  if (!locale) {
    return defaultLocale as Locale
  }
  if (locale.includes('-')) {
    const noRegion = removeRegion(locale)
    return validateLocale(noRegion, defaultLocale)
  } else {
    return validateLocale(locale, defaultLocale)
  }
}
