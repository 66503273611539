import * as t from 'io-ts'
import { SeriesContext } from '../../../../api-utils'
import { MatchResult } from '../../02-molecules/match-result-row-item/types'

export const MatchResultRows = t.array(MatchResult)

export type MatchResultRows = t.TypeOf<typeof MatchResultRows>

export type ComponentProps = {
  matches: MatchResultRows
  roundName?: string
  seriesContext: SeriesContext
  compact?: boolean
}
