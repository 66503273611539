import NextHead from 'next/head'
import { useRouter } from 'next/router'

import { Locale } from '../../../types'
import { makeLocale } from '../../../utils/locale'

type Props = {
  title?: string
  description?: string
  url?: string
  ogImage?: string
  canonicalSpecific?: boolean
}

export const Head = (props: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)

  const title = props.title
    ? props.title
    : locale === Locale.sv
    ? 'Ö-vik Nätverkspadel'
    : 'Ö-vik Network Padel'
  const description = props.description
    ? props.description
    : locale === Locale.sv
    ? 'En samling padelentusiaster som samlas för att spela och nätverka.'
    : 'A collection of padel enthusiasts who gather to play and network.'

  const ogImage = props.ogImage ? props.ogImage : '/og-image-default.jpg'
  const baseUrl = locale === Locale.sv ? 'https://natverkspadel.se' : 'https://natverkspadel.com'
  const canonicalUrl = props.canonicalSpecific ? `${baseUrl}${router.pathname}` : baseUrl

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" hrefLang={locale} href={`${baseUrl}${router.pathname}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta name="twitter:creator" content="@jrbaudin" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:image" content={`${baseUrl}${ogImage}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta property="og:site_name" content={title} />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </NextHead>
  )
}
