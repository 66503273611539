import * as t from 'io-ts'
import { RankedPlayer } from '../..'

export const RankedPlayers = t.array(RankedPlayer)

export type RankedPlayers = t.TypeOf<typeof RankedPlayers>

export type ComponentProps = {
  players: RankedPlayers
}
