import { Switch } from '@headlessui/react'
import { useSalesTax } from '../../../../hooks'
import { classNames } from '../../../../utils/classnames'

export const SalesTaxSelector = () => {
  const { type, setType } = useSalesTax()

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={type === 'inclusive'}
        onChange={() => setType(type === 'exclusive' ? 'inclusive' : 'exclusive')}
        className={classNames(
          type === 'inclusive' ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            type === 'inclusive' ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">Inkl. moms.</span>
        <span className="ml-2 text-sm text-gray-500">(visa priser)</span>
      </Switch.Label>
    </Switch.Group>
  )
}
