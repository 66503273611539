import { ArrowCircleDownIcon, CheckCircleIcon } from '@heroicons/react/outline'

import { Locale } from '../../../../types'

type FAQ = {
  id: number
  question: string
  answer: string
}

type Feature = {
  name: string
  description: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
}

type Translation = {
  pageTitle: string
  pageDescription: string
  headline: string
  subline: string
  description: string
  secondSubline: string
  secondHeadline: string
  priceHeadline: string
  priceTenWeeks: number
  priceFiveWeeks: number
  currencySuffix: string
  priceExclusive: string
  priceInclusive: string
  partnersHeadline: string
  faqHeadline: string
  faqs: FAQ[]
  features: Feature[]
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Anmälan | Ö-viks Nätverkspadel',
    pageDescription:
      'Välkommen med din anmälan. Vi förväntar oss att intresset kommer vara stort så boka din plats idag.',
    headline: 'Boka din plats nu.',
    subline: 'Anmälan',
    description: 'Vi förväntar oss att intresset kommer vara stort så boka din plats idag.',
    secondSubline: 'Allt du behöver',
    secondHeadline: 'Du behöver bara dyka upp',
    priceHeadline: 'Pris',
    priceTenWeeks: 2999,
    priceFiveWeeks: 1499,
    currencySuffix: 'kr',
    priceInclusive: 'inkl. 6% moms.',
    priceExclusive: 'exkl. moms.',
    partnersHeadline: 'I samarbete mellan dessa partners',
    faqHeadline: 'Vanliga frågor',
    faqs: [
      {
        id: 1,
        question: 'Men jag är inte så bra, kan jag ändå vara med?',
        answer:
          'Självklart. Häng med och lär dig på vägen, se till att vara öppen för tips och träna på.',
      },
      {
        id: 2,
        question: 'Nätverka? Jag tror inte jag behöver det för mitt jobb.',
        answer: 'Att knyta kontakter är aldrig fel. Du vet inte vad som händer i framtiden.',
      },
      {
        id: 3,
        question: '06:30, varför så tidigt?',
        answer:
          'Vi vet att alla har mycket att göra och vi är övertygade att om man får in det i kalendern blir det förutsägbart. Även om man, som vi, har familjepussel att styra upp.',
      },
      {
        id: 4,
        question: 'Måste jag vara med alla gånger?',
        answer:
          'Nej det behöver du inte. Du är garanterad att få spela 12 timmar, dvs 8 av 10 gånger. Men om du endast har tid med 6 är det helt OK. ',
      },
      {
        id: 5,
        question: 'Finns det något alternativ om jag vill spela men inte kan alla gånger?',
        answer:
          'Ja! Anmäl ditt företag och sedan kan ni dela upp passen mellan er. Varje match samlar ni ihop poäng och tävlar mot andra företag.',
      },
    ],
    features: [
      {
        name: 'Börja med anmälan',
        description:
          'Första steget är att genomföra din anmälan. Först till kvarn gäller. Vi har 24 platser.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Hantera betalning',
        description:
          'Vi kommer höra av oss till dig på mejl för att hantera betalning. Din anmälan är inte bekräftad förrän betalning är klar.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Boka in i kalendern',
        description:
          'Nu är du anmäld och du kan börja stretcha och förbereda dig för den första matchen.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Ta vara på chansen',
        description: 'Ha kul, ta det inte på för stort allvar, skapa många nya kontakter.',
        icon: CheckCircleIcon,
      },
    ],
  },
  en: {
    pageTitle: 'Anmälan | Ö-viks Nätverkspadel',
    pageDescription:
      'Välkommen med din anmälan. Vi förväntar oss att intresset kommer vara stort så boka din plats idag.',
    headline: 'Boka din plats nu.',
    subline: 'Anmälan',
    description: 'Vi förväntar oss att intresset kommer vara stort så boka din plats idag.',
    secondSubline: 'Allt du behöver',
    secondHeadline: 'Du behöver bara dyka upp',
    priceHeadline: 'Pris',
    priceTenWeeks: 2999,
    priceFiveWeeks: 1499,
    currencySuffix: 'kr',
    priceInclusive: 'inkl. 6% moms.',
    priceExclusive: 'exkl. moms.',
    partnersHeadline: 'I samarbete mellan dessa partners',
    faqHeadline: 'Vanliga frågor',
    faqs: [
      {
        id: 1,
        question: 'Men jag är inte så bra, kan jag ändå vara med?',
        answer:
          'Självklart. Häng med och lär dig på vägen, se till att vara öppen för tips och träna på.',
      },
      {
        id: 2,
        question: 'Nätverka? Jag tror inte jag behöver det för mitt jobb.',
        answer: 'Att knyta kontakter är aldrig fel. Du vet inte vad som händer i framtiden.',
      },
      {
        id: 3,
        question: '06:30, varför så tidigt?',
        answer:
          'Vi vet att alla har mycket att göra och vi är övertygade att om man får in det i kalendern blir det förutsägbart. Även om man, som vi, har familjepussel att styra upp.',
      },
      {
        id: 4,
        question: 'Måste jag vara med alla gånger?',
        answer:
          'Nej det behöver du inte. Du är garanterad att få spela 12 timmar, dvs 8 av 10 gånger. Men om du endast har tid med 6 är det helt OK. ',
      },
      {
        id: 5,
        question: 'Finns det något alternativ om jag vill spela men inte kan alla gånger?',
        answer:
          'Ja! Anmäl ditt företag och sedan kan ni dela upp passen mellan er. Varje match samlar ni ihop poäng och tävlar mot andra företag.',
      },
    ],
    features: [
      {
        name: 'Börja med anmälan',
        description:
          'Första steget är att genomföra din anmälan. Först till kvarn gäller. Vi har 24 platser.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Hantera betalning',
        description:
          'Vi kommer höra av oss till dig på mejl för att hantera betalning. Din anmälan är inte bekräftad förrän betalning är klar.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Boka in i kalendern',
        description:
          'Nu är du anmäld och du kan börja stretcha och förbereda dig för den första matchen.',
        icon: ArrowCircleDownIcon,
      },
      {
        name: 'Ta vara på chansen',
        description: 'Ha kul, ta det inte på för stort allvar, skapa många nya kontakter.',
        icon: CheckCircleIcon,
      },
    ],
  },
}
