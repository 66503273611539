import Link from 'next/link'
import { DateTime } from 'luxon'
import {
  StopIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
} from '@heroicons/react/outline'
import { ComponentProps, AdminRound } from './types'

export { AdminRound }

export const AdminRoundRowItem = ({ round }: ComponentProps) => {
  const groupBadge = () => {
    switch (round.group) {
      case 'morning':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
            Morgon
          </span>
        )
      case 'lunch':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Lunch
          </span>
        )
      case 'dynamic':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Dynamiskt
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
            Oväntad status
          </span>
        )
    }
  }

  const dtStart = DateTime.fromISO(round.round_start)
  const dtEnd = DateTime.fromISO(round.round_end)

  const rowContent = (
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <p className="text-sm font-medium text-indigo-600 truncate inline-flex gap-x-3">
              <span>{round.round_name}</span>
              <span className="flex gap-x-2">
                <DotsCircleHorizontalIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                {round.no_of_pending_invitations}
              </span>
              <span className="flex gap-x-2">
                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                {round.no_of_accepted_signups}
              </span>
              <span className="flex gap-x-2">
                <StopIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                {round.no_of_rejected_signups}
              </span>
            </p>
          </div>
          <div className="hidden md:block">
            <div>
              <p className="mt-2 flex items-center text-sm text-gray-500">{groupBadge()}</p>
            </div>
            <div>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {dtStart.setLocale('sv').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
              </p>
            </div>
            <div>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {dtStart.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)} till{' '}
                {dtEnd.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  )

  return (
    <li>
      <Link href={`/admin/series/${round.series_id}/round/${round.round_public_id}`}>
        <a className="block hover:bg-gray-50">{rowContent}</a>
      </Link>
    </li>
  )
}
