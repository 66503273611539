/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  TableIcon,
  UserIcon,
  CubeTransparentIcon,
  XIcon,
  CheckCircleIcon,
  ViewListIcon,
  BeakerIcon,
  CogIcon,
  CalendarIcon,
} from '@heroicons/react/outline'
import { SelectorIcon } from '@heroicons/react/solid'
import { useUser } from '@auth0/nextjs-auth0'
import { get, includes } from 'lodash'
import { classNames } from '../../../../utils/classnames'
import { SeriesContext } from '../../../../api-utils'

const adminNavigation = [
  {
    name: 'Serie',
    href: (seriesId: string) => `/admin/series/${seriesId}/`,
    icon: ViewListIcon,
    current: (pathname: string) => pathname === `/admin/series/[series_id]`,
  },
  {
    name: 'Hantera',
    href: (seriesId: string) => `/admin/series/${seriesId}/manage`,
    icon: CogIcon,
    current: (pathname: string) => includes(pathname, '/manage'),
  },
  {
    name: 'Match-verktyg',
    href: (seriesId: string) => `/admin/series/${seriesId}/match-breakdown`,
    icon: BeakerIcon,
    current: (pathname: string) => includes(pathname, '/match-breakdown'),
  },
]

type Props = {
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  incomingSeriesId?: string
  seriesContext?: SeriesContext
}

export const Header = ({ sidebarOpen, setSidebarOpen, incomingSeriesId, seriesContext }: Props) => {
  const navigation = [
    {
      name: 'Tabell',
      href: (seriesId: string) => `https://app.natverkspadel.se/series/gCBrLGHmUulvQgEUbY6P`,
      type: 'external',
      icon: TableIcon,
      current: (pathname: string) => includes(pathname, '/team/standings'),
      validTypes: ['team'],
    },
    {
      name: 'Spelare',
      href: (seriesId: string) => `https://app.natverkspadel.se/series/gCBrLGHmUulvQgEUbY6P`,
      icon: UserIcon,
      type: 'external',
      current: (pathname: string) => includes(pathname, '/player/standings'),
      validTypes: ['team', 'single_player'],
    },
    {
      name: 'Turnering',
      href: (seriesId: string) => `/series/${seriesId}/tournament/standings`,
      icon: CubeTransparentIcon,
      type: 'internal',
      current: (pathname: string) => includes(pathname, '/tournament/standings'),
      validTypes: ['tournament'],
    },
    {
      name: 'Omgångar',
      href: (seriesId: string) => `/series/${seriesId}/rounds`,
      icon: CalendarIcon,
      type: 'internal',
      current: (pathname: string) => includes(pathname, '/rounds'),
      validTypes: ['team', 'single_player', 'tournament'],
    },
    {
      name: 'Matcher',
      href: (seriesId: string) => `/series/${seriesId}/matches`,
      icon: CheckCircleIcon,
      type: 'internal',
      current: (pathname: string) => includes(pathname, '/matches'),
      validTypes: ['team', 'single_player', 'tournament'],
    },
  ]
  const [navItems, setNavItems] = useState(navigation)
  useEffect(() => {
    if (seriesContext) {
      setNavItems(
        navigation.filter((item) =>
          seriesContext ? item.validTypes.includes(seriesContext.type) : true
        )
      )
    }
  }, [seriesContext])
  const { user, isLoading, error } = useUser()
  const { query, pathname } = useRouter()
  const seriesId = incomingSeriesId || get(query, 'id', null)
  if (!seriesId) return null
  if (typeof seriesId !== 'string') return null

  const isAdmin =
    !isLoading &&
    !error &&
    user &&
    user.email &&
    includes(['joel@vihyr.com', 'gabriel@vihyr.com'], user.email)
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                {seriesContext ? (
                  <p className="text-md font-medium leading-6 text-gray-900">
                    <Link href="/" passHref>
                      {seriesContext.name}
                    </Link>
                  </p>
                ) : null}
                {!seriesContext ? (
                  <Link href="/" passHref>
                    <img
                      className="h-8 w-auto"
                      src="/logo/onp-text-logo-800x500.svg"
                      alt="ONP logo"
                    />
                  </Link>
                ) : null}
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navItems.map((item) => {
                      const seriesUrl = `${item.href(seriesId)}`
                      if (item.type === 'external') {
                        return (
                          <a
                            key={item.name}
                            href={item.href('')}
                            target="_blank"
                            rel="noreferrer noopener"
                            className={classNames(
                              item.current(pathname)
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                            )}
                            aria-current={item.current(pathname) ? 'page' : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current(pathname)
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        )
                      } else {
                        return (
                          <Link key={item.name} href={seriesUrl}>
                            <a
                              className={classNames(
                                item.current(pathname)
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                              )}
                              aria-current={item.current(pathname) ? 'page' : undefined}
                            >
                              <item.icon
                                className={classNames(
                                  item.current(pathname)
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 h-6 w-6 flex-shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </Link>
                        )
                      }
                    })}
                  </div>
                  {isAdmin ? (
                    <div className="mt-8">
                      <h3
                        className="px-3 text-xs font-semibold uppercase tracking-wider text-gray-500"
                        id="desktop-admin-links-headline"
                      >
                        Administratör
                      </h3>
                      <div className="space-y-1">
                        {adminNavigation.map((item) => {
                          const seriesUrl = `${item.href(seriesId)}`
                          return (
                            <Link key={item.name} href={seriesUrl}>
                              <a
                                className={classNames(
                                  item.current(pathname)
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                  'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                                )}
                                aria-current={item.current(pathname) ? 'page' : undefined}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current(pathname)
                                      ? 'text-gray-500'
                                      : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-3 h-6 w-6 flex-shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : null}
                  {/* <div className="mt-8">
                    <h3
                      className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                      id="mobile-teams-headline"
                    >
                      Teams
                    </h3>
                    <div className="mt-1 space-y-1" role="group" aria-labelledby="mobile-teams-headline">
                      {teams.map((team) => (
                        <a
                          key={team.name}
                          href={team.href}
                          className="group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span
                            className={classNames(team.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full')}
                            aria-hidden="true"
                          />
                          <span className="truncate">{team.name}</span>
                        </a>
                      ))}
                    </div>
                  </div> */}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex w-64 flex-col border-r border-gray-200 bg-gray-100 pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-6">
            {seriesContext ? (
              <p className="text-lg font-medium leading-6 text-gray-900">
                <Link href="/" passHref>
                  {seriesContext.name}
                </Link>
              </p>
            ) : null}
            {!seriesContext ? (
              <Link href="/" passHref>
                <img className="h-8 w-auto" src="/logo/onp-text-logo-800x500.svg" alt="ONP logo" />
              </Link>
            ) : null}
          </div>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
            {/* User account dropdown */}
            <Menu as="div" className="relative inline-block px-3 text-left">
              {!isLoading && !error && user ? (
                <div>
                  <Menu.Button className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="flex w-full items-center justify-between">
                      <span className="flex min-w-0 items-center justify-between space-x-3">
                        {user.picture ? (
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                            src={user.picture}
                            alt={user.name || 'User profile avatar'}
                          />
                        ) : null}
                        <span className="flex min-w-0 flex-1 flex-col">
                          <span className="truncate text-sm font-medium text-gray-900">
                            {user.name || user.email}
                          </span>
                          {/* <span className="text-gray-500 text-sm truncate">@jessyschwarz</span> */}
                        </span>
                      </span>
                      <SelectorIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                </div>
              ) : null}
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          View profile
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Settings
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Notifications
                        </a>
                      )}
                    </Menu.Item>
                  </div> */}
                  {/* <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Get desktop app
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Support
                        </a>
                      )}
                    </Menu.Item>
                  </div> */}
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link href="/api/auth/logout">
                          <a
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Logga ut
                          </a>
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {/* Sidebar Search */}
            {/* <div className="px-3 mt-5">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div
                    className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    aria-hidden="true"
                  >
                    <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                  />
                </div>
              </div> */}
            {/* Navigation */}
            <nav className="mt-6 px-3">
              <div className="space-y-1">
                {navItems.map((item) => {
                  if (item.type === 'external') {
                    return (
                      <a
                        key={item.name}
                        href={item.href('')}
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classNames(
                          item.current(pathname)
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                        )}
                        aria-current={item.current(pathname) ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.current(pathname)
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 h-6 w-6 flex-shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    )
                  } else {
                    return (
                      <Link key={item.name} href={`${item.href(seriesId)}`}>
                        <a
                          className={classNames(
                            item.current(pathname)
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current(pathname) ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current(pathname)
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </Link>
                    )
                  }
                })}
              </div>
              {isAdmin ? (
                <div className="mt-8">
                  <h3
                    className="px-3 text-xs font-semibold uppercase tracking-wider text-gray-500"
                    id="desktop-admin-links-headline"
                  >
                    Administratör
                  </h3>
                  <div className="mt-1 space-y-1" aria-labelledby="desktop-admin-links-headline">
                    {adminNavigation.map((item) => (
                      <Link key={item.name} href={`${item.href(seriesId)}`}>
                        <a
                          className={classNames(
                            item.current(pathname)
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current(pathname) ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current(pathname)
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
              {/* <div className="mt-8">
                <h3
                  className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  id="desktop-teams-headline"
                >
                  Teams
                </h3>
                <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                  {teams.map((team) => (
                    <a
                      key={team.name}
                      href={team.href}
                      className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                    >
                      <span
                        className={classNames(team.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full')}
                        aria-hidden="true"
                      />
                      <span className="truncate">{team.name}</span>
                    </a>
                  ))}
                </div>
              </div> */}
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
