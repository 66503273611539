import { useRouter } from 'next/router'

import { Locale } from '../../../../../../types'
import { makeLocale } from '../../../../../../utils/locale'
import { Head } from '../../../../'
import { translations } from './translations'
import { StandingsLayout } from './layout'
import { PlayerProps as Props } from './types'

export * from './types'

export const PlayerStandingsPage = ({ standings, seriesContext }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <StandingsLayout standings={standings} seriesContext={seriesContext} />
    </>
  )
}
