import { useUser } from '@auth0/nextjs-auth0'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

import useFathomEvents from '../../../../hooks/useFathomEvents'
import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { translations } from './translations'
import { SalesTaxSelector } from './sales-tax-selector'
import { Series } from '../../../../pages'
import { classNames } from '../../../../utils/classnames'
import { DesktopComputerIcon, PhoneIcon, PlayIcon } from '@heroicons/react/outline'
import { orderBy } from 'lodash'

const solutions = [
  {
    name: 'Plattform',
    description: 'Vi levererar en komplett lösning så att du kan starta en grupp i din stad.',
    linkText: 'Kontakta oss',
    href: 'https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1',
    type: 'external',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Turneringar m.m.',
    description:
      'I systemet kan du även skapa och hantera enskilda turneringar för att skapa mer värde för dina medlemmar.',
    linkText: 'Kontakta oss',
    href: 'https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1',
    type: 'external',
    icon: PlayIcon,
  },
]
const callsToAction = [
  {
    name: 'Kontakta oss',
    href: 'https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1',
    type: 'external',
    icon: PhoneIcon,
  },
]
const blogPosts = [
  {
    id: 1,
    name: 'Rapport från andra säsongen',
    href: '/resultat/np-v22',
    preview: 'Under våren 2022 genomförde vi den andra säsongen. Såhär gick det.',
    imageUrl: '/assets/martin-hitting-the-ball.jpg',
  },
  {
    id: 2,
    name: 'Första säsongen avklarad',
    href: '/resultat/onp-h21',
    preview:
      'Fredag den 17e december genomförde vi den första säsongsavslutningen av Ö-viks Nätverkspadel.',
    imageUrl: '/assets/fredrik-striking-ball.jpg',
  },
]

type Props = {
  series: Series
}

export const Header = ({ series }: Props) => {
  const { trackSignupClickOnHeader } = useFathomEvents()
  const userData = useUser()
  const { user, error, isLoading } = userData
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const sortedSeries = orderBy(series, ['series_start'], ['desc'])

  return (
    <header className="pb-5">
      <Popover className="relative bg-white">
        <div className="pointer-events-none absolute inset-0 z-30 shadow" aria-hidden="true" />
        <div className="relative z-20">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:justify-start md:space-x-10 lg:px-8">
            <div>
              <Link href="/">
                <a>
                  <span className="sr-only">Nätverkspadel</span>
                  <img
                    className="h-8 w-auto sm:h-12"
                    src="/logo/onp-text-logo-800x500.svg"
                    alt="Nätverkspadel logo"
                  />
                </a>
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
              <Popover.Group as="nav" className="flex space-x-10">
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                      >
                        <span>Spela padel</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform shadow-lg md:block">
                          <div className="absolute inset-0 flex">
                            <div className="w-1/2 bg-white" />
                            <div className="w-1/2 bg-gray-50" />
                          </div>
                          <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                            <nav className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                              <div>
                                <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                  Seriespel
                                </h3>
                                <ul role="list" className="mt-5 space-y-6">
                                  {sortedSeries
                                    .filter(
                                      (s) =>
                                        s.series_type === 'team' ||
                                        s.series_type === 'single_player'
                                    )
                                    .map((s) => (
                                      <li key={s.series_id} className="flow-root">
                                        <Link
                                          href={
                                            s.series_type === 'team'
                                              ? `/series/${s.series_id}/team/standings`
                                              : s.series_type === 'player'
                                              ? `/series/${s.series_id}/player/standings`
                                              : `/series/${s.series_id}/team/standings`
                                          }
                                        >
                                          <a className="-m-3 flex items-center gap-x-2 rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50">
                                            {s.series_name}{' '}
                                            <span className="text-xs font-extralight text-slate-600">
                                              (Örnsköldsvik)
                                            </span>
                                          </a>
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                  Turneringar
                                </h3>
                                <ul role="list" className="mt-5 space-y-6">
                                  {sortedSeries
                                    .filter((s) => s.series_type === 'tournament')
                                    .map((s) => (
                                      <li key={s.series_id} className="flow-root">
                                        <Link href={`/series/${s.series_id}/tournament/standings`}>
                                          <a className="-m-3 flex items-center gap-x-2 rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50">
                                            {s.series_name}{' '}
                                            <span className="text-xs font-extralight text-slate-600">
                                              (Örnsköldsvik)
                                            </span>
                                          </a>
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </nav>
                            <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                              <div>
                                <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                  Från historiken
                                </h3>
                                <ul role="list" className="mt-6 space-y-6">
                                  {blogPosts.map((post) => (
                                    <li key={post.id} className="flow-root">
                                      <a
                                        href={post.href}
                                        className="-m-3 flex rounded-lg p-3 hover:bg-gray-100"
                                      >
                                        <div className="hidden flex-shrink-0 sm:block">
                                          <img
                                            className="h-20 w-32 rounded-md object-cover"
                                            src={post.imageUrl}
                                            alt=""
                                          />
                                        </div>
                                        <div className="w-0 flex-1 sm:ml-8">
                                          <h4 className="truncate text-base font-medium text-gray-900">
                                            {post.name}
                                          </h4>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {post.preview}
                                          </p>
                                        </div>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              {/* <div className="mt-6 text-sm font-medium">
                                <a href="#" className="text-indigo-600 hover:text-indigo-500">
                                  {' '}
                                  View all posts <span aria-hidden="true">&rarr;</span>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                      >
                        <span>Starta</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg md:block">
                          <div className="mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                            {solutions.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 flex flex-col justify-between rounded-lg p-3 hover:bg-gray-50"
                                target={item.type === 'external' ? '_blank' : ''}
                                rel="noreferrer"
                              >
                                <div className="flex md:h-full lg:flex-col">
                                  <div className="flex-shrink-0">
                                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                      <item.icon className="h-6 w-6" aria-hidden="true" />
                                    </span>
                                  </div>
                                  <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                    <div>
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p>
                                    </div>
                                    <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                      {item.linkText} <span aria-hidden="true">&rarr;</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                          <div className="bg-gray-50">
                            <div className="mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                              {callsToAction.map((item) => (
                                <div key={item.name} className="flow-root">
                                  <a
                                    href={item.href}
                                    className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                    target={item.type === 'external' ? '_blank' : ''}
                                    rel="noreferrer"
                                  >
                                    <item.icon
                                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-3">{item.name}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <a
                  href="https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Kontakta oss
                </a>
                {/* <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Docs
                </a> */}
              </Popover.Group>
              <div className="flex items-center gap-x-4 md:ml-12">
                <SalesTaxSelector />
                {!isLoading && !error && !user ? (
                  <Link href={translation.signInCta.href}>
                    <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                      {translation.signInCta.text}
                    </a>
                  </Link>
                ) : null}
                {!isLoading && !error && user ? (
                  <Link href={translation.signOutCta.href}>
                    <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                      {translation.signOutCta.text}
                    </a>
                  </Link>
                ) : null}
                {/*                 <a
                  href="#"
                  className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6 sm:pb-8">
                <div className="flex items-center justify-between">
                  <div>
                    <Link href="/">
                      <a>
                        <img
                          className="h-8 w-auto"
                          src="/logo/onp-text-logo-800x500.svg"
                          alt="Nätverkspadel logo"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 sm:mt-8">
                  <nav>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                          target={item.type === 'external' ? '_blank' : ''}
                          rel="noreferrer"
                        >
                          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </div>
                    {/* <div className="mt-8 text-base">
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        View all products <span aria-hidden="true">&rarr;</span>
                      </a>
                    </div> */}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  {sortedSeries
                    .filter((s) => s.series_type === 'team' || s.series_type === 'single_player')
                    .map((s) => (
                      <Link
                        key={s.series_id}
                        href={
                          s.series_type === 'team'
                            ? `/series/${s.series_id}/team/standings`
                            : s.series_type === 'player'
                            ? `/series/${s.series_id}/player/standings`
                            : `/series/${s.series_id}/team/standings`
                        }
                      >
                        <a className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                          {s.series_name}{' '}
                          <span className="text-xs font-extralight text-slate-600">
                            (Örnsköldsvik)
                          </span>
                        </a>
                      </Link>
                    ))}
                  {sortedSeries
                    .filter((s) => s.series_type === 'tournament')
                    .map((s) => (
                      <Link key={s.series_id} href={`/series/${s.series_id}/tournament/standings`}>
                        <a className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                          {s.series_name}{' '}
                          <span className="text-xs font-extralight text-slate-600">
                            (Örnsköldsvik)
                          </span>
                        </a>
                      </Link>
                    ))}
                </div>
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <a
                    href="https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1"
                    className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                    target="_blank"
                    rel="noreferrer nooper"
                  >
                    Kontakta oss
                  </a>
                </div>
                <div className="mt-6">
                  {!isLoading && !error && !user ? (
                    <Link href={translation.signInCta.href}>
                      <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                        {translation.signInCta.text}
                      </a>
                    </Link>
                  ) : null}
                  {!isLoading && !error && user ? (
                    <Link href={translation.signOutCta.href}>
                      <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                        {translation.signOutCta.text}
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}
