/* eslint-disable @next/next/no-img-element */
import * as Yup from 'yup'
import Link from 'next/link'
import { Field, Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { UserProfile } from '@auth0/nextjs-auth0'
import { Locale } from '../../../../../../types'
import { makeLocale } from '../../../../../../utils/locale'
import { translations } from './types'
import { ErrorAlert, SuccessAlert } from '../../../../02-molecules'
import {
  SeriesGroup,
  SeriesLength,
  SignupRequestBody,
  SignupResponseData,
} from '../../../../../../pages/api/signup'
import { reduce, round } from 'lodash'
import useFathomEvents from '../../../../../../hooks/useFathomEvents'

type Props = {
  seriesLength: SeriesLength
  seriesGroup: SeriesGroup
  user: UserProfile | undefined
}

export const SignupForm = ({ seriesGroup, seriesLength, user }: Props) => {
  const { trackSubmitSignupForm } = useFathomEvents()
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  const initialValues: SignupRequestBody = {
    company: '',
    name: user && user.name ? user.name : '',
    email: user && user.email ? user.email : '',
    phone: '',
    group: seriesGroup ? seriesGroup : 'morning',
    series_length: seriesLength ? seriesLength : '10weeks',
    quantity: 1,
    application_bot_field: '',
  }

  const ValidationSchema = Yup.object().shape({
    company: Yup.string()
      .min(2, translation.fields.company.validation.short)
      .max(40, translation.fields.company.validation.long)
      .trim()
      .required(translation.fields.company.validation.required),
    name: Yup.string()
      .min(2, translation.fields.name.validation.short)
      .max(40, translation.fields.name.validation.long)
      .trim()
      .required(translation.fields.name.validation.required),
    email: Yup.string()
      .min(2, translation.fields.email.validation.short)
      .max(40, translation.fields.email.validation.long)
      .email(translation.fields.email.validation.format)
      .trim()
      .lowercase()
      .required(translation.fields.email.validation.required),
    phone: Yup.string()
      .min(5, translation.fields.phone.validation.short)
      .max(12, translation.fields.phone.validation.long)
      .trim()
      .required(translation.fields.phone.validation.required),
    group: Yup.string()
      .min(2, translation.fields.group.validation.short)
      .max(20, translation.fields.group.validation.long)
      .trim()
      .lowercase()
      .required(translation.fields.group.validation.required),
    series_length: Yup.string()
      .min(2, translation.fields.series_length.validation.short)
      .max(10, translation.fields.series_length.validation.long)
      .trim()
      .lowercase()
      .required(translation.fields.series_length.validation.required),
    quantity: Yup.number()
      .min(1, translation.fields.quantity.validation.short)
      .max(10, translation.fields.quantity.validation.long),
  })

  return (
    <>
      {status === 'ok' ? (
        <SuccessAlert translations={{ headline: translation.success.headline }} reset={reset} />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert
          translations={{ headline: translation.error.headline }}
          error={error}
          reset={reset}
        />
      ) : null}

      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            trackSubmitSignupForm()
            const signupRes = await fetch('/api/signup', {
              method: 'POST',
              body: JSON.stringify({
                company: values.company,
                name: values.name,
                email: values.email,
                phone: values.phone,
                group: values.group,
                series_length: values.series_length,
                quantity: values.quantity,
                application_bot_field: values.application_bot_field,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            if (signupRes.status !== 200) {
              setStatus('not_ok')
              setError(new Error('Non 200 response from backend'))
              actions.resetForm()
              actions.setSubmitting(false)
              return
            }

            if (signupRes.status === 200 && signupRes.redirected) {
              actions.resetForm()
              actions.setSubmitting(false)
              return router.push(signupRes.url)
            } else {
              const signupResData: SignupResponseData = await signupRes.json()
              if (!signupResData.success) {
                setStatus('not_ok')
                setError(new Error(signupResData.code))
                actions.resetForm()
                actions.setSubmitting(false)
              } else {
                setStatus('ok')
                actions.resetForm()
                actions.setSubmitting(false)
                if (signupResData.redirect_to_survey) {
                  location.href = `https://forms.reform.app/gLNw4a/post-signup-survey/7waj93?4f0ae710-16a4-4f25-8437-c2b710573148=${encodeURIComponent(
                    values.company
                  )}`
                } else {
                  router.push('/signup/success')
                }
              }
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Signup failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}
      >
        {(formikBag) => {
          const products = [
            {
              id: 1,
              name: 'Tävlingsanmälan Ö-viks Nätverkspadel',
              price:
                formikBag.values.series_length === '10weeks'
                  ? 299900
                  : formikBag.values.series_length === '5weeks'
                  ? 149900
                  : 299900,
              descriptionLine1:
                formikBag.values.series_length === '10weeks'
                  ? '10 veckor med start 21 september'
                  : formikBag.values.series_length === '5weeks'
                  ? '5 veckor med start 21 september'
                  : '10 veckor med start 21 september',
              descriptionLine2:
                formikBag.values.group === 'morning'
                  ? 'Morgon - 07:00'
                  : formikBag.values.group === 'lunch'
                  ? 'Lunch - 11:30'
                  : 'Morgon - 07:00',
              imageSrc: '/logo/np-logo.svg',
              imageAlt: 'Nätverkspadel logo',
            },
          ]

          const totalPrice =
            reduce(products, (sum, product) => sum + product.price, 0) * formikBag.values.quantity
          const totalWithSalesTax = round((totalPrice * 1.06) / 100, 0)
          const justSalesTax = round((totalPrice * 1.06 - totalPrice) / 100, 2)
          const roundedTotalPrice = round(totalPrice / 100, 0)

          return (
            <div className="bg-white">
              {/* Background color split screen for large screens */}
              <div
                className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block"
                aria-hidden="true"
              />
              <div
                className="fixed top-0 right-0 hidden h-full w-1/2 bg-indigo-900 lg:block"
                aria-hidden="true"
              />

              <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
                <h1 className="sr-only">Checkout</h1>

                <section
                  aria-labelledby="summary-heading"
                  className="bg-indigo-900 py-12 text-indigo-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pt-0 lg:pb-24"
                >
                  <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                    <h2 id="summary-heading" className="sr-only">
                      Order summary
                    </h2>

                    <dl>
                      <dt className="text-sm font-medium">Pris</dt>
                      <dd className="mt-1 text-3xl font-extrabold text-white">
                        {roundedTotalPrice}kr
                      </dd>
                    </dl>

                    <ul
                      role="list"
                      className="divide-y divide-white divide-opacity-10 text-sm font-medium"
                    >
                      {products.map((product) => (
                        <li key={product.id} className="flex items-start space-x-4 py-6">
                          <img
                            src={product.imageSrc}
                            alt={product.imageAlt}
                            className="w-30 h-20 flex-none rounded-md bg-transparent object-cover object-center"
                          />
                          <div className="flex-auto space-y-1">
                            <h3 className="text-white">
                              {formikBag.values.quantity}x {product.name}
                            </h3>
                            <p>{product.descriptionLine1}</p>
                            <p>{product.descriptionLine2}</p>
                          </div>
                          <p className="flex-none text-base font-medium text-white">
                            {product.price / 100}kr
                          </p>
                        </li>
                      ))}
                    </ul>

                    <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
                      <div className="flex items-center justify-between">
                        <dt>Subtotal</dt>
                        <dd>{roundedTotalPrice}kr</dd>
                      </div>

                      <div className="flex items-center justify-between">
                        <dt>Moms 6%</dt>
                        <dd>{justSalesTax}kr</dd>
                      </div>

                      <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                        <dt className="text-base">Total</dt>
                        <dd className="text-base">{totalWithSalesTax}kr</dd>
                      </div>
                    </dl>
                  </div>
                </section>

                <section
                  aria-labelledby="payment-and-shipping-heading"
                  className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24"
                >
                  <h2 id="payment-and-shipping-heading" className="sr-only">
                    Payment and shipping details
                  </h2>
                  <Form>
                    <Field name="application_bot_field">
                      {({ field }: Record<string, any>) => (
                        <p className="hidden">
                          <label htmlFor="application_bot_field">
                            Don’t fill this out if you&apos;re human: <input {...field} />
                          </label>
                        </p>
                      )}
                    </Field>
                    <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                      <div>
                        <h3 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                          Företagsinformation
                        </h3>
                        <Field name="company">
                          {({ field, meta }: Record<string, any>) => (
                            <div className="mt-6">
                              <label
                                htmlFor="company"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {translation.fields.company.label}
                              </label>
                              <input
                                {...field}
                                required
                                type="text"
                                autoComplete="name"
                                placeholder={translation.fields.company.placeholder}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              {meta.touched && meta.error && (
                                <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>

                      <div className="mt-10">
                        <h3 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                          Kontaktinformation
                        </h3>
                        <Field name="email">
                          {({ field, meta }: Record<string, any>) => (
                            <div className="mt-6">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {translation.fields.email.label}
                              </label>
                              <input
                                {...field}
                                required
                                type="email"
                                autoComplete="email"
                                placeholder={translation.fields.email.placeholder}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              {meta.touched && meta.error && (
                                <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                              )}
                            </div>
                          )}
                        </Field>
                        <Field name="name">
                          {({ field, meta }: Record<string, any>) => (
                            <div className="mt-6">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {translation.fields.name.label}
                              </label>
                              <input
                                {...field}
                                required
                                type="text"
                                autoComplete="name"
                                placeholder={translation.fields.name.placeholder}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              {meta.touched && meta.error && (
                                <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                              )}
                            </div>
                          )}
                        </Field>
                        <Field name="phone">
                          {({ field, meta }: Record<string, any>) => (
                            <div className="mt-6">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {translation.fields.phone.label}
                              </label>
                              <input
                                {...field}
                                required
                                type="tel"
                                autoComplete="tel"
                                placeholder={translation.fields.phone.placeholder}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              {meta.touched && meta.error && (
                                <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>

                      <div className="mt-10">
                        <h3 className="text-lg font-medium text-gray-900">När & Hur</h3>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
                          <Field name="group">
                            {({ field, meta }: Record<string, any>) => (
                              <div>
                                <label
                                  htmlFor="group"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {translation.fields.group.label}
                                </label>
                                <div className="mt-1">
                                  <select
                                    {...field}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  >
                                    <option value="morning">Morgon 90min (07:00)</option>
                                    <option value="lunch">Lunch 60min (11:30)</option>
                                  </select>
                                  {meta.touched && meta.error && (
                                    <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </Field>
                          {/* <Field name="series_length">
                            {({ field, meta }: Record<string, any>) => (
                              <div>
                                <label htmlFor="series_length" className="block text-sm font-medium text-gray-700">
                                  {translation.fields.series_length.label}
                                </label>
                                <div className="mt-1">
                                  <select
                                    {...field}
                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  >
                                    <option value="10weeks">Nu (gruppen har startat)</option>
                                    <option value="5weeks">Vecka 45 (i fem veckor)</option>
                                  </select>
                                  {meta.touched && meta.error && (
                                    <p className="text-red-500 text-xs italic pt-2">{meta.error}</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </Field> */}
                          <Field name="quantity">
                            {({ field, meta }: Record<string, any>) => (
                              <div>
                                <label
                                  htmlFor="quantity"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {translation.fields.quantity.label}
                                </label>
                                <div className="mt-1">
                                  <input
                                    {...field}
                                    type="number"
                                    min={1}
                                    max={10}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                  {meta.touched && meta.error && (
                                    <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="mt-10 flex justify-end border-t border-gray-200 pt-6">
                        <Link href="/">
                          <a className="mr-2 rounded-md border border-transparent bg-indigo-50 py-2 px-4 text-sm font-medium text-indigo-900 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                            Avbryt
                          </a>
                        </Link>
                        <button
                          type="submit"
                          className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          disabled={formikBag.isSubmitting || !formikBag.isValid}
                        >
                          {translation.button.text}
                        </button>
                      </div>
                    </div>
                  </Form>
                </section>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}
