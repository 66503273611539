import { useRouter } from 'next/router'

import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { Footer, Head, Header } from '../..'
import { translations } from './translations'
import { Series } from '../../../../pages'
import { FullContent } from './full-content'
import { ResultBanner } from './banner'

type Props = {
  series: Series
}

export const ResultV22Page = ({ series }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <div>
        <Header series={series} />
        <main>
          <ResultBanner />
          <FullContent />
        </main>
        <Footer />
      </div>
    </>
  )
}
