import { Locale } from '../../../../types'

type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Summering av Nätverkspadel säsong 2',
    pageDescription:
      'Andra säsongen av Ö-viks Nätverkspadel är avslutad och vi summerar vad vi gjort samt vad vi tror om framtiden.',
  },
  en: {
    pageTitle: 'A summary of Nätverkspadel season 2',
    pageDescription:
      'Andra säsongen av Ö-viks Nätverkspadel är avslutad och vi summerar vad vi gjort samt vad vi tror om framtiden.',
  },
}
