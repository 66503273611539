export * from './root'

export enum Locale {
  sv = 'sv',
  en = 'en',
}

export type User = {
  given_name: string
  family_name: string
  nickname: string
  name: string
  picture: string
  locale: string
  updated_at: string
  email: string
  email_verified: boolean
  sub: string
}
