/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChatIcon, TrendingUpIcon } from '@heroicons/react/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { Footer, Head, Header } from '../..'
import imgJamesClear from './assets/james-clear.jpg'
import { translations } from './translations'
import { Alternatives } from './alternatives'
import { replace } from 'lodash'
import { useState } from 'react'
import useFathomEvents from '../../../../hooks/useFathomEvents'
import { Series } from '../../../../pages'
import { Testimonial } from './testimonials/single-testimonial'
import { SeasonEndResultBanner } from './bottom-banner'

const testimonials: Testimonial[] = [
  {
    embedUrl: 'https://www.youtube-nocookie.com/embed/1d4G2_fzaY0',
    logoUrl:
      'https://res.cloudinary.com/db7wmjwhe/image/upload/v1639676714/logo_color_white_fvqbfm_hngh0a.svg',
    personName: 'Johan Lindgren',
    companyName: 'XLENT',
  },
  {
    embedUrl: 'https://www.youtube-nocookie.com/embed/eZPvqaSwLLU',
    logoUrl: 'https://res.cloudinary.com/db7wmjwhe/image/upload/v1639676619/bonnier_qpxyty.svg',
    personName: 'Mikael Nordstrand',
    companyName: 'Bonnier News Local',
  },
  {
    embedUrl: 'https://www.youtube-nocookie.com/embed/lQuH5zfznC8',
    logoUrl: 'https://res.cloudinary.com/db7wmjwhe/image/upload/v1636013468/carstedts_a1hgoa.png',
    personName: 'Martin Carstedt',
    companyName: 'Carstedts Bil',
  },
  {
    embedUrl: 'https://www.youtube-nocookie.com/embed/zmORqW8yaZU',
    logoUrl:
      'https://res.cloudinary.com/db7wmjwhe/image/upload/v1636013351/placerum_logotyp_RGB_vyrt3e.svg',
    personName: 'Victor Hansson',
    companyName: 'Placerum',
  },
]

type Props = {
  series: Series
}

export const HomePage = ({ series }: Props) => {
  const { trackSignupClickOnHome } = useFathomEvents()
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]
  const [packageLength, setPackageLength] = useState<number>(10)

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <div className="bg-white">
        <Header series={series} />
        <main>
          {/* Hero section */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src="/assets/man-holding-padel-rack-oliver-1.jpg"
                    alt="Player holding rack"
                  />
                  {/* <Image
                  className="h-full w-full object-cover"
                  src={translation.images.holdingRack.source}
                  alt={translation.images.holdingRack.alt}
                  layout="responsive"
                  objectFit="cover"
                /> */}
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-blue-400 mix-blend-multiply" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">{translation.headlinePlain}</span>
                    <span className="block text-blue-200">{translation.headlineMarked}</span>
                  </h1>
                  <p className="mx-auto mt-6 max-w-lg text-center text-xl text-blue-200 sm:max-w-3xl">
                    {translation.subline}
                  </p>
                  <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                    <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                      {translation.primaryCta.type === 'anchor' ? (
                        <a
                          href={translation.primaryCta.href}
                          className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8"
                        >
                          {translation.primaryCta.text}
                        </a>
                      ) : null}
                      {translation.primaryCta.type === 'external' ? (
                        <a
                          href={translation.primaryCta.href}
                          className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {translation.primaryCta.text}
                        </a>
                      ) : null}
                      {translation.primaryCta.type === 'link' ? (
                        <Link href={translation.primaryCta.href}>
                          <a
                            onClick={trackSignupClickOnHome}
                            className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8"
                          >
                            {translation.primaryCta.text}
                          </a>
                        </Link>
                      ) : null}
                      {translation.secondaryCta.type === 'anchor' ? (
                        <a
                          href={translation.secondaryCta.href}
                          className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                        >
                          {translation.secondaryCta.text}
                        </a>
                      ) : null}
                      {translation.secondaryCta.type === 'external' ? (
                        <a
                          href={translation.secondaryCta.href}
                          className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {translation.secondaryCta.text}
                        </a>
                      ) : null}
                      {translation.secondaryCta.type === 'link' ? (
                        <Link href={translation.secondaryCta.href}>
                          <a className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8">
                            {translation.secondaryCta.text}
                          </a>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo Cloud */}
          <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
              <p className="flex justify-center text-center text-sm font-semibold uppercase tracking-wide text-gray-500">
                {translation.logoCloudHeadline}
              </p>
              <div className="mt-6 grid grid-cols-4 gap-8">{translation.logoCloud}</div>
            </div>
          </div>

          <Testimonial
            id="first-testimonial-block"
            firstTestimonial={testimonials[0]}
            secondTestimonial={testimonials[1]}
          />

          <Alternatives packageLength={packageLength} setPackageLength={setPackageLength} />

          {/* Alternating Feature Sections */}
          <div className="relative overflow-hidden pt-16 pb-32" id="features">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
            />
            <div className="relative">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-blue-600">
                        <TrendingUpIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {translation.firstFeature.headline}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        {translation.firstFeature.description}
                      </p>
                      <div className="mt-6">
                        {translation.firstFeature.cta.type === 'anchor' ? (
                          <a
                            href={translation.firstFeature.cta.href}
                            className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                          >
                            {translation.firstFeature.cta.text}
                          </a>
                        ) : null}
                        {translation.firstFeature.cta.type === 'external' ? (
                          <a
                            href={translation.firstFeature.cta.href}
                            className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {translation.firstFeature.cta.text}
                          </a>
                        ) : null}
                        {translation.firstFeature.cta.type === 'link' ? (
                          <Link href={translation.firstFeature.cta.href}>
                            <a
                              onClick={trackSignupClickOnHome}
                              className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                            >
                              {translation.firstFeature.cta.text}
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                      <div>
                        <p className="text-base text-gray-500">
                          &ldquo;You do not rise to the level of your goals. You fall to the level
                          of your systems.&rdquo;
                        </p>
                      </div>
                      <footer className="mt-3">
                        <div className="flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <Image
                              className="h-6 w-6 rounded-full"
                              src={imgJamesClear}
                              alt="James Clear"
                              layout="fixed"
                              width={50}
                              height={50}
                            />
                          </div>
                          <div className="text-base font-medium text-gray-700">
                            {translation.jamesClearNote}
                          </div>
                        </div>
                      </footer>
                    </blockquote>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    {/* <Image
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src={translation.images.lyingDown.source}
                      alt={translation.images.lyingDown.alt}
                      layout="responsive"
                      objectFit="contain"
                    /> */}
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="/assets/fredrik-striking-ball.jpg"
                      alt="Player just before hitting the ball"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-24">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-purple-600 to-blue-600">
                        <ChatIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {translation.secondFeature.headline}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        {translation.secondFeature.description}
                      </p>
                      <div className="mt-6">
                        {translation.secondFeature.cta.type === 'anchor' ? (
                          <a
                            href={translation.secondFeature.cta.href}
                            className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                          >
                            {translation.secondFeature.cta.text}
                          </a>
                        ) : null}
                        {translation.secondFeature.cta.type === 'external' ? (
                          <a
                            href={translation.secondFeature.cta.href}
                            className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {translation.secondFeature.cta.text}
                          </a>
                        ) : null}
                        {translation.secondFeature.cta.type === 'link' ? (
                          <Link href={translation.secondFeature.cta.href}>
                            <a
                              onClick={trackSignupClickOnHome}
                              className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                            >
                              {translation.secondFeature.cta.text}
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                  <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    {/* <Image
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={translation.images.blueAndOrange.source}
                    alt={translation.images.blueAndOrange.alt}
                    layout="responsive"
                    objectFit="contain"
                  /> */}
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="/assets/martin-hitting-the-ball.jpg"
                      alt="Player hitting the ball"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Testimonial
            id="second-testimonial-block"
            firstTestimonial={testimonials[2]}
            secondTestimonial={testimonials[3]}
          />

          {/* Gradient Feature Section */}
          <div className="bg-gradient-to-r from-purple-800 to-blue-700">
            <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
              <h2 className="text-3xl font-extrabold tracking-tight text-white">
                {translation.featuresHeadline}
              </h2>
              <p className="mt-4 max-w-3xl text-lg text-purple-200">
                {translation.featuresDescription}
              </p>
              <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                {translation.features.map((feature) => (
                  <div key={feature.name}>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-white bg-opacity-10">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                      <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Stats section */}
          <div className="relative bg-gray-900">
            <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
              <div className="h-full w-full xl:grid xl:grid-cols-2">
                <div className="h-full xl:relative xl:col-start-2">
                  <img
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src="/assets/hermes-lots-of-balls-on-court.jpg"
                    alt="Lots of balls on the court"
                  />
                  {/* <Image
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src={translation.images.balls.source}
                  alt={translation.images.balls.alt}
                  layout="responsive"
                  objectFit="cover"
                /> */}
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                  />
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
              <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                <h2 className="text-sm font-semibold uppercase tracking-wide">
                  <span className="bg-gradient-to-r from-purple-300 to-blue-300 bg-clip-text text-transparent">
                    {translation.metricsSubline}
                  </span>
                </h2>
                <p className="mt-3 text-3xl font-extrabold text-white">
                  {translation.metricsHeadline}
                </p>
                <div className="text-md prose mt-5 text-gray-300">
                  {translation.metricsDescription}
                </div>
                <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                  {translation.metrics.map((item) => (
                    <p key={item.id}>
                      <span className="block text-2xl font-bold text-white">{item.stat}</span>
                      <span className="mt-1 block text-base text-gray-300">
                        <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-indigo-700">
            <div className="mx-auto max-w-3xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">{translation.companySignupCta.headlinePartOne}</span>
                <span className="block">{translation.companySignupCta.headlinePartTwo}</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                {replace(
                  translation.companySignupCta.description,
                  '{{packageLength}}',
                  `${packageLength}`
                )}
              </p>
              {translation.companySignupCta.button.type === 'anchor' ? (
                <a
                  href={translation.companySignupCta.button.href}
                  onClick={trackSignupClickOnHome}
                  className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
                >
                  {translation.companySignupCta.button.text}
                </a>
              ) : null}
              {translation.companySignupCta.button.type === 'external' ? (
                <a
                  href={translation.companySignupCta.button.href}
                  onClick={trackSignupClickOnHome}
                  className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translation.companySignupCta.button.text}
                </a>
              ) : null}
              {translation.companySignupCta.button.type === 'link' ? (
                <a
                  href={translation.companySignupCta.button.href}
                  onClick={trackSignupClickOnHome}
                  className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
                >
                  {translation.companySignupCta.button.text}
                </a>
              ) : null}
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-white">
            <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8">
              <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">{translation.bottomCta.prompt}</span>
                <span className="block bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                  {translation.bottomCta.headline}
                </span>
              </h2>
              <div className="mt-6 space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
                {translation.bottomCta.primaryButton.type === 'anchor' ? (
                  <a
                    href={translation.bottomCta.primaryButton.href}
                    className="flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700"
                  >
                    {translation.bottomCta.primaryButton.text}
                  </a>
                ) : null}
                {translation.bottomCta.primaryButton.type === 'link' ? (
                  <Link href={translation.bottomCta.primaryButton.href}>
                    <a className="flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-blue-700">
                      {translation.bottomCta.primaryButton.text}
                    </a>
                  </Link>
                ) : null}
                {translation.bottomCta.secondaryButton.type === 'anchor' ? (
                  <a
                    onClick={trackSignupClickOnHome}
                    href={translation.bottomCta.secondaryButton.href}
                    className="flex items-center justify-center rounded-md border border-transparent bg-blue-50 px-4 py-3 text-base font-medium text-blue-800 shadow-sm hover:bg-blue-100"
                  >
                    {translation.bottomCta.secondaryButton.text}
                  </a>
                ) : null}
                {translation.bottomCta.secondaryButton.type === 'link' ? (
                  <Link href={translation.bottomCta.secondaryButton.href}>
                    <a
                      onClick={trackSignupClickOnHome}
                      className="flex items-center justify-center rounded-md border border-transparent bg-blue-50 px-4 py-3 text-base font-medium text-blue-800 shadow-sm hover:bg-blue-100"
                    >
                      {translation.bottomCta.secondaryButton.text}
                    </a>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <SeasonEndResultBanner />
    </>
  )
}
