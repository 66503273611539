import { Round } from '../../../..'

type Props = {
  round: Round
}

export const GameStats = ({ round }: Props) => {
  const stats = [
    { name: 'Bekräftade matcher', stat: `${round.confirmed_matches}` },
    { name: 'Spelade matcher', stat: `${round.completed_matches}` },
  ]
  return (
    <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Matcher</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
