import { RankedPlayers, RankingList } from '../../../..'

type Props = {
  players: RankedPlayers
}

export const Ranking = ({ players }: Props) => {
  return (
    <div className="bg-gray-50 overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Ranking</h3>
          <p className="mt-2 text-sm text-gray-500">
            Detta är rankingen som vi genererar matcher utifrån. Observera att det kanske inte
            stämmer 100% om anmälda spelare har ändrats efter att matcherna skapades.
          </p>
        </div>
        {players.length > 0 ? <RankingList players={players} /> : null}
      </div>
    </div>
  )
}
