import { PlusIcon, StopIcon } from '@heroicons/react/outline'
import { reduce } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  AdminMatchResult,
  AdminMatchResultList,
  CreateMatchModal,
  Head,
  ManageMatchModal,
  RoundDetailsPanel,
  SignupsList,
} from '../../..'
import { Locale } from '../../../../../types'
import { makeLocale } from '../../../../../utils/locale'
import { Header } from '../../../03-organisms/app-layout/header'
import { SearchHeader } from '../../../03-organisms/app-layout/search-header'
import { translations } from './translations'
import { AdminSeriesRoundTemplateProps as Props } from './types'

export const AdminSeriesRoundDetailsTemplate = ({ round, series, matches, signups }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [addNewMatchModalOpen, setAddNewMatchModalOpen] = useState<boolean>(false)
  const [manageMatchModalOpen, setManageMatchModalOpen] = useState<boolean>(false)
  const [matchId, setMatchId] = useState<number>()
  const [match, setMatch] = useState<AdminMatchResult>()

  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector('html')
      if (htmlEl) {
        htmlEl.classList.add('h-full')
        htmlEl.classList.add('bg-white')
      }
      const bodyEl = document.querySelector('body')
      if (bodyEl) {
        bodyEl.classList.add('h-full')
        bodyEl.classList.add('overflow-hidden')
      }
      const nextWrapperEl = document.querySelector('#__next')
      if (nextWrapperEl) {
        nextWrapperEl.classList.add('h-full')
        nextWrapperEl.classList.add('overflow-hidden')
      }
    }
  })

  const handleMatchClick = (match: AdminMatchResult) => {
    if (match) {
      setMatch(match)
      if (match.match_id) setMatchId(match.match_id)
    }
    setManageMatchModalOpen(true)
  }

  return (
    <>
      <Head title={translation.pageTitle} description={translation.pageDescription} />
      <div className="h-full flex">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          incomingSeriesId={series.id}
          seriesContext={series}
        />
        {/* Main column */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/* Search header */}
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none pb-12">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="flex-1 min-w-0">
                <h1 className="text-base font-medium leading-6 text-gray-900 sm:truncate">
                  Rundor för {round && round.series_name ? round.series_name : 'denna serie'}
                </h1>
              </div>
            </div>
            <RoundDetailsPanel round={round} series={series} />
            <div className="py-5 border-b border-gray-200">
              <h3 className="px-4 text-lg leading-6 font-bold text-gray-900">
                Anmälningar{' '}
                {signups && signups.length > 0
                  ? `(${reduce(
                      signups,
                      (sum, signup) => (signup.signup_state === 'accepted' ? sum + 1 : sum),
                      0
                    )}st bekräftade)`
                  : null}
              </h3>
            </div>
            {signups.length === 0 ? (
              <div className="mt-12 text-center">
                <StopIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Inga anmälningar för denna vecka
                </h3>
                <p className="mt-1 text-sm text-gray-500">Behöver det skickas ut en påminnelse?</p>
              </div>
            ) : null}
            {signups.length > 0 ? (
              <SignupsList signups={signups} showDetails={false} series={series} round={round} />
            ) : null}
            <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="px-4 text-lg leading-6 font-bold text-gray-900">Matcher</h3>
              {['current', 'next_week', 'past'].includes(round.status) ? (
                <div className="px-4 mt-3 sm:mt-0">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setAddNewMatchModalOpen(true)}
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Ny match
                  </button>
                </div>
              ) : null}
            </div>
            {matches.length === 0 ? (
              <div className="mt-12 text-center">
                <StopIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Inga matcher för denna runda
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Klicka på knappen nedan för att skapa den första.
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setAddNewMatchModalOpen(true)}
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Ny match
                  </button>
                </div>
              </div>
            ) : null}
            {matches.length > 0 ? (
              <AdminMatchResultList matches={matches} handleMatchClick={handleMatchClick} />
            ) : null}
            <CreateMatchModal
              open={addNewMatchModalOpen}
              setOpen={setAddNewMatchModalOpen}
              roundId={round.round_id}
              seriesId={round.series_id}
            />
            <ManageMatchModal
              open={manageMatchModalOpen}
              setOpen={(open: boolean) => {
                setManageMatchModalOpen(open)
                if (!open) {
                  setMatchId(undefined)
                  setMatch(undefined)
                }
              }}
              roundId={round.round_id}
              seriesId={round.series_id}
              matchId={matchId}
              match={match}
            />
          </main>
        </div>
      </div>
    </>
  )
}
