import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { RoundDetails } from '..'
import { SeriesContext } from '../../../../api-utils'

const SignupState = t.union([
  t.literal('created'),
  t.literal('sent'),
  t.literal('accepted'),
  t.literal('rejected'),
  t.literal('reminder_sent'),
])

export const Signup = t.intersection([
  t.type({
    invitation_public_id: UUID,
    team_name: t.string,
    team_id: UUID,
    series_round_id: t.number,
    series_round_name: t.string,
    series_name: t.string,
    series_round_start: t.string,
    series_round_end: t.string,
    team_series_group: t.string,
    team_series_length: t.string,
    invite_link: t.string,
    reminder_text: t.string,
  }),
  t.partial({
    signup_id: t.union([t.null, t.number]),
    signup_public_id: t.union([t.null, UUID]),
    signup_additional_answers: t.union([t.null, t.UnknownRecord]),
    signup_created_at: t.union([t.null, t.string]),
    signup_state: t.union([t.null, SignupState]),
    signup_comment: t.union([t.null, t.string]),
    user_name: t.union([t.null, t.string]),
    user_id: t.union([t.null, UUID]),
    team_member_id: t.union([t.null, t.number]),
  }),
])

export type Signup = t.TypeOf<typeof Signup>

export type ComponentProps = {
  signup: Signup
  round: RoundDetails
  series: SeriesContext
}
