import { CheckIcon, DotsCircleHorizontalIcon, StopIcon } from '@heroicons/react/outline'
import { classNames } from '../../../../utils/classnames'
import { ComponentProps, Breakdown } from './types'

export { Breakdown }

export const AdminPlayerMatcherTableRowItem = ({ row, rowIdx, allRows }: ComponentProps) => {
  return (
    <tr key={`${row.player_id}--row`} className={classNames(rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50')}>
      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">{row.player_name}</td>
      {allRows.map((innerRow) => {
        return (
          <>
            {row.player_id === innerRow.player_id ? (
              <td key={`${innerRow.player_id}--inner--column`} className="px-2 py-1 whitespace-nowrap text-sm text-white bg-gray-900 border border-gray-200">
                <StopIcon className="h-5 w-5" aria-hidden="true" />
              </td>
            ) : row.partners.map(partner => partner.id).includes(innerRow.player_id) ? (
              <td key={`${innerRow.player_id}--inner--column`} className="px-2 py-1 whitespace-nowrap text-sm text-gray-900 bg-green-500 border border-gray-200">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </td>
            ) : (
              <td key={`${innerRow.player_id}--inner--column`} className="px-2 py-1 whitespace-nowrap text-sm text-gray-900 bg-gray-50 border border-gray-200" />
            )}
          </>
        )
      })}
    </tr>
  )
}