import { Locale } from '../../../../../types'


type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Matcher',
    pageDescription:
      'Här kan du se de matcher som hittills har spelats i ÖNP.',
  },
  en: {
    pageTitle: 'Matcher',
    pageDescription:
      'Här kan du se de matcher som hittills har spelats i ÖNP.',
  },
}
