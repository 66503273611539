import * as t from 'io-ts'
import { AdminRound } from '../../02-molecules/admin-round-row-item/types'

export const AdminRoundRows = t.array(AdminRound)

export type AdminRoundRows = t.TypeOf<typeof AdminRoundRows>

export type ComponentProps = {
  rounds: AdminRoundRows
}
