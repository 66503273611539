import * as t from 'io-ts'

const Winner = t.union([
  t.literal('home'),
  t.literal('away'),
  t.literal('draw'),
  t.literal('unknown'),
])

const MatchState = t.union([
  t.literal('upcoming'),
  t.literal('played'),
  t.literal('awaiting_play'),
  t.literal('not_confirmed'),
  t.literal('unknown'),
])

const NullableString = t.union([t.string, t.null])
const NullableNumber = t.union([t.number, t.null])
const NullableBoolean = t.union([t.boolean, t.null])
const NullableWinner = t.union([Winner, t.null])

const MatchTeam = t.type({
  team_name: t.string,
  player_name: t.string,
})

export const AdminMatchResult = t.type({
  match_id: NullableNumber,
  round_name: t.string,
  round_start: t.string,
  series_id: t.string,
  home_teams: t.array(MatchTeam),
  away_teams: t.array(MatchTeam),
  match_location_name: t.string,
  match_location_adress: t.string,
  match_location_zipcode: t.string,
  match_location_city: t.string,
  match_location_country: t.string,
  is_upcoming_match: t.boolean,
  home_total_games: NullableNumber,
  home_set_one_games: NullableNumber,
  home_set_two_games: NullableNumber,
  home_set_three_games: NullableNumber,
  away_total_games: NullableNumber,
  away_set_one_games: NullableNumber,
  away_set_two_games: NullableNumber,
  away_set_three_games: NullableNumber,
  winner: NullableWinner,
  winning_points: NullableNumber,
  loosing_points: NullableNumber,
  tiebreak: NullableBoolean,
  home_tiebreak_points: NullableNumber,
  away_tiebreak_points: NullableNumber,
  match_calculated_state: MatchState,
  match_state: NullableString,
  match_result_state: NullableString,
})

export type AdminMatchResult = t.TypeOf<typeof AdminMatchResult>

export type ComponentProps = {
  match: AdminMatchResult
  handleMatchClick: (match: AdminMatchResult) => void
}