import { useEffect, useState } from 'react'
import { RoundPageProps as Props } from './types'
import { Header } from '../../../../03-organisms/app-layout/header'
import { SearchHeader } from '../../../../03-organisms/app-layout/search-header'
import { RoundBreadcrumbs } from '../../../..'
import { InviteStats } from './invite-stats'
import { GameStats } from './game-stats'
import { Matches } from './matches'
import { Ranking } from './ranking'

export const RoundPageLayout = ({ round, seriesContext, matches, players }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector('html')
      if (htmlEl) {
        htmlEl.classList.add('h-full')
        htmlEl.classList.add('bg-white')
      }
      const bodyEl = document.querySelector('body')
      if (bodyEl) {
        bodyEl.classList.add('h-full')
        bodyEl.classList.add('overflow-hidden')
      }
      const nextWrapperEl = document.querySelector('#__next')
      if (nextWrapperEl) {
        nextWrapperEl.classList.add('h-full')
        nextWrapperEl.classList.add('overflow-hidden')
      }
    }
  })
  return (
    <>
      <div className="h-full flex">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          incomingSeriesId={seriesContext.id}
          seriesContext={seriesContext}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <RoundBreadcrumbs seriesContext={seriesContext} round={round} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-50">
            <InviteStats round={round} />
            <Ranking players={players} />
            <Matches seriesContext={seriesContext} matches={matches} />
            <GameStats round={round} />
          </main>
        </div>
      </div>
    </>
  )
}
