import { Locale } from '../../../../../types'

type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Rapportera matchresultat',
    pageDescription: 'Rapportera matchresultat i Nätverkspadeln',
  },
  en: {
    pageTitle: 'Rapportera matchresultat',
    pageDescription: 'Rapportera matchresultat i Nätverkspadeln',
  },
}
