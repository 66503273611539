import { DateTime } from 'luxon'
import { ComponentProps, RankedPlayer } from './types'

export { RankedPlayer }

export const RankingRowItem = ({ player, listIndex }: ComponentProps) => {
  const dtSignedUpAt = DateTime.fromISO(player.created_at)
  return (
    <tr key={player.name} className={listIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {listIndex + 1}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {player.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {player.points_standing}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{player.plus_minus}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{player.no_of_matches}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {dtSignedUpAt.setLocale('sv').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center h-5">
          <input
            id="seeded"
            name="seeded"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={player.seeded}
            disabled
          />
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center h-5">
          <input
            id="guest"
            name="guest"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={player.guest}
            disabled
          />
        </div>
      </td>
    </tr>
  )
}
