import * as t from 'io-ts'
import { RoundDetails } from '../..'
import { SeriesContext } from '../../../../api-utils'
import { Signup } from '../../02-molecules/admin-signup-row-item/types'

export const SignupRows = t.array(Signup)

export type SignupRows = t.TypeOf<typeof SignupRows>

export type ComponentProps = {
  signups: SignupRows
  showDetails?: boolean
  round: RoundDetails
  series: SeriesContext
}
