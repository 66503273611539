import * as Yup from 'yup'
import Link from 'next/link'
import { Field, Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Locale } from '../../../../types'
import { makeLocale } from '../../../../utils/locale'
import { translations } from './types'
import { ErrorAlert } from '../'
import { ReportMatchRequestBody, ReportMatchResponseData } from '../../../../pages/api/match/report'
import { MatchHeader } from '../match-header'
import { MatchResult } from '../match-result-row-item'
import { SeriesContext } from '../../../../api-utils'
import { CustomSuccessAlert } from './custom-success-alert'

type FormValues = {
  home_set_one: number
  home_set_two: number
  home_set_three: number
  home_tiebreak: number
  away_set_one: number
  away_set_two: number
  away_set_three: number
  away_tiebreak: number
}

type Props = {
  match: MatchResult
  seriesContext: SeriesContext
}

export const MatchEditResultForm = ({ match, seriesContext }: Props) => {
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const initialValues: FormValues = {
    home_set_one: match.home_set_one_games || 0,
    home_set_two: match.home_set_two_games || 0,
    home_set_three: match.home_set_three_games || 0,
    home_tiebreak: match.home_tiebreak_points || 0,
    away_set_one: match.away_set_one_games || 0,
    away_set_two: match.away_set_two_games || 0,
    away_set_three: match.away_set_three_games || 0,
    away_tiebreak: match.away_tiebreak_points || 0,
  }

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  const ValidationSchema = Yup.object().shape({
    home_set_one: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_set_two: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_set_three: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_tiebreak: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem'),
    away_set_one: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_set_two: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_set_three: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_tiebreak: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem'),
  })

  return (
    <>
      <MatchHeader match={match} seriesContext={seriesContext} />
      {status === 'ok' ? (
        <CustomSuccessAlert
          translations={{ headline: translation.success.headline }}
          reset={reset}
          match={match}
          seriesContext={seriesContext}
        />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert
          translations={{ headline: translation.error.headline }}
          error={error}
          reset={reset}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            if (confirm('Är du säker på att du vill rapportera detta matchresultat?')) {
              const isTiebreak = values.home_tiebreak > 0 || values.away_tiebreak > 0
              const requestBody: ReportMatchRequestBody = {
                type: match.is_upcoming_match ? 'create' : 'update',
                matchPublicId: match.match_public_id,
                tiebreak: isTiebreak,
                sendNotifications: true,
                homeResultSet: {
                  set_one: values.home_set_one,
                  set_two: values.home_set_two,
                  set_three: values.home_set_three,
                  tiebreak: isTiebreak && values.home_tiebreak ? values.home_tiebreak : 0,
                },
                awayResultSet: {
                  set_one: values.away_set_one,
                  set_two: values.away_set_two,
                  set_three: values.away_set_three,
                  tiebreak: isTiebreak && values.away_tiebreak ? values.away_tiebreak : 0,
                },
              }
              const ReportGameResultRes = await fetch('/api/match/report', {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                  'Content-Type': 'application/json',
                },
              })

              if (ReportGameResultRes.status !== 200) {
                setStatus('not_ok')
                setError(new Error('Non 200 response from backend'))
                actions.resetForm()
                actions.setSubmitting(false)
                return
              }

              const ReportGameResultResData: ReportMatchResponseData =
                await ReportGameResultRes.json()
              if (!ReportGameResultResData.success) {
                setStatus('not_ok')
                setError(new Error(ReportGameResultResData.code))
                actions.resetForm()
                actions.setSubmitting(false)
              } else {
                actions.resetForm()
                actions.setSubmitting(false)
                setStatus('ok')
                router.replace(router.asPath)
              }
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Report game result failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}
      >
        {(formikBag) => {
          return (
            <Form>
              <div className="py-5">
                <div>
                  <div className="flex gap-x-2">
                    <p className="col-span-6 block w-full rounded-md border-gray-300 text-center shadow-sm sm:col-span-3 sm:text-sm">
                      Set 1
                    </p>
                    <p className="col-span-6 block w-full rounded-md border-gray-300 text-center shadow-sm sm:col-span-3 sm:text-sm">
                      Set 2
                    </p>
                    <p className="col-span-6 block w-full rounded-md border-gray-300 text-center shadow-sm sm:col-span-3 sm:text-sm">
                      Set 3
                    </p>
                    <p className="col-span-6 block w-full rounded-md border-gray-300 text-center shadow-sm sm:col-span-3 sm:text-sm">
                      Tiebreak
                    </p>
                  </div>
                  <div className="flex gap-x-2">
                    <Field name="home_set_one">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="home_set_one" className="sr-only">
                            Hemma set 1
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="home_set_two">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="home_set_two" className="sr-only">
                            Hemma set 2
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="home_set_three">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="home_set_three" className="sr-only">
                            Hemma set 3
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="home_tiebreak">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="home_tiebreak" className="sr-only">
                            Hemma tiebreak
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="flex gap-x-2">
                    <Field name="away_set_one">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="away_set_one" className="sr-only">
                            Borta set 1
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="away_set_two">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="away_set_two" className="sr-only">
                            Borta set 2
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="away_set_three">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="away_set_three" className="sr-only">
                            Borta set 3
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="away_tiebreak">
                      {({ field, meta }: Record<string, any>) => (
                        <div className="col-span-6 block w-full sm:col-span-3">
                          <label htmlFor="away_tiebreak" className="sr-only">
                            Borta tiebreak
                          </label>
                          <input
                            {...field}
                            required
                            type="number"
                            className="mt-1 block w-full rounded-md border-gray-300 text-center shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <p className="pt-2 text-xs italic text-red-500">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="py-3 text-right">
                <Link href={`/series/${seriesContext.id}/match/${match.match_public_id}`}>
                  <a className="mr-2 rounded-md border border-transparent bg-indigo-50 py-2 px-4 text-sm font-medium text-indigo-900 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                    Avbryt
                  </a>
                </Link>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  disabled={formikBag.isSubmitting || !formikBag.isValid}
                >
                  {translation.button.text}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
