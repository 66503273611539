import Link from 'next/link'
import { DateTime } from 'luxon'
import { ReactNode } from 'react'
import { SeriesContext } from '../../../../api-utils'
import { classNames } from '../../../../utils/classnames'
import { ComponentProps, MatchResult, MatchTeam } from './types'
import { ChevronRightIcon } from '@heroicons/react/solid'

export { MatchResult }

const getParticipantName = (matchTeam: MatchTeam, seriesContext: SeriesContext): ReactNode => {
  switch (seriesContext.type) {
    case 'team':
      return (
        <>
          {matchTeam.team_name} (<span className="font-bold">{matchTeam.player_name}</span>)
        </>
      )
    case 'tournament':
    case 'single_player':
      return (
        <>
          <span className="font-bold">{matchTeam.player_name}</span>
        </>
      )
    default:
      return null
  }
}

export const MatchResultRowItem = ({ match, seriesContext }: ComponentProps) => {
  const winner1st =
    match.home_set_one_games > match.away_set_one_games
      ? 'home'
      : match.home_set_one_games < match.away_set_one_games
      ? 'away'
      : 'undetermined'
  const winner2nd =
    match.home_set_two_games > match.away_set_two_games
      ? 'home'
      : match.home_set_two_games < match.away_set_two_games
      ? 'away'
      : 'undetermined'
  const winner3rd =
    match.home_set_three_games > match.away_set_three_games
      ? 'home'
      : match.home_set_three_games < match.away_set_three_games
      ? 'away'
      : 'undetermined'
  const winnerTiebreak =
    match.home_tiebreak_points > match.away_tiebreak_points
      ? 'home'
      : match.home_tiebreak_points < match.away_tiebreak_points
      ? 'away'
      : 'undetermined'

  const dtStart = DateTime.fromISO(match.round_start)

  const matchResultBoxClasses = `px-3 py-2 text-center font-mono`

  return (
    <li key={match.match_id} className="block bg-white hover:bg-gray-50">
      <Link href={`/series/${seriesContext.id}/match/${match.match_public_id}`}>
        <a>
          <div className="flex items-center bg-white px-4 py-4 font-light hover:bg-gray-50 sm:px-6">
            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="border-b border-gray-200 pb-5 pt-3 sm:border-0 sm:px-6">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  {match.round_name} @ {match.match_location_name}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {dtStart.setLocale('sv').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                </p>
              </div>
              <div>
                <div className="mt-4 grid grid-cols-8 grid-rows-2 items-center gap-x-2 py-1 text-xs font-light">
                  <p className="col-span-1 row-span-2 rounded-md border border-solid border-gray-200 px-2 py-2 text-center font-bold">
                    L1
                  </p>
                  <p className={classNames('col-span-7 py-0')}>
                    {getParticipantName(match.home_teams[0], seriesContext)}
                  </p>
                  <p className={classNames('col-span-7 py-0')}>
                    {getParticipantName(match.home_teams[1], seriesContext)}
                  </p>
                </div>
                <div className="grid grid-cols-8 grid-rows-2 items-center gap-x-2 py-1 text-xs font-light">
                  <p className="col-span-1 row-span-2 rounded-md border border-solid border-gray-200 px-2 py-2 text-center font-bold">
                    L2
                  </p>
                  <p className={classNames('col-span-7 py-0')}>
                    {getParticipantName(match.away_teams[0], seriesContext)}
                  </p>
                  <p className={classNames('col-span-7 py-0')}>
                    {getParticipantName(match.away_teams[1], seriesContext)}
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  match.tiebreak ? 'grid-cols-7' : 'grid-cols-6',
                  'mt-4 grid grid-rows-3 items-center divide-x-2 divide-y-2 rounded-md border border-solid border-gray-200 text-xs font-light'
                )}
              >
                <p />
                <p title="Set 1" className={classNames('font-bold', matchResultBoxClasses)}>
                  1
                </p>
                <p title="Set 2" className={classNames('font-bold', matchResultBoxClasses)}>
                  2
                </p>
                <p title="Set 3" className={classNames('font-bold', matchResultBoxClasses)}>
                  3
                </p>
                <p
                  title="Totalt antal gem"
                  className={classNames('font-bold', matchResultBoxClasses)}
                >
                  T
                </p>
                {match.tiebreak ? (
                  <p title="Tiebreak" className={classNames('font-bold', matchResultBoxClasses)}>
                    TB
                  </p>
                ) : null}
                <p title="Poäng" className={classNames('font-bold', matchResultBoxClasses)}>
                  P
                </p>
                <p className={classNames('font-bold', matchResultBoxClasses)}>L1</p>
                <p
                  className={classNames(
                    winner1st === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_one_games}
                </p>
                <p
                  className={classNames(
                    winner2nd === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_two_games}
                </p>
                <p
                  className={classNames(
                    winner3rd === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_three_games}
                </p>
                <p
                  className={classNames(
                    match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_total_games}
                </p>
                {match.tiebreak ? (
                  <p
                    className={classNames(
                      winnerTiebreak === 'home' ? 'bg-green-100' : 'bg-red-100',
                      matchResultBoxClasses
                    )}
                  >
                    {match.home_tiebreak_points}
                  </p>
                ) : null}
                <p
                  className={classNames(
                    match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.winner === 'home' ? match.winning_points : match.loosing_points}
                </p>
                <p className={classNames('font-bold', matchResultBoxClasses)}>L2</p>
                <p
                  className={classNames(
                    winner1st === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_one_games}
                </p>
                <p
                  className={classNames(
                    winner2nd === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_two_games}
                </p>
                <p
                  className={classNames(
                    winner3rd === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_three_games}
                </p>
                <p
                  className={classNames(
                    match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_total_games}
                </p>
                {match.tiebreak ? (
                  <p
                    className={classNames(
                      winnerTiebreak === 'away' ? 'bg-green-100' : 'bg-red-100',
                      matchResultBoxClasses
                    )}
                  >
                    {match.away_tiebreak_points}
                  </p>
                ) : null}
                <p
                  className={classNames(
                    match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.winner === 'away' ? match.winning_points : match.loosing_points}
                </p>
              </div>
              <div className="hidden sm:block">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </li>
  )
}
