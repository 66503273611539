import * as t from 'io-ts'

export const RankedPlayer = t.type({
  name: t.string,
  points_standing: t.number,
  plus_minus: t.number,
  no_of_matches: t.number,
  created_at: t.string,
  seeded: t.boolean,
  guest: t.boolean,
})

export type RankedPlayer = t.TypeOf<typeof RankedPlayer>

export type ComponentProps = {
  listIndex: number
  player: RankedPlayer
}
