import { Locale } from '../../../../../../types'

type FieldContent = {
  label: string
  placeholder: string
}
type Button = {
  text: string
  destination?: string
}
export type Translation = {
  success: {
    headline: string
  }
  error: {
    headline: string
  }
  fields: {
    company: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    name: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    email: FieldContent & {
      validation: {
        short: string
        long: string
        format: string
        required: string
      }
    }
    phone: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    group: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    series_length: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
    quantity: FieldContent & {
      validation: {
        short: string
        long: string
        required: string
      }
    }
  }
  button: Button
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    fields: {
      company: {
        label: 'Vilket företag representerar du?',
        placeholder: 'Skriv företagsnamn eller organisationsnummer',
        validation: {
          short: 'Företaget är för kort',
          long: 'Företaget är för långt',
          required: 'Företaget saknas',
        },
      },
      name: {
        label: 'Vad är ditt namn?',
        placeholder: 'Ted Lasso',
        validation: {
          short: 'Namnet är för kort',
          long: 'Namnet är för långt',
          required: 'Namnet saknas',
        },
      },
      email: {
        label: 'Vad är din mejladress?',
        placeholder: 'ted@example.se',
        validation: {
          short: 'E-postadressen är för kort',
          long: 'E-postadressen är för lång',
          format: 'E-postadressen har ogiltigt format',
          required: 'E-postadressen saknas',
        },
      },
      phone: {
        label: 'Vad är ditt telefonnummer?',
        placeholder: 'Skriv ditt telefonummer',
        validation: {
          short: 'Telefonnumret är för kort',
          long: 'Telefonnumret är för långt',
          required: 'Telefonnumret saknas',
        },
      },
      group: {
        label: 'Vilken grupp är du intressad av?',
        placeholder: '',
        validation: {
          short: 'Grupp är för kort',
          long: 'Grupp är för långt',
          required: 'Grupp saknas',
        },
      },
      series_length: {
        label: 'När vill du starta?',
        placeholder: '',
        validation: {
          short: 'Längd är för kort',
          long: 'Längd är för långt',
          required: 'Längd saknas',
        },
      },
      quantity: {
        label: 'Hur många platser vill du boka?',
        placeholder: '',
        validation: {
          short: 'Antalet är för få',
          long: 'Antalet är för många',
          required: 'Antal saknas',
        },
      },
    },
    button: {
      text: 'Skicka',
    },
    error: {
      headline: 'Det var ett problem med din anmälan',
    },
    success: {
      headline: 'Tack för din anmälan. Vi hör av oss.',
    },
  },
  en: {
    fields: {
      company: {
        label: 'Vilket företag representerar du?',
        placeholder: 'Skriv företagsnamn eller organisationsnummer',
        validation: {
          short: 'Företaget är för kort',
          long: 'Företaget är för långt',
          required: 'Företaget saknas',
        },
      },
      name: {
        label: 'Vad är ditt namn?',
        placeholder: 'Ted Lasso',
        validation: {
          short: 'Namnet är för kort',
          long: 'Namnet är för långt',
          required: 'Namnet saknas',
        },
      },
      email: {
        label: 'Vad är din mejladress?',
        placeholder: 'ted@example.se',
        validation: {
          short: 'E-postadressen är för kort',
          long: 'E-postadressen är för lång',
          format: 'E-postadressen har ogiltigt format',
          required: 'E-postadressen saknas',
        },
      },
      phone: {
        label: 'Vad är ditt telefonnummer?',
        placeholder: 'Skriv ditt telefonummer',
        validation: {
          short: 'Telefonnumret är för kort',
          long: 'Telefonnumret är för långt',
          required: 'Telefonnumret saknas',
        },
      },
      group: {
        label: 'Vilken grupp är du intressad av?',
        placeholder: '',
        validation: {
          short: 'Grupp är för kort',
          long: 'Grupp är för långt',
          required: 'Grupp saknas',
        },
      },
      series_length: {
        label: 'När vill du starta?',
        placeholder: '',
        validation: {
          short: 'Längd är för kort',
          long: 'Längd är för långt',
          required: 'Längd saknas',
        },
      },
      quantity: {
        label: 'Hur många platser vill du boka?',
        placeholder: '',
        validation: {
          short: 'Antalet är för få',
          long: 'Antalet är för många',
          required: 'Antal saknas',
        },
      },
    },
    button: {
      text: 'Send',
    },
    error: {
      headline: 'There was an error with your submission.',
    },
    success: {
      headline: "Thank you for your submission. We'll be in touch",
    },
  },
}
