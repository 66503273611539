import { ClockIcon, CalendarIcon } from '@heroicons/react/outline'
import { filter, take, orderBy, first, last } from 'lodash'
import { DateTime } from 'luxon'
import { ReactNode } from 'react'
import { AdminRoundRowItem } from '../../02-molecules'
import { ComponentProps, AdminRoundRows } from './types'

export { AdminRoundRows }

const getTimespan = (showTimespan: boolean, rounds: AdminRoundRows): ReactNode | null => {
  if (!showTimespan) return null
  const [morning] = take(
    filter(rounds, (r) => r.group === 'morning'),
    1
  )
  const [lunch] = take(
    filter(rounds, (r) => r.group === 'lunch'),
    1
  )
  const dtStart = DateTime.fromISO(morning.round_start)
  const dtEnd = lunch ? DateTime.fromISO(lunch.round_end) : dtStart.endOf('day')

  return (
    <p className="mt-2 flex items-center text-sm text-gray-500">
      <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
      {dtStart.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)} till{' '}
      {dtEnd.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)}
    </p>
  )
}
const getDate = (showDate: boolean, rounds: AdminRoundRows): ReactNode | null => {
  if (!showDate) return null
  const [morning] = take(
    filter(rounds, (r) => r.group === 'morning'),
    1
  )
  const dtStart = DateTime.fromISO(morning.round_start)

  return (
    <p className="mt-2 flex items-center text-sm text-gray-500">
      <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
      {dtStart.setLocale('sv').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
    </p>
  )
}

const RoundGroup = ({
  rounds,
  title,
  showDate = true,
  showTimespan = true,
}: {
  rounds: AdminRoundRows
  title: string
  showDate?: boolean
  showTimespan?: boolean
}) => {
  return (
    <>
      <div className="pb-5 border-b border-gray-200 px-4 py-4 sm:px-6 lg:px-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <div className="mt-2 max-w-4xl text-sm text-gray-500">
          {getDate(showDate, rounds)}
          {getTimespan(showTimespan, rounds)}
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {rounds.map((round) => (
          <AdminRoundRowItem key={round.round_public_id} round={round} />
        ))}
      </ul>
    </>
  )
}

export const AdminRoundsList = ({ rounds }: ComponentProps) => {
  const pastRounds = orderBy(
    filter(rounds, (round) => round.status === 'past'),
    ['round_start'],
    ['asc']
  )
  const currentRounds = orderBy(
    filter(rounds, (round) => round.status === 'current'),
    ['round_start'],
    ['asc']
  )
  const nextWeeksRounds = orderBy(
    filter(rounds, (round) => round.status === 'next_week'),
    ['round_start'],
    ['asc']
  )
  const upcomingRounds = orderBy(
    filter(rounds, (round) => round.status === 'upcoming'),
    ['round_start'],
    ['asc']
  )
  const dynamicRounds = orderBy(
    filter(rounds, (round) => round.status === 'dynamic'),
    ['round_name'],
    ['asc']
  )

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      {dynamicRounds && dynamicRounds.length > 0 ? (
        <RoundGroup
          rounds={dynamicRounds}
          title={`Nuvarande vecka (${dynamicRounds[0]?.current_week})`}
          showTimespan={false}
          showDate={false}
        />
      ) : null}
      {currentRounds && currentRounds.length > 0 ? (
        <RoundGroup
          rounds={currentRounds}
          title={`Nuvarande vecka (${currentRounds[0]?.current_week})`}
        />
      ) : null}
      {nextWeeksRounds && nextWeeksRounds.length > 0 ? (
        <>
          <div className="relative py-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">Nästa vecka</span>
            </div>
          </div>
          <RoundGroup
            rounds={nextWeeksRounds}
            title={`Nästa vecka (${nextWeeksRounds[0]?.start_week})`}
          />
        </>
      ) : null}
      {upcomingRounds && upcomingRounds.length > 0 ? (
        <>
          <div className="relative py-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">Kommande veckor</span>
            </div>
          </div>
          <RoundGroup
            rounds={upcomingRounds}
            title={`Kommande veckor (${first(upcomingRounds)?.start_week}-${
              last(upcomingRounds)?.start_week
            })`}
            showDate={false}
            showTimespan={false}
          />
        </>
      ) : null}
      {pastRounds && pastRounds.length > 0 ? (
        <>
          <div className="relative py-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">Föregående veckor</span>
            </div>
          </div>
          <RoundGroup
            rounds={pastRounds}
            title={`Föregående veckor (${first(pastRounds)?.start_week}-${
              last(pastRounds)?.start_week
            })`}
            showDate={false}
            showTimespan={false}
          />
        </>
      ) : null}
    </div>
  )
}
