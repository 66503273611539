import * as t from 'io-ts'
import { AdminMatchResult } from '../../02-molecules/admin-match-result-row-item/types'

export const AdminMatchResultRows = t.array(AdminMatchResult)

export type AdminMatchResultRows = t.TypeOf<typeof AdminMatchResultRows>

export type ComponentProps = {
  matches: AdminMatchResultRows
  handleMatchClick: (match: AdminMatchResult) => void
}