import Link from 'next/link'
import { DateTime } from 'luxon'
import { ComponentProps, Round } from './types'
import { ChevronRightIcon } from '@heroicons/react/solid'

export { Round }

export const RoundRowItem = ({ round, seriesContext }: ComponentProps) => {
  const dtStart = DateTime.fromISO(round.start)

  return (
    <li key={round.public_id} className="block bg-white hover:bg-gray-50">
      <Link href={`/series/${seriesContext.id}/round/${round.public_id}`}>
        <a>
          <div className="px-4 py-4 flex items-center sm:px-6 font-light bg-white hover:bg-gray-50">
            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="pb-5 pt-3 border-b border-gray-200 sm:border-0 sm:px-6">
                <h3 className="text-base leading-6 font-medium text-gray-900">{round.name}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {dtStart.setLocale('sv').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                </p>
              </div>
              <span className="inline-flex items-center mt-2 sm:mt-0 px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
                {round.confirmed_matches} matcher
              </span>
              <span className="inline-flex items-center mt-2 sm:mt-0 ml-2 sm:ml-0 px-2.5 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800">
                {round.accepted} spelare
              </span>
              <div className="hidden sm:block">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </li>
  )
}
