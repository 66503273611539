import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UUID } from 'io-ts-types'
import { PencilIcon } from '@heroicons/react/outline'
import { MatchResultForm } from './match-result-form'
import { useRouter } from 'next/router'
import {
  ConfirmMatchRequestBody,
  ConfirmMatchResponseData,
} from '../../../../pages/api/admin/match/confirm'
import {
  DeleteMatchRequestBody,
  DeleteMatchResponseData,
} from '../../../../pages/api/admin/match/delete-soft'
import { AdminMatchResult, SuccessAlert, ErrorAlert } from '../..'

type Props = {
  seriesId: UUID
  roundId: number
  matchId: number | undefined
  match: AdminMatchResult | undefined
  open: boolean
  setOpen: (open: boolean) => void
}

export const ManageMatchModal = ({ open, setOpen, roundId, seriesId, matchId, match }: Props) => {
  const router = useRouter()
  const cancelButtonRef = useRef(null)
  const [courtName, setCourtName] = useState<string>('')
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
    setCourtName('')
    setIsConfirming(false)
    setIsDeleting(false)
  }

  const confirmMatch = async () => {
    setIsConfirming(true)
    try {
      if (!matchId) {
        console.error(`Match ID is not set`)
        setError(new Error('Match ID is not set'))
        setStatus('not_ok')
        setIsConfirming(false)
        return
      }
      if (match && match.match_state !== 'created') {
        console.error(`Match in invalid state [${match.match_state}]`)
        setError(new Error(`Invalid match state [${match.match_state}]`))
        setStatus('not_ok')
        setIsConfirming(false)
        return
      }

      const requestBody: ConfirmMatchRequestBody = {
        matchId,
        sendNotifications: true,
        courtName: courtName || null,
      }
      const actionRes = await fetch('/api/admin/match/confirm', {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (actionRes.status !== 200) {
        console.error('actionRes', actionRes)
        setError(new Error('Non 200 response from backend'))
        setStatus('not_ok')
        setIsConfirming(false)
        return
      }

      const responseData: ConfirmMatchResponseData = await actionRes.json()
      if (!responseData.success) {
        console.error('responseData', responseData)
        setError(new Error(responseData.code))
        setStatus('not_ok')
        setIsConfirming(false)
        return
      }
      setError(undefined)
      setStatus('pristine')
      setIsConfirming(false)
      router.replace(router.asPath)
      setOpen(false)
    } catch (error) {
      console.error('Confirm match error', error)
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error was caught'))
      }
      setIsConfirming(false)
      setStatus('not_ok')
      return
    }
  }

  const deleteMatch = async () => {
    setIsDeleting(true)
    try {
      if (!matchId) {
        console.error(`Match ID is not set`)
        setError(new Error('Match ID is not set'))
        setStatus('not_ok')
        setIsConfirming(false)
        return
      }
      const requestBody: DeleteMatchRequestBody = {
        matchId,
      }

      /* const apiRouteUrl = match?.match_state === 'created' ? '/api/admin/match/delete-hard' : '/api/admin/match/delete-soft' */
      const apiRouteUrl = '/api/admin/match/delete-soft'

      const actionRes = await fetch(apiRouteUrl, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (actionRes.status !== 200) {
        console.error('actionRes', actionRes)
        setError(new Error('Non 200 response from backend'))
        setStatus('not_ok')
        setIsDeleting(false)
        return
      }

      const responseData: DeleteMatchResponseData = await actionRes.json()
      setError(undefined)
      setStatus('ok')
      setIsDeleting(false)
      router.replace(router.asPath)
    } catch (error) {
      console.error('Delete match error', error)
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error was caught'))
      }
      setIsDeleting(false)
      setStatus('not_ok')
      return
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block h-screen transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <PencilIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Hantera match
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Rapportera matchresultat m.m.</p>
                  </div>
                  {status === 'ok' ? (
                    <div className="mt-12">
                      <SuccessAlert
                        translations={{ headline: 'Den action du tog gick bra!' }}
                        reset={reset}
                      />
                    </div>
                  ) : null}
                  {status === 'not_ok' && error ? (
                    <div className="mt-12">
                      <ErrorAlert
                        translations={{ headline: 'Typiskt! Något gick snett.' }}
                        error={error}
                        reset={reset}
                      />
                    </div>
                  ) : null}
                  {matchId ? (
                    <>
                      <div className="border-b border-gray-200 bg-white py-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Rapportera matchresultat
                        </h3>
                      </div>
                      <MatchResultForm
                        matchId={matchId}
                        open={open}
                        setOpen={setOpen}
                        initialValues={{
                          home_set_one: (match && match.home_set_one_games) || 0,
                          home_set_two: (match && match.home_set_two_games) || 0,
                          home_set_three: (match && match.home_set_three_games) || 0,
                          home_tiebreak: (match && match.home_tiebreak_points) || 0,
                          away_set_one: (match && match.away_set_one_games) || 0,
                          away_set_two: (match && match.away_set_two_games) || 0,
                          away_set_three: (match && match.away_set_three_games) || 0,
                          away_tiebreak: (match && match.away_tiebreak_points) || 0,
                        }}
                      />
                    </>
                  ) : null}
                  {matchId ? (
                    <>
                      <div className="border-b border-gray-200 bg-white py-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Bekräfta match
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          När du bekräftar matchen låser kommer vi skicka iväg en notifikation till
                          spelarna så de vet när, var och med vem de ska spela.
                        </p>
                      </div>
                      <div className="py-5">
                        <div>
                          <label
                            htmlFor="court_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Bana
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="court_name"
                              id="court_name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Skriv namnet på banan"
                              aria-describedby="court_name_description"
                              value={courtName}
                              onChange={(e) => setCourtName(e.currentTarget.value)}
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500" id="court_name_description">
                            Skriv namnet på banan som denna match ska spelas på.
                          </p>
                        </div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                          disabled={isConfirming}
                          onClick={() => {
                            if (
                              confirm(
                                'Är du säker på att du vill bekräfta matchen och skicka notifikationer till spelarna?'
                              )
                            ) {
                              confirmMatch()
                            }
                          }}
                        >
                          Bekräfta match
                          {isConfirming ? (
                            <svg
                              className="-mr-1 ml-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : null}
                        </button>
                      </div>
                    </>
                  ) : null}
                  {matchId ? (
                    <>
                      <div className="border-b border-gray-200 bg-white py-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Ta bort match
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit quam corrupti
                          consectetur.
                        </p>
                      </div>
                      <div className="py-5">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                          disabled={isDeleting}
                          onClick={() => {
                            if (confirm('Är du säker på att du vill ta bort matchen?')) {
                              deleteMatch()
                            }
                          }}
                        >
                          Ta bort match
                          {isDeleting ? (
                            <svg
                              className="-mr-1 ml-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : null}
                        </button>
                      </div>
                    </>
                  ) : null}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      reset()
                      setOpen(false)
                    }}
                    ref={cancelButtonRef}
                  >
                    Stäng det här fönstret
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
