/* eslint-disable @next/next/no-img-element */
import {
  ChevronDoubleUpIcon,
  ClockIcon,
  CreditCardIcon,
  HashtagIcon,
  SparklesIcon,
  HeartIcon,
  LightningBoltIcon,
  MusicNoteIcon,
} from '@heroicons/react/outline'
import Image from 'next/image'
import { ReactNode } from 'react'

import { Locale } from '../../../../types'
import imgBalls from './assets/hermes-lots-of-balls-on-court.jpg'
import imgHoldingPadel from './assets/man-holding-padel-rack-oliver-1.jpg'
import imgBlueAndOrange from './assets/martin-hitting-the-ball.jpg'
import imgLyingDown from './assets/fredrik-striking-ball.jpg'

type Feature = {
  name: string
  description: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
}
type Metric = {
  id: number
  stat: string
  emphasis: string
  rest: string
}

type Image = {
  source: StaticImageData
  attribution: ReactNode
  alt: string
}

type CTAButton = {
  text: string
  href: string
  type: 'anchor' | 'link' | 'external'
}

type AlternatingFeature = {
  headline: string
  description: string
  cta: CTAButton
}

type Translation = {
  pageTitle: string
  pageDescription: string
  headlinePlain: string
  headlineMarked: string
  subline: string
  primaryCta: CTAButton
  secondaryCta: CTAButton
  logoCloudHeadline: ReactNode
  logoCloud: ReactNode
  firstFeature: AlternatingFeature
  secondFeature: AlternatingFeature
  featuresHeadline: string
  featuresDescription: string
  features: Feature[]
  images: {
    balls: Image
    holdingRack: Image
    blueAndOrange: Image
    lyingDown: Image
  }
  metricsHeadline: string
  metricsSubline: string
  metricsDescription: ReactNode
  bottomCta: {
    prompt: string
    headline: string
    primaryButton: CTAButton
    secondaryButton: CTAButton
  }
  metricsLabel: string
  metrics: Metric[]
  jamesClearNote: string
  companySignupCta: {
    headlinePartOne: string
    headlinePartTwo: string
    description: string
    button: CTAButton
  }
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Nätverkspadel',
    pageDescription:
      'Vi samlas på onsdagar för att spela padel och nätverka. Två flugor i en smäll.',
    headlinePlain: 'Spela mer padel',
    headlineMarked: 'och tävla mot andra företag',
    subline:
      'Nätverkspadel är en möjlighet att varje onsdag spela padel med, och mot, stadens företagare, entreprenörer, och entusiaster. Snacket får du stå för själv, resten tar vi hand om.',
    primaryCta: {
      text: 'Anmäl er nu',
      href: '/signup',
      type: 'link',
    },
    secondaryCta: {
      text: 'Läs mer',
      href: '#first-testimonial-block',
      type: 'link',
    },
    logoCloudHeadline: (
      <>
        <span className="hidden md:flex md:items-center">
          Presenteras av{' '}
          <a
            href="https://www.ovik.dev/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center pr-1 font-bold"
          >
            <img className="inline h-4 px-1" src="/partners/impact-quest.svg" alt="ImpactQuest" />
            Impact Quest
          </a>{' '}
          tillsammans med våra grymma partners
        </span>
        <span className="flex items-center md:hidden">
          Presenteras av Impact Quest tillsammans med våra grymma partners
        </span>
      </>
    ),
    logoCloud: (
      <>
        <div className="col-span-1 flex justify-center">
          <a
            href="https://stjarnafyrkant.se/ornskoldsvik/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center text-center"
          >
            <img className="h-12" src="/partners/stjarnafyrkant.svg" alt="StjärnaFyrkant" />
            <p className="mt-2 text-sm font-semibold text-gray-700">Teknik</p>
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a
            href="https://totalpadelcenter.se/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center text-center"
          >
            <img className="h-12" src="/partners/wap-logo-black-text.svg" alt="We Are Padel" />
            <p className="mt-2 text-sm font-semibold text-gray-700">Sport</p>
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a
            href="https://www.halsobarennorrland.se/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center text-center"
          >
            <img className="h-12" src="/partners/halsobaren-logo.svg" alt="Hälsobaren" />
            <p className="mt-2 text-sm font-semibold text-gray-700">Mat & dryck</p>
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a
            href="https://varlion.com/en/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col justify-center text-center"
          >
            <img className="h-12" src="/partners/varlion.svg" alt="Varlion" />
            <p className="mt-2 text-sm font-semibold text-gray-700">Bollar</p>
          </a>
        </div>
      </>
    ),
    firstFeature: {
      headline: 'Träffa nya människor',
      description:
        'Vi tror starkt på att om det är i kalendern blir det av. I det här fallet är det två flugor i en smäll. För det första får du träningen gjord samt att du på samma gång har utökat och förstärkt ditt lokala nätverk. Bra va?',
      cta: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    jamesClearNote: 'James Clear, Författare av Atomic Habits',
    secondFeature: {
      headline: 'Hälsan får en boost',
      description:
        'Med Nätverkspadeln kan du räkna med träning, tävling, och nyttigt käk under 10 veckor. Vi samarbetar med Hälsobaren för att erbjuda en bra och näringsrik mat som tar dig genom hela dagen.',
      cta: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    featuresHeadline: 'Vad du får med på köpet',
    featuresDescription:
      'Vi designar en smidig och effektiv upplevelse för alla deltagare. Här nedan ser du ett smakprov av vad du kan förvänta dig.',
    metricsHeadline: 'Hur man vinner säsongen',
    metricsSubline: 'Speldynamik',
    metricsDescription: (
      <>
        <p>
          Varje omgång spelar vi en mexicanoturnering där hela säsongens ranking tas i beaktning för
          att skapa en ranking av deltagarna. I varje match spelas 7 gem och det kan bli 7-0, 3-4,
          5-2 etc. Varje gem räknas sedan in den individuella spelarens säsong.
        </p>
        <p>
          Varje match tar ungefär 15-20 minuter och frukostgruppen hinner 4 matcher varje omgång och
          lunchgruppen 3 matcher.
        </p>
        <p>
          Tabellen sorteras sedan på ett &quot;<em>Poäng per match snitt</em>&quot; (PPM) vilket är{' '}
          <em>totalt antal vunna gem / antal matcher</em>.
        </p>
        <p>
          I ÖNP är “deltagare” den organisation eller företag som anmält sig.
          Företaget/organisationen kan representeras av en eller flera personer, men endast 1
          spelare vid varje enskilt speltillfälle.
        </p>
      </>
    ),
    bottomCta: {
      prompt: 'Redo att komma igång?',
      headline: 'Läs på eller så anmäler du dig bara.',
      primaryButton: {
        text: 'Läs mer',
        href: '#first-testimonial-block',
        type: 'link',
      },
      secondaryButton: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    metricsLabel: 'Metrics',
    features: [
      {
        name: 'Gott om speltid',
        description:
          'Du kan räkna med att konsekvent få träna på din padel. Mängden är beroende på vilken grupp du deltar i.',
        icon: ClockIcon,
      },
      {
        name: 'En boost på morgonen',
        description:
          'Om du spelar i morgongruppen kommer det finnas kaffe redo när du kommer, för den välbehövliga koffein-kicken.',
        icon: ChevronDoubleUpIcon,
      },
      {
        name: 'Återhämtning',
        description:
          'Efter spel ingår en frukost eller lunch (beroende på vilken grupp du spelar i) uppe på hyllan så du kan göra dig redo vad som händer härnäst.',
        icon: LightningBoltIcon,
      },
      {
        name: 'Behöver du ett nytt racket?',
        description:
          'Kanske du vill levla upp, eller behöver det bytas ut efter några dåliga tillslag? Oavsett får du 20% på racket hos WAP Örnsköldsvik under hela säsongen.',
        icon: CreditCardIcon,
      },
      {
        name: 'Har skärmen gått sönder?',
        description:
          'Som deltagare får du 15% på service & reparation och 10% på tillbehör hos StjärnaFyrkant Örnsköldsvik. Varför inte få det billigare om du kan?',
        icon: HashtagIcon,
      },
      {
        name: 'Sugen på en sjyst lunch?',
        description:
          'Som deltagare får du gratis dryck när du köper lunch hos vår partner Hälsobaren. Visa upp ditt ÖNP medlemskort så hjälper dom dig.',
        icon: SparklesIcon,
      },
      {
        name: 'Säsongsavslutning',
        description:
          'Efter säsongen har vi en gemensam avslutning med Mexicano-turnering samt AW och annat skoj. Bör inte missas.',
        icon: MusicNoteIcon,
      },
      {
        name: 'Nya kunder eller partners',
        description:
          'Kanske säger sig själv men det är sannerligen en "feature" av detta. Finns alla möjligheter att göra affärer. Upp till dig.',
        icon: HeartIcon,
      },
    ],
    metrics: [
      {
        id: 1,
        stat: '7-12+',
        emphasis: 'Timmar',
        rest: 'och gott om tid att ta steg i ditt spel.',
      },
      {
        id: 2,
        stat: '20',
        emphasis: 'Deltagare',
        rest: 'som gör upp om att vinna titeln vid säsongens slut.',
      },
    ],
    images: {
      balls: {
        source: imgBalls,
        alt: 'Många bollar ligger på banan',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@hermez777?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Hermes Rivera
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      blueAndOrange: {
        source: imgBlueAndOrange,
        alt: 'Ett blått och orange padel rack',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@manupappa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Manuel Pappacena
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      holdingRack: {
        source: imgHoldingPadel,
        alt: 'Spelare håller ett rack',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@ollivves?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Oliver Sjöström
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      lyingDown: {
        source: imgLyingDown,
        alt: 'Spelare ligger på banan',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@ollivves?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Oliver Sjöström
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
    },
    companySignupCta: {
      headlinePartOne: 'Låter det mycket med varje vecka?',
      headlinePartTwo: 'Spela som ett lag istället.',
      description:
        'Om {{packageLength}} gånger känns som ett stort åtagande kan ni på företaget dela på passen och spela som ett lag. Bestäm vecka till vecka vem som ska spela och samla poäng för att tävla mot andra företag.',
      button: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
  },
  en: {
    pageTitle: 'Nätverkspadel',
    pageDescription:
      'Vi samlas på onsdagar för att spela padel och nätverka. Två flugor i en smäll.',
    headlinePlain: 'Spela mer padel',
    headlineMarked: 'och tävla mot andra företag',
    subline:
      'Nätverkspadel är en möjlighet att varje onsdag spela padel med, och mot, stadens företagare, entreprenörer, och entusiaster. Snacket får du stå för själv, resten tar vi hand om.',
    primaryCta: {
      text: 'Anmäl er nu',
      href: '/signup',
      type: 'link',
    },
    secondaryCta: {
      text: 'Läs mer',
      href: '#first-testimonial-block',
      type: 'link',
    },
    logoCloudHeadline: 'Presenteras av Impact Quest tillsammans med våra grymma partners',
    logoCloud: (
      <>
        <div className="col-span-1 flex justify-center">
          <a href="https://www.impactquest.se" target="_blank" rel="noopener noreferrer">
            <img className="h-12" src="/partners/impact-quest.svg" alt="ImpactQuest" />
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a
            href="https://stjarnafyrkant.se/ornskoldsvik/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="h-12" src="/partners/stjarnafyrkant.svg" alt="StjärnaFyrkant" />
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a href="https://totalpadelcenter.se/" target="_blank" rel="noopener noreferrer">
            <img className="h-12" src="/partners/wap-logo-black-text.svg" alt="Total Padelcenter" />
          </a>
        </div>
        <div className="col-span-1 flex justify-center">
          <a href="https://www.halsobarennorrland.se/" target="_blank" rel="noopener noreferrer">
            <img className="h-12" src="/partners/halsobaren-logo.svg" alt="Hälsobaren" />
          </a>
        </div>
      </>
    ),
    firstFeature: {
      headline: 'Träffa nya människor',
      description:
        'Vi tror starkt på att om det är i kalendern blir det av. I det här fallet är det två flugor i en smäll. För det första får du träningen gjord samt att du på samma gång har utökat och förstärkt ditt lokala nätverk. Bra va?',
      cta: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    jamesClearNote: 'James Clear, Författare av Atomic Habits',
    secondFeature: {
      headline: 'Hälsan får en boost',
      description:
        'Med Nätverkspadeln kan du räkna med träning, tävling, och nyttigt käk under 10 veckor. Vi samarbetar med Hälsobaren för att erbjuda en bra och näringsrik mat som tar dig genom hela dagen.',
      cta: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    featuresHeadline: 'Vad du får med på köpet',
    featuresDescription:
      'Vi designar en smidig och effektiv upplevelse för alla deltagare. Här nedan ser du ett smakprov av vad du kan förvänta dig.',
    metricsHeadline: 'Hur man vinner säsongen',
    metricsSubline: 'Speldynamik',
    metricsDescription: (
      <>
        <p>
          Varje omgång spelar vi en mexicanoturnering där hela säsongens ranking tas i beaktning för
          att skapa en ranking av deltagarna. I varje match spelas 7 gem och det kan bli 7-0, 3-4,
          5-2 etc. Varje gem räknas sedan in den individuella spelarens säsong.
        </p>
        <p>
          Varje match tar ungefär 15-20 minuter och frukostgruppen hinner 4 matcher varje omgång och
          lunchgruppen 3 matcher.
        </p>
        <p>
          Tabellen sorteras sedan på ett &quot;<em>Poäng per match snitt</em>&quot; (PPM) vilket är{' '}
          <em>totalt antal vunna gem / antal matcher</em>.
        </p>
        <p>
          I ÖNP är “deltagare” den organisation eller företag som anmält sig.
          Företaget/organisationen kan representeras av en eller flera personer, men endast 1
          spelare vid varje enskilt speltillfälle.
        </p>
      </>
    ),
    bottomCta: {
      prompt: 'Redo att komma igång?',
      headline: 'Läs på eller så anmäler du dig bara.',
      primaryButton: {
        text: 'Läs mer',
        href: '#first-testimonial-block',
        type: 'link',
      },
      secondaryButton: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
    metricsLabel: 'Metrics',
    features: [
      {
        name: 'Gott om speltid',
        description:
          'Du kan räkna med att konsekvent få träna på din padel. Mängden är beroende på vilken grupp du deltar i.',
        icon: ClockIcon,
      },
      {
        name: 'En boost på morgonen',
        description:
          'Om du spelar i morgongruppen kommer det finnas kaffe redo när du kommer, för den välbehövliga koffein-kicken.',
        icon: ChevronDoubleUpIcon,
      },
      {
        name: 'Återhämtning',
        description:
          'Efter spel ingår en frukost eller lunch (beroende på vilken grupp du spelar i) uppe på hyllan så du kan göra dig redo vad som händer härnäst.',
        icon: LightningBoltIcon,
      },
      {
        name: 'Behöver du ett nytt racket?',
        description:
          'Kanske du vill levla upp, eller behöver det bytas ut efter några dåliga tillslag? Oavsett får du 20% på racket hos WAP Örnsköldsvik under hela säsongen.',
        icon: CreditCardIcon,
      },
      {
        name: 'Har skärmen gått sönder?',
        description:
          'Som deltagare får du 15% på service & reparation och 10% på tillbehör hos StjärnaFyrkant Örnsköldsvik. Varför inte få det billigare om du kan?',
        icon: HashtagIcon,
      },
      {
        name: 'Sugen på en sjyst lunch?',
        description:
          'Som deltagare får du gratis dryck när du köper lunch hos vår partner Hälsobaren. Visa upp ditt ÖNP medlemskort så hjälper dom dig.',
        icon: SparklesIcon,
      },
      {
        name: 'Säsongsavslutning',
        description:
          'Efter säsongen har vi en gemensam avslutning med Mexicano-turnering samt AW och annat skoj. Bör inte missas.',
        icon: MusicNoteIcon,
      },
      {
        name: 'Nya kunder eller partners',
        description:
          'Kanske säger sig själv men det är sannerligen en "feature" av detta. Finns alla möjligheter att göra affärer. Upp till dig.',
        icon: HeartIcon,
      },
    ],
    metrics: [
      {
        id: 1,
        stat: '7-12+',
        emphasis: 'Timmar',
        rest: 'och gott om tid att ta steg i ditt spel.',
      },
      {
        id: 2,
        stat: '20',
        emphasis: 'Deltagare',
        rest: 'som gör upp om att vinna titeln vid säsongens slut.',
      },
    ],
    images: {
      balls: {
        source: imgBalls,
        alt: 'Många bollar ligger på banan',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@hermez777?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Hermes Rivera
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      blueAndOrange: {
        source: imgBlueAndOrange,
        alt: 'Ett blått och orange padel rack',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@manupappa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Manuel Pappacena
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      holdingRack: {
        source: imgHoldingPadel,
        alt: 'Spelare håller ett rack',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@ollivves?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Oliver Sjöström
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
      lyingDown: {
        source: imgLyingDown,
        alt: 'Spelare ligger på banan',
        attribution: (
          <>
            Foto av{' '}
            <a href="https://unsplash.com/@ollivves?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Oliver Sjöström
            </a>{' '}
            på{' '}
            <a href="https://unsplash.com/s/photos/padel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </>
        ),
      },
    },
    companySignupCta: {
      headlinePartOne: 'Låter det mycket med varje vecka?',
      headlinePartTwo: 'Spela som ett lag istället.',
      description:
        'Om {{packageLength}} gånger känns som ett stort åtagande kan ni på företaget dela på passen och spela som ett lag. Bestäm vecka till vecka vem som ska spela och samla poäng för att tävla mot andra företag.',
      button: {
        text: 'Anmäl er nu',
        href: '/signup',
        type: 'link',
      },
    },
  },
}
