/* eslint-disable @next/next/no-img-element */
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'
import { Locale } from '../../../../../types'
import { makeLocale } from '../../../../../utils/locale'
import { translations } from './types'
import { ErrorAlert, SuccessAlert } from '../../../02-molecules'
import { CreateTeamMemberResponseData, CreateTeamMemberRequestBody } from '../../../../../pages/api/create-team-member'

type Props = {
  teamName: string
  teamId: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  first_name: string
  last_name: string
  email_adress: string
  phone_number: string
  application_bot_field: string
}

export const CreateTeamMemberForm = ({ teamName, teamId, setOpen }: Props) => {
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    email_adress: '',
    phone_number: '',
    application_bot_field: '',
  }

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, translation.fields.first_name.validation.short)
      .max(40, translation.fields.first_name.validation.long)
      .trim()
      .required(translation.fields.first_name.validation.required),
    last_name: Yup.string()
      .min(2, translation.fields.last_name.validation.short)
      .max(40, translation.fields.last_name.validation.long)
      .trim()
      .required(translation.fields.last_name.validation.required),
    email_adress: Yup.string()
      .min(2, translation.fields.email_adress.validation.short)
      .max(40, translation.fields.email_adress.validation.long)
      .email(translation.fields.email_adress.validation.format)
      .trim()
      .lowercase()
      .required(translation.fields.email_adress.validation.required),
    phone_number: Yup.string()
      .min(5, translation.fields.phone_number.validation.short)
      .max(12, translation.fields.phone_number.validation.long)
      .trim()
      .required(translation.fields.phone_number.validation.required),
  })

  return (
    <>
      {status === 'ok' ? (
        <SuccessAlert translations={{ headline: translation.success.headline }} reset={reset} />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert
          translations={{ headline: translation.error.headline }}
          error={error}
          reset={reset}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            const requestBody: CreateTeamMemberRequestBody = {
              first_name: values.first_name,
              last_name: values.last_name,
              email_adress: values.email_adress,
              phone_number: values.phone_number,
              application_bot_field: values.application_bot_field,
              team_id: teamId,
              team_name: teamName,
            }
            const createTeamMemberRes = await fetch('/api/create-team-member', {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            if (createTeamMemberRes.status !== 200) {
              setStatus('not_ok')
              setError(new Error('Non 200 response from backend'))
              actions.resetForm()
              actions.setSubmitting(false)
              return
            }

            const createTeamMemberResData: CreateTeamMemberResponseData = await createTeamMemberRes.json()
            if (!createTeamMemberResData.success) {
              setStatus('not_ok')
              setError(new Error(createTeamMemberResData.code))
              actions.resetForm()
              actions.setSubmitting(false)
            } else {
              actions.resetForm()
              actions.setSubmitting(false)
              setOpen(false)
              router.replace(router.asPath)
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Create team member failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}>
        {(formikBag) => {
          return (
            <Form>
              <Field name='application_bot_field'>
                {({ field }: Record<string, never>) => (
                  <p className='hidden'>
                    <label htmlFor='application_bot_field'>
                      Don’t fill this out if you&apos;re human: <input {...field} />
                    </label>
                  </p>
                )}
              </Field>
              <div className='px-4 py-5 sm:p-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <Field name='first_name'>
                    {({ field, meta }: Record<string, any>) => (
                      <div className='col-span-6 sm:col-span-3'>
                        <label htmlFor='first_name' className='block text-left text-sm font-medium text-gray-700'>
                          {translation.fields.first_name.label}
                        </label>
                        <input
                          {...field}
                          required
                          type='text'
                          placeholder={translation.fields.first_name.placeholder}
                          autoComplete='given-name'
                          className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                        {meta.touched && meta.error && (
                          <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name='last_name'>
                    {({ field, meta }: Record<string, any>) => (
                      <div className='col-span-6 sm:col-span-3'>
                        <label htmlFor='last_name' className='block text-left text-sm font-medium text-gray-700'>
                          {translation.fields.last_name.label}
                        </label>
                        <input
                          {...field}
                          required
                          type='text'
                          placeholder={translation.fields.last_name.placeholder}
                          autoComplete='family-name'
                          className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                        {meta.touched && meta.error && (
                          <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name='email_adress'>
                    {({ field, meta }: Record<string, any>) => (
                      <div className='col-span-6'>
                        <label htmlFor='email_adress' className='block text-left text-sm font-medium text-gray-700'>
                          {translation.fields.email_adress.label}
                        </label>
                        <input
                          {...field}
                          required
                          type='email'
                          placeholder={translation.fields.email_adress.placeholder}
                          autoComplete='email'
                          className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                        {meta.touched && meta.error && (
                          <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name='phone_number'>
                    {({ field, meta }: Record<string, any>) => (
                      <div className='col-span-6'>
                        <label htmlFor='phone_number' className='block text-left text-sm font-medium text-gray-700'>
                          {translation.fields.phone_number.label}
                        </label>
                        <input
                          {...field}
                          required
                          type='tel'
                          placeholder={translation.fields.phone_number.placeholder}
                          autoComplete='tel'
                          className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                        />
                        {meta.touched && meta.error && (
                          <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className='px-4 py-3 text-right sm:px-6'>
                <button onClick={() => {
                  if (confirm('Är du säker på att du vill avbryta att skapa en ny medlem i teamet?')) {
                    formikBag.resetForm()
                    setOpen(false)
                  }
                }} className='mr-2 bg-indigo-50 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-indigo-900 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500'>
                  Avbryt
                </button>
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  disabled={formikBag.isSubmitting || !formikBag.isValid}>
                  {translation.button.text}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
