import Link from 'next/link'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import { MailIcon } from '@heroicons/react/solid'
import { ComponentProps, RoundDetails } from './types'
import { useState } from 'react'
import { SendReminderResponseData } from '../../../../pages/api/admin/series/[series_id]/round/[round_id]/send-reminders'
import { CreateAndSendInvitationsRequestBody } from '../../../../pages/api/admin/series/[series_id]/round/[round_id]/create-and-send-invitations'
import { GenerateMatchesResponseData } from '../../../../pages/api/admin/series/[series_id]/round/[round_id]/generate-matches'
import { GenerateMatchesButton } from './generate-matches-button'

export { RoundDetails }

export const RoundDetailsCard = ({ round, series }: ComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [createMatchLoading, setCreateMatchLoading] = useState<boolean>(false)
  const router = useRouter()
  const statusBadge = () => {
    switch (round.status) {
      case 'current':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Sker denna vecka ({round.start_week})
          </span>
        )
      case 'next_week':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Sker nästa vecka ({round.start_week})
          </span>
        )
      case 'upcoming':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
            Sker under kommande veckor
          </span>
        )
      case 'past':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
            Har genomförts
          </span>
        )
      case 'dynamic':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Dynamiskt
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
            Oväntad status
          </span>
        )
    }
  }
  const groupBadge = () => {
    switch (round.group) {
      case 'morning':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
            Morgon
          </span>
        )
      case 'lunch':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Lunch
          </span>
        )
      case 'dynamic':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Dynamiskt
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
            Oväntad status
          </span>
        )
    }
  }

  const dtStart = DateTime.fromISO(round.round_start)
  const dtEnd = DateTime.fromISO(round.round_end)

  const sendInvitations = async () => {
    if (confirm('Är du säker på att du vill skicka ut inbjudningar?')) {
      setLoading(true)

      const requestBody: CreateAndSendInvitationsRequestBody = {
        sendEmail: true,
      }

      const SendInvitationRes = await fetch(
        `/api/admin/series/${series.id}/round/${round.round_public_id}/create-and-send-invitations`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (SendInvitationRes.status !== 200) {
        setLoading(false)
        console.error(`Got a non 200 response from the backend.`, { SendInvitationRes })
        return
      }

      const SendInvitationResData: SendReminderResponseData = await SendInvitationRes.json()
      if (!SendInvitationResData.success) {
        setLoading(false)
        console.error(
          `Got a non success response from the backend ${SendInvitationResData.code}.`,
          {
            SendInvitationResData,
          }
        )
        return
      } else {
        alert(`Skickade ${SendInvitationResData.emails} inbjudningar(r).`)
        setLoading(false)
        router.replace(router.asPath)
      }
    }
  }

  const sendReminders = async () => {
    if (confirm('Är du säker på att du vill skicka påminnelser?')) {
      setLoading(true)

      const SendReminderRes = await fetch(
        `/api/admin/series/${series.id}/round/${round.round_public_id}/send-reminders`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (SendReminderRes.status !== 200) {
        setLoading(false)
        console.error(`Got a non 200 response from the backend.`, { SendReminderRes })
        return
      }

      const SendReminderResData: SendReminderResponseData = await SendReminderRes.json()
      if (!SendReminderResData.success) {
        setLoading(false)
        console.error(`Got a non success response from the backend ${SendReminderResData.code}.`, {
          SendReminderResData,
        })
        return
      } else {
        alert(`Skickade ${SendReminderResData.emails} påminnelse(r).`)
        setLoading(false)
        router.replace(router.asPath)
      }
    }
  }

  const generateMatches = async () => {
    if (confirm('Är du säker på att du vill generera matcher?')) {
      setLoading(true)

      const GenerateMatchRes = await fetch(
        `/api/admin/series/${series.id}/round/${round.round_public_id}/send-reminders`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (GenerateMatchRes.status !== 200) {
        setLoading(false)
        console.error(`Got a non 200 response from the backend.`, { GenerateMatchRes })
        return
      }

      const GenerateMatchesResData: GenerateMatchesResponseData = await GenerateMatchRes.json()
      if (!GenerateMatchesResData.success) {
        setLoading(false)
        console.error(
          `Got a non success response from the backend ${GenerateMatchesResData.code}.`,
          {
            GenerateMatchesResData,
          }
        )
        return
      } else {
        alert(`Skapade ${GenerateMatchesResData.matches} matcher.`)
        setLoading(false)
        router.replace(router.asPath)
      }
    }
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg sm:m-6">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {round.round_name} av {round.series_name}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {dtStart.setLocale('sv').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{' '}
          {dtStart.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)} till{' '}
          {dtEnd.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Grupp</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{groupBadge()}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{statusBadge()}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Inbjudningar skickade</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {round.invitations}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Accepterat</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{round.accepted}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Tackat nej</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{round.declined}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ej svarat</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{round.pending}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt className="text-sm font-medium text-gray-500">Skicka inbjudningar</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={sendInvitations}
                disabled={round.invitations > 0}
              >
                <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Skicka inbjudningar
                {loading ? (
                  <svg
                    className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
              </button>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt className="text-sm font-medium text-gray-500">Skicka påminnelser</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                onClick={sendReminders}
                disabled={round.invitations < 1}
              >
                <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Skicka påminnelser
                {loading ? (
                  <svg
                    className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
              </button>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt className="text-sm font-medium text-gray-500">Generera matcher</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <GenerateMatchesButton round={round} series={series} />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt className="text-sm font-medium text-gray-500">Bjud in extra lag</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Link href={`/admin/series/${round.series_id}/round/${round.round_public_id}/invite`}>
                <a className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Bjud in
                </a>
              </Link>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
