/* import { XIcon } from '@heroicons/react/outline' */

export const ResultBanner = () => {
  return (
    <div className="relative bg-indigo-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="md:hidden">Sverige, vi har ett resultat!</span>
            <span className="hidden md:inline">Sverige, vi har ett resultat!</span>
            <span className="block sm:ml-2 sm:inline-block">
              <a href="#standings" className="text-white font-bold underline">
                Se tabellen här <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
        {/* <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div> */}
      </div>
    </div>
  )
}
