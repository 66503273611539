import { useEffect, useState } from 'react'
import { StopIcon } from '@heroicons/react/solid'
import { RoundsPageProps as Props } from './types'
import { Header } from '../../../../03-organisms/app-layout/header'
import { SearchHeader } from '../../../../03-organisms/app-layout/search-header'
import { RoundsList } from '../../../..'

export const RoundsPageLayout = ({ rounds, seriesContext }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector('html')
      if (htmlEl) {
        htmlEl.classList.add('h-full')
        htmlEl.classList.add('bg-white')
      }
      const bodyEl = document.querySelector('body')
      if (bodyEl) {
        bodyEl.classList.add('h-full')
        bodyEl.classList.add('overflow-hidden')
      }
      const nextWrapperEl = document.querySelector('#__next')
      if (nextWrapperEl) {
        nextWrapperEl.classList.add('h-full')
        nextWrapperEl.classList.add('overflow-hidden')
      }
    }
  })

  return (
    <>
      <div className="h-full flex">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          incomingSeriesId={seriesContext.id}
          seriesContext={seriesContext}
        />
        {/* Main column */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/* Search header */}
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {rounds.length === 0 ? (
              <div className="mt-12 text-center">
                <StopIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">Inga rundor</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Kom tillbaka till den här sidan vid ett senare tillfälle.
                </p>
              </div>
            ) : null}
            {rounds.length > 0 ? (
              <RoundsList rounds={rounds} seriesContext={seriesContext} />
            ) : null}
          </main>
        </div>
      </div>
    </>
  )
}
