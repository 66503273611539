import { useRouter } from 'next/router'
import { CogIcon } from '@heroicons/react/solid'
import { ComponentProps, RoundDetails } from './types'
import { useState } from 'react'
import { GenerateMatchesResponseData } from '../../../../pages/api/admin/series/[series_id]/round/[round_id]/generate-matches'

export { RoundDetails }

export const GenerateMatchesButton = ({ round, series }: ComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const router = useRouter()

  const generateMatches = async () => {
    if (confirm('Är du säker på att du vill generera matcher?')) {
      setLoading(true)

      const GenerateMatchRes = await fetch(
        `/api/admin/series/${series.id}/round/${round.round_public_id}/generate-matches`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (GenerateMatchRes.status !== 200) {
        setLoading(false)
        console.error(`Got a non 200 response from the backend.`, { GenerateMatchRes })
        return
      }

      const GenerateMatchesResData: GenerateMatchesResponseData = await GenerateMatchRes.json()
      if (!GenerateMatchesResData.success) {
        setLoading(false)
        console.error(
          `Got a non success response from the backend ${GenerateMatchesResData.code}.`,
          {
            GenerateMatchesResData,
          }
        )
        return
      } else {
        alert(`Skapade ${GenerateMatchesResData.matches} matcher.`)
        setLoading(false)
        router.replace(router.asPath)
      }
    }
  }

  return (
    <button
      type="button"
      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      onClick={generateMatches}
      disabled={round.accepted < 1}
    >
      <CogIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      Generera matcher
      {loading ? (
        <svg
          className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : null}
    </button>
  )
}
