import { ComponentProps } from './types'
import { Player, Players } from './players'
import { Result } from './result'
import { Information } from './information'
import { MatchHeader } from '../match-header'
import { MatchBreadcrumbs } from './breadcrumbs'

export const MatchResultSummary = ({ match, seriesContext }: ComponentProps) => {
  const homePlayers: Player[] = [
    {
      name: match.home_teams[0].player_name,
      company: match.home_teams[0].team_name,
    },
    {
      name: match.home_teams[1].player_name,
      company: match.home_teams[1].team_name,
    },
  ]
  const awayPlayers: Player[] = [
    {
      name: match.away_teams[0].player_name,
      company: match.away_teams[0].team_name,
    },
    {
      name: match.away_teams[1].player_name,
      company: match.away_teams[1].team_name,
    },
  ]

  return (
    <>
      <MatchBreadcrumbs match={match} seriesContext={seriesContext} />
      <MatchHeader match={match} seriesContext={seriesContext} />
      <Players home={homePlayers} away={awayPlayers} />
      <Result match={match} seriesContext={seriesContext} />
      <Information match={match} />
    </>
  )
}
