import Link from 'next/link'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { MatchResult } from '../match-result-row-item'
import { SeriesContext } from '../../../../api-utils'

type Props = {
  translations: {
    headline: string
  }
  reset: () => void
  match: MatchResult
  seriesContext: SeriesContext
}

export const CustomSuccessAlert = ({ translations, reset, match, seriesContext }: Props) => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">{translations.headline}</h3>
          {/* <div className="mt-2 text-sm text-green-700">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum
              similique veniam.
            </p>
          </div> */}
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <Link href={`/series/${seriesContext.id}/match/${match.match_public_id}`}>
                <a
                  type="button"
                  className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                >
                  Gå tillbaka
                </a>
              </Link>
              <button
                type="button"
                className="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={reset}
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
