/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: 'Michael Foster',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  },
  // More people...
]

export type Player = {
  name: string
  company: string
}
type Props = {
  home: Player[]
  away: Player[]
}

const TeamPlayers = ({ players }: { players: Player[] }) => (
  <div className="grid grid-cols-2 gap-x-2 justify-center items-center">
    {players.map((player) => (
      <div key={player.name} className="space-y-4">
        <div className="mx-auto h-12 w-12 rounded-full lg:w-16 lg:h-16 bg-gradient-to-r from-purple-600 to-blue-600 flex items-center justify-center text-white text-xs">
          {player.name.split(' ')[0]}
        </div>
        <div className="space-y-2">
          <div className="text-xs font-medium lg:text-sm truncate">
            <h3>{player.name.split(' ')[1]}</h3>
            <p className="text-indigo-600 truncate">{player.company}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
)

export const Players = ({ home, away }: Props) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-4 sm:space-y-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">Spelare</span>
            </div>
          </div>
          <div className="mx-auto grid grid-cols-2 gap-x-2 gap-y-8 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12">
            <p>Lag A</p>
            <p>Lag B</p>
          </div>
          <div className="mx-auto grid grid-cols-2 divide-x-2 divide-solid divide-gray-300 gap-x-2 gap-y-8 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12">
            <TeamPlayers players={home} />
            <TeamPlayers players={away} />
          </div>
        </div>
      </div>
    </div>
  )
}
