import { DateTime } from 'luxon'
import { classNames } from '../../../../utils/classnames'
import { ComponentProps, AdminMatchResult } from './types'

export { AdminMatchResult }

export const AdminMatchResultRowItem = ({ match, handleMatchClick }: ComponentProps) => {
  const winner1st = match.home_set_one_games && match.away_set_one_games && match.home_set_one_games > match.away_set_one_games ? 'home' : match.home_set_one_games && match.away_set_one_games && match.home_set_one_games < match.away_set_one_games ? 'away' : 'undetermined'
  const winner2nd = match.home_set_two_games && match.away_set_two_games && match.home_set_two_games > match.away_set_two_games ? 'home' : match.home_set_two_games && match.away_set_two_games && match.home_set_two_games < match.away_set_two_games ? 'away' : 'undetermined'
  const winner3rd = match.home_set_three_games && match.away_set_three_games && match.home_set_three_games > match.away_set_three_games ? 'home' : match.home_set_three_games && match.away_set_three_games && match.home_set_three_games < match.away_set_three_games ? 'away' : 'undetermined'
  const winnerTiebreak = match.home_tiebreak_points && match.away_tiebreak_points && match.home_tiebreak_points > match.away_tiebreak_points ? 'home' : match.home_tiebreak_points && match.away_tiebreak_points && match.home_tiebreak_points < match.away_tiebreak_points ? 'away' : 'undetermined'

  const dtStart = DateTime.fromISO(match.round_start)

  const matchResultBoxClasses = `px-3 py-2 text-center font-mono`

  const statusBadge = () => {
    switch (match.match_state) {
      case 'created':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
            Skapad
          </span>
        )
      case 'started':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            Startad
          </span>
        )
      case 'completed':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
            Spelad
          </span>
        )
      case 'confirmed':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
            Bekräftad
          </span>
        )
      case 'deleted':
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
            Borttagen
          </span>
        )
      default:
        return (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
            Oväntad status
          </span>
        )
    }
  }

  return (
    <li key={match.round_name} className="block" onClick={() => {
      if (match && match.match_id) handleMatchClick(match)
    }}>
      <div className="px-4 py-4 flex items-center sm:px-6 font-light">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="bg-white pb-5 pt-3 border-b border-gray-200 sm:border-0 sm:px-6">
            <h3 className="text-base leading-6 font-medium text-gray-900">{match.round_name} @ {match.match_location_name}</h3>
            <p className="mt-1 text-sm text-gray-500">
              {dtStart.setLocale('sv').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              {statusBadge()}
            </p>
          </div>
          <div>
            <div className="mt-4 py-1 grid grid-cols-8 grid-rows-2 font-light text-xs items-center gap-x-2">
              <p className="font-bold row-span-2 col-span-1 px-2 py-2 border border-gray-200 border-solid rounded-md text-center">L1</p>
              <p className={classNames('py-0 col-span-7')}>{match.home_teams[0].team_name}{" "}({match.home_teams[0].player_name})</p>
              <p className={classNames('py-0 col-span-7')}>{match.home_teams[1].team_name}{" "}({match.home_teams[1].player_name})</p>
            </div>
            <div className="py-1 grid grid-cols-8 grid-rows-2 font-light text-xs items-center gap-x-2">
              <p className="font-bold row-span-2 col-span-1 px-2 py-2 border border-gray-200 border-solid rounded-md text-center">L2</p>
              <p className={classNames('py-0 col-span-7')}>{match.away_teams[0].team_name}{" "}({match.away_teams[0].player_name})</p>
              <p className={classNames('py-0 col-span-7')}>{match.away_teams[1].team_name}{" "}({match.away_teams[1].player_name})</p>
            </div>
          </div>
          <div className={classNames(
            match.tiebreak ? 'grid-cols-7' : 'grid-cols-6',
            "mt-4 grid grid-rows-3 divide-x-2 divide-y-2 border border-gray-200 border-solid rounded-md font-light text-xs items-center"
          )}>
            <p />
            <p title="Set 1" className={classNames('font-bold', matchResultBoxClasses)}>1</p>
            <p title="Set 2" className={classNames('font-bold', matchResultBoxClasses)}>2</p>
            <p title="Set 3" className={classNames('font-bold', matchResultBoxClasses)}>3</p>
            <p title="Totalt antal gem" className={classNames('font-bold', matchResultBoxClasses)}>T</p>
            {match.tiebreak ? <p title="Tiebreak" className={classNames('font-bold', matchResultBoxClasses)}>TB</p> : null}
            <p title="Poäng" className={classNames('font-bold', matchResultBoxClasses)}>P</p>
            <p className={classNames('font-bold', matchResultBoxClasses)}>
              L1
            </p>
            <p className={classNames(winner1st === 'home' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.home_set_one_games}
            </p>
            <p className={classNames(winner2nd === 'home' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.home_set_two_games}
            </p>
            <p className={classNames(winner3rd === 'home' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.home_set_three_games}
            </p>
            <p className={classNames(match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100', matchResultBoxClasses)}>
              {match.home_total_games}
            </p>
            {match.tiebreak ? (
              <p className={classNames(winnerTiebreak === 'home' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
                {match.home_tiebreak_points}
              </p>
            ) : null}
            <p className={classNames(match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100', matchResultBoxClasses)}>
              {match.winner === 'home' ? match.winning_points : match.loosing_points}
            </p>
            <p className={classNames('font-bold', matchResultBoxClasses)}>
              L2
            </p>
            <p className={classNames(winner1st === 'away' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.away_set_one_games}
            </p>
            <p className={classNames(winner2nd === 'away' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.away_set_two_games}
            </p>
            <p className={classNames(winner3rd === 'away' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
              {match.away_set_three_games}
            </p>
            <p className={classNames(match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100', matchResultBoxClasses)}>
              {match.away_total_games}
            </p>
            {match.tiebreak ? (
              <p className={classNames(winnerTiebreak === 'away' ? 'bg-green-100' : 'bg-red-100', matchResultBoxClasses)}>
                {match.away_tiebreak_points}
              </p>
            ) : null}
            <p className={classNames(match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100', matchResultBoxClasses)}>
              {match.winner === 'away' ? match.winning_points : match.loosing_points}
            </p>
          </div>
        </div>
      </div>
    </li>
  )
}