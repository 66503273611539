import { AdminPlayerMatcherTableRowItem } from '../../02-molecules/admin-player-matcher-table-row-item'
import { ComponentProps, AdminPlayerBreakdownRows } from './types'

export { AdminPlayerBreakdownRows }

export const AdminPlayerMatcherTable = ({ allRows }: ComponentProps) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
          >
            Spelare
          </th>
          {allRows.map((row) => (
            <th
              key={`${row.player_id}--header-row`}
              scope="col"
              className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              {row.player_name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {allRows.map((row, rowIdx) => <AdminPlayerMatcherTableRowItem key={row.player_id} row={row} rowIdx={rowIdx} allRows={allRows} />)}
      </tbody>
    </table>
  )
}