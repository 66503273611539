import { Locale } from '../../../../../types'


type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Anmälningar',
    pageDescription:
      'Här kan du se de anmälningar som hittills gjorts för den här veckan.',
  },
  en: {
    pageTitle: 'Anmälningar',
    pageDescription:
      'Här kan du se de anmälningar som hittills gjorts för den här veckan.',
  },
}
