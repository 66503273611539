import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Locale } from '../../../../../types'
import { makeLocale } from '../../../../../utils/locale'
import { translations } from './types'
import { ErrorAlert, SuccessAlert } from '../../../02-molecules'
import { ReportMatchRequestBody, ReportMatchResponseData } from '../../../../../pages/api/admin/match/report'

type FormValues = {
  home_set_one: number
  home_set_two: number
  home_set_three: number
  home_tiebreak: number
  away_set_one: number
  away_set_two: number
  away_set_three: number
  away_tiebreak: number
}

type Props = {
  matchId: number
  open: boolean
  setOpen: (open: boolean) => void
  initialValues?: FormValues
}

export const MatchResultForm = ({
  matchId,
  initialValues = {
    home_set_one: 0,
    home_set_two: 0,
    home_set_three: 0,
    home_tiebreak: 0,
    away_set_one: 0,
    away_set_two: 0,
    away_set_three: 0,
    away_tiebreak: 0,
  },
  setOpen
}: Props) => {
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<'ok' | 'not_ok' | 'pristine'>()

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const reset = () => {
    setError(undefined)
    setStatus('pristine')
  }

  const ValidationSchema = Yup.object().shape({
    home_set_one: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_set_two: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_set_three: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    home_tiebreak: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem'),
    away_set_one: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_set_two: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_set_three: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem')
      .required('Detta fält måste ha ett värde'),
    away_tiebreak: Yup.number()
      .min(0, 'Måste vara minst 0')
      .max(7, 'Du kan bara vinna som mest 7 gem'),
  })

  return (
    <>
      {status === 'ok' ? (
        <SuccessAlert translations={{ headline: translation.success.headline }} reset={reset} />
      ) : null}
      {status === 'not_ok' && error ? (
        <ErrorAlert
          translations={{ headline: translation.error.headline }}
          error={error}
          reset={reset}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            const isTiebreak = values.home_tiebreak > 0 || values.away_tiebreak > 0
            const requestBody: ReportMatchRequestBody = {
              matchId,
              tiebreak: isTiebreak,
              sendNotifications: true,
              homeResultSet: {
                set_one: values.home_set_one,
                set_two: values.home_set_two,
                set_three: values.home_set_three,
                tiebreak: isTiebreak && values.home_tiebreak ? values.home_tiebreak : 0
              },
              awayResultSet: {
                set_one: values.away_set_one,
                set_two: values.away_set_two,
                set_three: values.away_set_three,
                tiebreak: isTiebreak && values.away_tiebreak ? values.away_tiebreak : 0
              },
            }
            const ReportGameResultRes = await fetch('/api/admin/match/report', {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            if (ReportGameResultRes.status !== 200) {
              setStatus('not_ok')
              setError(new Error('Non 200 response from backend'))
              actions.resetForm()
              actions.setSubmitting(false)
              return
            }

            const ReportGameResultResData: ReportMatchResponseData = await ReportGameResultRes.json()
            if (!ReportGameResultResData.success) {
              setStatus('not_ok')
              setError(new Error(ReportGameResultResData.code))
              actions.resetForm()
              actions.setSubmitting(false)
            } else {
              actions.resetForm()
              actions.setSubmitting(false)
              setOpen(false)
              router.replace(router.asPath)
            }
          } catch (caughtError) {
            setStatus('not_ok')
            if (caughtError instanceof Error) {
              setError(caughtError)
            } else {
              setError(new Error('Report game result failed due to a unknown error'))
            }
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}>
        {(formikBag) => {
          return (
            <Form>
              <div className='py-5'>
                <div className=''>

                  <div className="flex gap-x-2">
                    <Field name='home_set_one'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='home_set_one' className='sr-only'>
                            Hemma set 1
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='home_set_two'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='home_set_two' className='sr-only'>
                            Hemma set 2
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='home_set_three'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='home_set_three' className='sr-only'>
                            Hemma set 3
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='home_tiebreak'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='home_tiebreak' className='sr-only'>
                            Hemma tiebreak
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="flex gap-x-2">
                    <Field name='away_set_one'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='away_set_one' className='sr-only'>
                            Borta set 1
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='away_set_two'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='away_set_two' className='sr-only'>
                            Borta set 2
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='away_set_three'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='away_set_three' className='sr-only'>
                            Borta set 3
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='away_tiebreak'>
                      {({ field, meta }: Record<string, any>) => (
                        <div className='col-span-6 sm:col-span-3'>
                          <label htmlFor='away_tiebreak' className='sr-only'>
                            Borta tiebreak
                          </label>
                          <input
                            {...field}
                            required
                            type='number'
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-center'
                          />
                          {meta.touched && meta.error && (
                            <p className='text-red-500 text-xs italic pt-2'>{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                </div>
              </div>
              <div className='py-3 text-right'>
                {/* <button onClick={() => {
                  if (confirm('Är du säker på att du vill avbryta att rapportera matchresultat?')) {
                    formikBag.resetForm()
                    setOpen(false)
                  }
                }} className='mr-2 bg-indigo-50 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-indigo-900 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500'>
                  Avbryt
                </button> */}
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  disabled={formikBag.isSubmitting || !formikBag.isValid}>
                  {translation.button.text}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
