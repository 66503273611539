import Link from 'next/link'

import { CheckIcon } from '@heroicons/react/solid'
import { replace, round } from 'lodash'
import { useRouter } from 'next/router'

import { useSalesTax } from '../../../../../hooks'
import { makeLocale } from '../../../../../utils/locale'
import { translations } from './translations'
import { Locale } from '../../../../../types'

import useFathomEvents from '../../../../../hooks/useFathomEvents'

type Props = {
  packageLength: number
  setPackageLength: (packageLength: number) => void
}
export const Alternatives = ({ packageLength, setPackageLength }: Props) => {
  const { trackSignupClickOnHome } = useFathomEvents()
  const { type } = useSalesTax()
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale) || 'sv'
  const translation = translations[locale as Locale]

  const getPrice = (price: number): number =>
    type === 'exclusive' ? price : round(price * 1.06, 0)

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            {translation.headline}
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">{translation.description}</p>
          {/* <div className="relative self-center mt-6 bg-gray-200 rounded-lg p-0.5 flex sm:mt-8">
            <button
              type="button"
              className={classNames(
                packageLength === 10
                  ? 'bg-white border-gray-200 shadow-sm text-gray-900'
                  : 'border border-transparent text-gray-700',
                'w-1/2 relative rounded-md font-medium text-sm py-2 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8'
              )}
              onClick={() => setPackageLength(10)}
            >
              {translation.lengthSelectors.tenWeeks.label}
            </button>
            <button
              type="button"
              className={classNames(
                packageLength === 5
                  ? 'bg-white border-gray-200 shadow-sm text-gray-900'
                  : 'border border-transparent text-gray-700',
                'ml-0.5 w-1/2 relative rounded-md font-medium text-sm py-2 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8'
              )}
              onClick={() => setPackageLength(5)}
            >
              {translation.lengthSelectors.fiveWeeks.label}
            </button>
          </div> */}
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          {translation.tiers.map((tier) => (
            <div
              key={tier.name}
              className="bg-white border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4 text-sm text-gray-500">
                  {replace(tier.description, '{{packageLength}}', `${packageLength}`)}
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {packageLength === 10
                      ? getPrice(tier.priceTenWeeks)
                      : getPrice(tier.priceFiveWeeks)}
                    {translation.price.suffix}
                  </span>{' '}
                  <span className="text-base font-medium text-gray-500">
                    /{translation.price.type}
                  </span>
                </p>
                {!tier.full ? (
                  <Link href={`${tier.href}&length=${packageLength}weeks`}>
                    <a
                      onClick={trackSignupClickOnHome}
                      className="mt-8 block w-full rounded-md py-2 text-center text-base font-medium text-white bg-gradient-to-r from-purple-600 to-blue-600 bg-origin-border border border-transparent hover:from-purple-700 hover:to-blue-700"
                    >
                      {translation.callToActionButtons.prefix} {tier.name}
                    </a>
                  </Link>
                ) : null}
                {tier.full ? (
                  <div className="mt-8 w-full rounded-md py-2 text-center text-base font-medium text-white bg-gradient-to-r from-teal-600 to-green-600 bg-origin-border border border-transparent inline-flex gap-x-2 justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Fullbokat</span>
                  </div>
                ) : null}
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  Vad ingår
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {[
                    ...tier.includedFeatures,
                    ...(packageLength === 10 ? tier.featuresTenWeek : tier.featuresFiveWeek),
                  ].map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
