import Link from 'next/link'

export const CTA = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-indigo-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Vill du vara med?</span>
                <span className="block">Hör av dig idag.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Nästa säsong sker till hösten. Skicka ett meddelande till oss så berättar vi mer.
              </p>
              <a
                href="https://forms.reform.app/gLNw4a/kontaktformular/jo0zn1"
                target="_blank"
                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow hover:bg-indigo-50"
                rel="noopener noreferrer"
              >
                Kontakta oss
              </a>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
            <img
              className="translate-x-6 translate-y-6 transform rounded-md object-cover object-right-top sm:translate-x-16 lg:translate-y-20"
              src="/assets/martin-hitting-the-ball.jpg"
              alt="Martin hitting the ball"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
