import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { SeriesContext } from '../../../../api-utils'

export const Round = t.type({
  public_id: UUID,
  name: t.string,
  start: t.string,
  series_id: t.string,
  start_week: t.number,
  current_week: t.number,
  is_this_week: t.boolean,
  done: t.boolean,
  upcoming: t.boolean,
  invitations: t.number,
  accepted: t.number,
  declined: t.number,
  pending: t.number,
  confirmed_matches: t.number,
  completed_matches: t.number,
})

export type Round = t.TypeOf<typeof Round>

export type ComponentProps = {
  round: Round
  seriesContext: SeriesContext
}
