import { Locale } from '../../../../../types'

type Tier = {
  name: string
  href: string
  priceTenWeeks: number
  priceFiveWeeks: number
  description: string
  includedFeatures: string[]
  featuresTenWeek: string[]
  featuresFiveWeek: string[]
  full?: boolean
}

type Translation = {
  headline: string
  description: string
  tiers: Tier[]
  callToActionButtons: {
    prefix: string
  }
  price: {
    suffix: string
    type: string
  }
  lengthSelectors: {
    tenWeeks: {
      label: string
    }
    fiveWeeks: {
      label: string
    }
  }
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    headline: 'Vad är rätt för er?',
    description:
      'Vi erbjuder olika varianter för att kunna fungera för fler deltagare. Kolla in dem nedan.',
    callToActionButtons: {
      prefix: 'Boka',
    },
    price: {
      suffix: 'kr',
      type: 'omgång',
    },
    lengthSelectors: {
      fiveWeeks: {
        label: '5 veckor',
      },
      tenWeeks: {
        label: '10 veckor',
      },
    },
    tiers: [
      {
        name: 'Morgon',
        href: '/signup?alternative=morning',
        priceTenWeeks: 2999,
        priceFiveWeeks: 1499,
        description:
          '{{packageLength}} tillfällen med 90 minuter padel och frukost från Hälsobaren.',
        includedFeatures: [
          '07:00-08:30 spel.',
          '08:30-09:00 frukost.',
          'Frukost från Hälsobaren.',
          'Slutspel och säsongsavslutning med Mexicano-turnering.',
          'Kan delas på mellan kollegor',
        ],
        featuresTenWeek: ['Upp till 17 timmar padel.'],
        featuresFiveWeek: ['Upp till 9,5 timmar padel.'],
        full: false,
      },
      {
        name: 'Lunch',
        href: '/signup?alternative=lunch',
        priceTenWeeks: 2999,
        priceFiveWeeks: 1499,
        description: '{{packageLength}} tillfällen med 60 minuter padel och lunch från Hälsobaren.',
        includedFeatures: [
          '11:30-12:30 spel.',
          '12:30-13:00 lunch.',
          'Lunch från Hälsobaren',
          'Slutspel och säsongsavslutning med Mexicano-turnering.',
          'Kan delas på mellan kollegor',
        ],
        featuresTenWeek: ['Upp till 12 timmar padel.'],
        featuresFiveWeek: ['Upp till 7 timmar padel.'],
        full: false,
      },
    ],
  },
  en: {
    headline: 'Vad är rätt för er?',
    description:
      'Vi erbjuder lite olika varianter för att kunna fungera för fler deltagare. Kolla in dem nedan.',
    callToActionButtons: {
      prefix: 'Boka',
    },
    price: {
      suffix: 'kr',
      type: 'omgång',
    },
    lengthSelectors: {
      fiveWeeks: {
        label: '5 veckor',
      },
      tenWeeks: {
        label: '10 veckor',
      },
    },
    tiers: [
      {
        name: 'Morgon',
        href: '/signup?alternative=morning',
        priceTenWeeks: 2999,
        priceFiveWeeks: 1499,
        description: '{{packageLength}} tillfällen med 90 minuter padel och frukost.',
        includedFeatures: [
          '07:00-08:30 spel.',
          '08:30-09:00 frukost.',
          'Kaffe på plats innan match.',
          'Slutspel och säsongsavslutning med Mexicano-turnering.',
        ],
        featuresTenWeek: ['Upp till 17 timmar padel.'],
        featuresFiveWeek: ['Upp till 9,5 timmar padel.'],
        full: false,
      },
      {
        name: 'Lunch',
        href: '/signup?alternative=lunch',
        priceTenWeeks: 2999,
        priceFiveWeeks: 1499,
        description: '{{packageLength}} tillfällen med 60 minuter padel och lunch från Hälsobaren.',
        includedFeatures: [
          '11:30-12:30 spel.',
          '12:30-13:00 lunch.',
          'Lunch från Hälsobaren',
          'Slutspel och säsongsavslutning med Mexicano-turnering.',
        ],
        featuresTenWeek: ['Upp till 12 timmar padel.'],
        featuresFiveWeek: ['Upp till 7 timmar padel.'],
        full: false,
      },
    ],
  },
}
