import { RoundRowItem } from '../../02-molecules'
import { ComponentProps, RoundRows } from './types'

export { RoundRows }

export const RoundsList = ({ rounds, seriesContext }: ComponentProps) => {
  const upcomingRounds = rounds.filter((round) => !round.done)
  const completedRounds = rounds.filter((round) => round.done)
  return (
    <div className="mt-8 bg-white overflow-hidden">
      {upcomingRounds && upcomingRounds.length > 0 ? (
        <>
          <div className="pb-5 px-6 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Kommande rundor</h3>
          </div>
          <ul role="list" className="divide-y divide-gray-200">
            {upcomingRounds.map((round) => (
              <RoundRowItem key={round.public_id} round={round} seriesContext={seriesContext} />
            ))}
          </ul>
        </>
      ) : null}
      {completedRounds && completedRounds.length > 0 ? (
        <>
          <div className="pb-5 px-6 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Spelade rundor</h3>
          </div>
          <ul role="list" className="divide-y divide-gray-200">
            {completedRounds.map((round) => (
              <RoundRowItem key={round.public_id} round={round} seriesContext={seriesContext} />
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}
