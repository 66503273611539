import { Locale } from '../../../../../types'


type Translation = {
  pageTitle: string
  pageDescription: string
}
type Translations = {
  [key in Locale]: Translation
}
export const translations: Translations = {
  sv: {
    pageTitle: 'Ö-viks Nätverkspadel',
    pageDescription:
      'Vi samlas på onsdagar för att spela padel och nätverka. Två flugor i en smäll.',
  },
  en: {
    pageTitle: "Ö-vik's Network Padel ",
    pageDescription: 'We gather on Wednesdays to play padel and network. Two birds with one stone.',
  },
}
