import Link from 'next/link'
import { PencilIcon, FlagIcon } from '@heroicons/react/solid'
import { MatchResult } from '../match-result-row-item'
import { classNames } from '../../../../utils/classnames'
import { SeriesContext } from '../../../../api-utils'

export const Result = ({
  match,
  seriesContext,
}: {
  match: MatchResult
  seriesContext: SeriesContext
}) => {
  const winner1st =
    match.home_set_one_games > match.away_set_one_games
      ? 'home'
      : match.home_set_one_games < match.away_set_one_games
      ? 'away'
      : 'undetermined'
  const winner2nd =
    match.home_set_two_games > match.away_set_two_games
      ? 'home'
      : match.home_set_two_games < match.away_set_two_games
      ? 'away'
      : 'undetermined'
  const winner3rd =
    match.home_set_three_games > match.away_set_three_games
      ? 'home'
      : match.home_set_three_games < match.away_set_three_games
      ? 'away'
      : 'undetermined'
  const winnerTiebreak =
    match.home_tiebreak_points > match.away_tiebreak_points
      ? 'home'
      : match.home_tiebreak_points < match.away_tiebreak_points
      ? 'away'
      : 'undetermined'

  const matchResultBoxClasses = `px-3 py-2 text-center font-mono`

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-4 sm:space-y-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm">
                <Link
                  href={`/series/${seriesContext.id}/match/${match.match_public_id}/result/edit`}
                >
                  <a className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                    <span className="sr-only">Ändra</span>
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </Link>
              </span>
            </div>
          </div>
          <div className="flex items-center px-4 py-4 font-light sm:px-6">
            <div className="w-full min-w-0 flex-1 sm:flex sm:items-center sm:justify-center">
              <div
                className={classNames(
                  match.tiebreak ? 'grid-cols-7' : 'grid-cols-6',
                  'grid grid-rows-3 items-center divide-x-2 divide-y-2 rounded-md border border-solid border-gray-200 text-xs font-light sm:w-10/12'
                )}
              >
                <p />
                <p title="Set 1" className={classNames('font-bold', matchResultBoxClasses)}>
                  1
                </p>
                <p title="Set 2" className={classNames('font-bold', matchResultBoxClasses)}>
                  2
                </p>
                <p title="Set 3" className={classNames('font-bold', matchResultBoxClasses)}>
                  3
                </p>
                <p
                  title="Totalt antal gem"
                  className={classNames('font-bold', matchResultBoxClasses)}
                >
                  T
                </p>
                {match.tiebreak ? (
                  <p title="Tiebreak" className={classNames('font-bold', matchResultBoxClasses)}>
                    TB
                  </p>
                ) : null}
                <p title="Poäng" className={classNames('font-bold', matchResultBoxClasses)}>
                  P
                </p>
                <p className={classNames('font-bold', matchResultBoxClasses)}>A</p>
                <p
                  className={classNames(
                    winner1st === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_one_games}
                </p>
                <p
                  className={classNames(
                    winner2nd === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_two_games}
                </p>
                <p
                  className={classNames(
                    winner3rd === 'home' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_set_three_games}
                </p>
                <p
                  className={classNames(
                    match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.home_total_games}
                </p>
                {match.tiebreak ? (
                  <p
                    className={classNames(
                      winnerTiebreak === 'home' ? 'bg-green-100' : 'bg-red-100',
                      matchResultBoxClasses
                    )}
                  >
                    {match.home_tiebreak_points}
                  </p>
                ) : null}
                <p
                  className={classNames(
                    match.winner === 'home' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.winner === 'home' ? match.winning_points : match.loosing_points}
                </p>
                <p className={classNames('font-bold', matchResultBoxClasses)}>B</p>
                <p
                  className={classNames(
                    winner1st === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_one_games}
                </p>
                <p
                  className={classNames(
                    winner2nd === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_two_games}
                </p>
                <p
                  className={classNames(
                    winner3rd === 'away' ? 'bg-green-100' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_set_three_games}
                </p>
                <p
                  className={classNames(
                    match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.away_total_games}
                </p>
                {match.tiebreak ? (
                  <p
                    className={classNames(
                      winnerTiebreak === 'away' ? 'bg-green-100' : 'bg-red-100',
                      matchResultBoxClasses
                    )}
                  >
                    {match.away_tiebreak_points}
                  </p>
                ) : null}
                <p
                  className={classNames(
                    match.winner === 'away' ? 'bg-green-100 font-extrabold' : 'bg-red-100',
                    matchResultBoxClasses
                  )}
                >
                  {match.winner === 'away' ? match.winning_points : match.loosing_points}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
