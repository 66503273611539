/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import { Fragment, useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { orderBy, take } from 'lodash'
import { PlayerProps as Props } from './types'
import { Header } from '../../../../../03-organisms/app-layout/header'
import { SearchHeader } from '../../../../../03-organisms/app-layout/search-header'
import { classNames } from '../../../../../../../utils/classnames'

export const StandingsLayout = ({ standings, seriesContext }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector('html')
      if (htmlEl) {
        htmlEl.classList.add('h-full')
        htmlEl.classList.add('bg-white')
      }
      const bodyEl = document.querySelector('body')
      if (bodyEl) {
        bodyEl.classList.add('h-full')
        bodyEl.classList.add('overflow-hidden')
      }
      const nextWrapperEl = document.querySelector('#__next')
      if (nextWrapperEl) {
        nextWrapperEl.classList.add('h-full')
        nextWrapperEl.classList.add('overflow-hidden')
      }
    }
  })

  const orderedStandings = orderBy(
    standings,
    ['points_standing', 'plus_minus', 'no_of_matches'],
    ['desc', 'desc', 'desc']
  )
  const filteredStandings = orderedStandings.filter((row) => row.no_of_matches > 0)
  const top3 = take(filteredStandings, 3)

  return (
    <>
      <div className="flex h-full">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          seriesContext={seriesContext}
        />
        {/* Main column */}
        <div className="flex w-0 flex-1 flex-col overflow-hidden">
          {/* Search header */}
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="min-w-0 flex-1">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Spelare</h1>
              </div>
            </div>
            {top3.length > 0 ? (
              <div className="mt-6 px-4 sm:px-6 lg:px-8">
                <h2 className="text-xs font-medium uppercase tracking-wide text-gray-500">Top 3</h2>
                <ul
                  role="list"
                  className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4"
                >
                  {top3.map((player, i) => (
                    <li key={player.name} className="relative col-span-1 flex rounded-md shadow-sm">
                      <div
                        className={classNames(
                          'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md border-t border-l border-b border-gray-200 bg-white pl-1 text-sm font-medium text-white'
                        )}
                      >
                        {player.image_url_logo ? <img src={player.image_url_logo} /> : null}
                        {!player.image_url_logo ? i + 1 : null}
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <Link href={`/series/${seriesContext.id}/player/${player.user_id}`}>
                            <a className="font-medium text-gray-900 hover:text-gray-600">
                              {player.name}
                            </a>
                          </Link>
                          <p className="text-gray-500">{player.points_standing} Poäng</p>
                        </div>
                        <Menu as="div" className="flex-shrink-0 pr-2">
                          <Menu.Button className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                            <span className="sr-only">Open options</span>
                            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-10 top-3 z-10 mx-3 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      href={`/series/${seriesContext.id}/player/${player.user_id}`}
                                    >
                                      <a
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm'
                                        )}
                                      >
                                        Se mer
                                      </a>
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                              {/* <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                      )}
                                    >
                                      Removed from pinned
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                      )}
                                    >
                                      Share
                                    </a>
                                  )}
                                </Menu.Item>
                              </div> */}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {/* Projects list (only on smallest breakpoint) */}
            <div className="mt-10 sm:hidden">
              <div className="px-4 sm:px-6">
                <h2 className="text-xs font-medium uppercase tracking-wide text-gray-500">
                  Tabell
                </h2>
              </div>
              <ul role="list" className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
                {orderedStandings.map((player, i) => (
                  <li key={player.user_id}>
                    <Link href={`/series/${seriesContext.id}/player/${player.user_id}`}>
                      <a className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
                        <span className="flex items-center space-x-3 truncate">
                          <span
                            className={'flex flex-shrink-0 items-center justify-center font-mono'}
                            aria-hidden="true"
                          >
                            {i + 1}
                          </span>
                          <span className="truncate text-sm font-medium leading-6">
                            {player.name}{' '}
                            <span className="truncate font-normal text-gray-500">
                              med {player.points_standing} poäng
                            </span>
                          </span>
                        </span>
                        <ChevronRightIcon
                          className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Projects table (small breakpoint and up) */}
            <div className="mt-8 hidden sm:block">
              <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="border-t border-gray-200">
                      <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500">
                        Rank
                      </th>
                      <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
                        Lag
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        GP
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        5P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        4P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        3P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        2P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        -2P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        -3P
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        G
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        GA
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        +/-
                      </th>
                      <th className="hidden border-b border-gray-200 bg-gray-100 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider text-gray-500 sm:table-cell">
                        Poäng
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100 bg-white">
                    {orderedStandings.map((standingsRow, i) => {
                      return (
                        <tr key={standingsRow.name} className={i % 2 ? 'bg-gray-50' : ''}>
                          <td className="whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500">
                            {i + 1}
                          </td>
                          <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                            <Link
                              href={`/series/${seriesContext.id}/player/${standingsRow.user_id}`}
                            >
                              <a className="truncate hover:text-gray-600">{standingsRow.name}</a>
                            </Link>
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.no_of_matches}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.five_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.four_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.three_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.two_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.minus_two_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.minus_three_points}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.total_games_won}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.total_games_lost}
                          </td>
                          <td className="hidden whitespace-nowrap px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.plus_minus}
                          </td>
                          <td className="hidden whitespace-nowrap bg-gray-100 px-6 py-3 text-center text-sm font-light lining-nums text-gray-500 sm:table-cell">
                            {standingsRow.points_standing}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Förkortningar</h3>
                    <div className="mt-5">
                      <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                        <div className="grid grid-cols-12">
                          {[
                            { text: 'Rank', description: 'Ranking' },
                            { text: 'Lag', description: 'Lag för vald säsong' },
                            { text: 'GP', description: 'Spelade matcher' },
                            { text: '5P', description: '5 poäng (stor vinst)' },
                            { text: '4P', description: '4 poäng (medelstor vinst)' },
                            { text: '3P', description: '3 poäng (liten vinst)' },
                            { text: '2P', description: '2 poäng (vinst efter tiebreak)' },
                            { text: '-2P', description: '-2 poäng (förlust i tiebreak)' },
                            { text: '-3P', description: '-3 poäng (förlust)' },
                            { text: 'G', description: 'Vunna gem' },
                            { text: 'GA', description: 'Förlorade gem' },
                            { text: '+/-', description: 'Plus/minus för gem' },
                            {
                              text: 'Poäng',
                              description: 'Nuvarande poäng med start på 100 poäng',
                            },
                          ].map((abbr) => (
                            <Fragment key={abbr.text}>
                              <p className="col-span-2 col-start-1 font-bold">{abbr.text}</p>
                              <p className="col-span-10 col-start-3">{abbr.description}</p>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
