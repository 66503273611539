import { ClockIcon, CalendarIcon, CalculatorIcon } from '@heroicons/react/outline'
import { get, take, orderBy, reduce } from 'lodash'
import { DateTime } from 'luxon'
import { SignupRowItem } from '../../02-molecules'
import { ComponentProps, SignupRows } from './types'

export { SignupRows }

const SignupsGroup = ({
  group,
  showDetails = true,
  series,
  round,
}: {
  group: SignupRows
  showDetails?: boolean
  series: ComponentProps['series']
  round: ComponentProps['round']
}) => {
  if (group.length === 0) return null
  const groupOrdered = orderBy(group, ['signup_state', 'signup_created_at'], ['asc', 'asc'])
  const acceptedCount = reduce(
    groupOrdered,
    (sum, row) => (row && row.signup_state && row.signup_state === 'accepted' ? sum + 1 : sum),
    0
  )
  const [signup] = take(groupOrdered, 1)
  const roundName = get(signup, 'series_round_name', '')
  const dtStart = DateTime.fromISO(signup.series_round_start)
  const dtEnd = DateTime.fromISO(signup.series_round_end)

  return (
    <>
      <div className="pb-5 border-b border-gray-200 px-4 py-4 sm:px-6 lg:px-8">
        <div className="mt-2 max-w-4xl text-sm text-gray-500">
          {showDetails ? (
            <>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
                {roundName}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <ClockIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
                {dtStart.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)} till{' '}
                {dtEnd.setLocale('sv').toLocaleString(DateTime.TIME_24_SIMPLE)}
              </p>
            </>
          ) : null}
          <p className="mt-2 flex items-center text-sm text-gray-500">
            <CalculatorIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
              aria-hidden="true"
            />
            {acceptedCount}
          </p>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {groupOrdered.map((signup) => (
          <SignupRowItem
            key={signup.invitation_public_id}
            signup={signup}
            series={series}
            round={round}
          />
        ))}
      </ul>
    </>
  )
}

export const SignupsList = ({ signups, showDetails, series, round }: ComponentProps) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <SignupsGroup group={signups} showDetails={showDetails} series={series} round={round} />
    </div>
  )
}
