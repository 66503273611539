import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { SeriesContext } from '../../../../api-utils'

const Winner = t.union([
  t.literal('home'),
  t.literal('away'),
  t.literal('draw'),
  t.literal('unknown'),
])

const MatchTeam = t.type({
  team_name: t.string,
  player_name: t.string,
})

export type MatchTeam = t.TypeOf<typeof MatchTeam>

export const MatchResult = t.intersection([
  t.type({
    match_id: t.number,
    match_public_id: UUID,
    round_name: t.string,
    round_start: t.string,
    series_id: t.string,
    home_teams: t.array(MatchTeam),
    away_teams: t.array(MatchTeam),
    match_location_name: t.string,
    match_location_adress: t.string,
    match_location_zipcode: t.string,
    match_location_city: t.string,
    match_location_country: t.string,
    match_court_name: t.string,
    is_upcoming_match: t.boolean,
    home_total_games: t.number,
    home_set_one_games: t.number,
    home_set_two_games: t.number,
    home_set_three_games: t.number,
    away_total_games: t.number,
    away_set_one_games: t.number,
    away_set_two_games: t.number,
    away_set_three_games: t.number,
    winner: Winner,
    winning_points: t.number,
    loosing_points: t.number,
    tiebreak: t.boolean,
    home_tiebreak_points: t.number,
    away_tiebreak_points: t.number,
  }),
  t.partial({
    current_team_is: t.string,
  }),
])

export type MatchResult = t.TypeOf<typeof MatchResult>

export type ComponentProps = {
  match: MatchResult
  seriesContext: SeriesContext
}
