import * as t from 'io-ts'
import { MatchResultRows } from '../../..'
import { SeriesContext } from '../../../../../api-utils'

export const UserInformation = t.type({
  user_id: t.string,
  name: t.string,
  points_standing: t.number,
  average_match_point: t.number,
  no_of_matches: t.number,
  positive: t.number,
  negative: t.number,
  total_games_won: t.number,
  total_games_lost: t.number,
  plus_minus: t.number,
  five_points: t.number,
  four_points: t.number,
  three_points: t.number,
  two_points: t.number,
  minus_two_points: t.number,
  minus_three_points: t.number,
})

export type UserInformation = t.TypeOf<typeof UserInformation>

export type UserPageProps = {
  matchResults: MatchResultRows
  userInformation: UserInformation
  seriesContext: SeriesContext
}