/* eslint-disable @next/next/no-img-element */
import { Fragment, useEffect, useState } from 'react'
import { UserPageProps as Props } from './types'
import { take } from 'lodash'
import { Header } from "../../../../03-organisms/app-layout/header"
import { SearchHeader } from "../../../../03-organisms/app-layout/search-header"
import { MatchResultList } from '../../../..'

export const UserPageLayout = ({ matchResults, userInformation, seriesContext }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  useEffect(() => {
    if (document) {
      const htmlEl = document.querySelector("html")
      if (htmlEl) {
        htmlEl.classList.add("h-full")
        htmlEl.classList.add("bg-white")

      }
      const bodyEl = document.querySelector("body")
      if (bodyEl) {
        bodyEl.classList.add("h-full")
        bodyEl.classList.add("overflow-hidden")
      }
      const nextWrapperEl = document.querySelector("#__next")
      if (nextWrapperEl) {
        nextWrapperEl.classList.add("h-full")
        nextWrapperEl.classList.add("overflow-hidden")
      }
    }
  })

  const [result] = take(matchResults, 1)
  return (
    <>
      <div className="h-full flex">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} incomingSeriesId={result?.series_id} seriesContext={seriesContext} />
        {/* Main column */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/* Search header */}
          <SearchHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{userInformation.name}</h1>
              </div>
            </div>
            <div className="mt-8 px-6 pb-5 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Statistik</h3>
            </div>
            <div className="mt-8 block overflow-y-scroll">
              <div className="align-middle inline-block min-w-full border-b border-gray-200">
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="border-t border-gray-200">
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-100 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Poäng
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        GP
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        5P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        4P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        3P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        2P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        -2P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        -3P
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        G
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        GA
                      </th>
                      <th className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                        +/-
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    <tr>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 bg-gray-100 text-center lining-nums font-light">
                        {userInformation.points_standing}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.no_of_matches}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.five_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.four_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.three_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.two_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.minus_two_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.minus_three_points}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.total_games_won}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.total_games_lost}
                      </td>
                      <td className="table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-center lining-nums font-light">
                        {userInformation.plus_minus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <MatchResultList matches={matchResults} seriesContext={seriesContext} />
            <div className="mt-8 px-6 pb-5 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Förkortningar</h3>
            </div>
            <div className="bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <div className="grid grid-cols-12">
                {[
                  { text: 'Rank', description: 'Ranking' },
                  { text: 'Lag', description: 'Lag för vald säsong' },
                  { text: 'GP', description: 'Spelade matcher' },
                  { text: '5P', description: '5 poäng (stor vinst)' },
                  { text: '4P', description: '4 poäng (medelstor vinst)' },
                  { text: '3P', description: '3 poäng (liten vinst)' },
                  { text: '2P', description: '2 poäng (vinst i tiebreak)' },
                  { text: '-2P', description: '-2 poäng (förlust i tiebreak)' },
                  { text: '-3P', description: '-3 poäng (förlust)' },
                  { text: 'G', description: 'Vunna gem' },
                  { text: 'GA', description: 'Förlorade gem' },
                  { text: '+/-', description: 'Plus/minus för gem' },
                  { text: 'Poäng', description: 'Nuvarande poäng med start på 100 poäng' },
                ].map((abbr => (
                  <Fragment key={abbr.text}>
                    <p className="font-bold col-start-1 col-span-2">{abbr.text}</p>
                    <p className="col-start-3 col-span-10">{abbr.description}</p>
                  </Fragment>
                )))}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
